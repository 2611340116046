import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux'
import { registerUser, loginUser } from '../../../actions/authActions';
import HeaderIndex from "../header/Index";
import $ from "jquery";
import { FormattedMessage } from 'react-intl';

class Register extends Component {
	constructor() {
		super();
		this.state = {
			gender         : 'monsieur',
			firstName      : '',
			lastName       : '',
			day            : '',
			month          : '',
			year           : '',
			phone          : '',
			photo          : null,
			email          : '',
			company        : '',
			prefix         : '',
			currentPosition: '',
			website        : '',
			password       : '',
			password2      : '',
			user           : '',
			passwordUser   : '',
			username       : '',
			erreur         : '',
			erreur1        : '',
			erreur2        : '',
			erreur3        : '',
			erreur4        : '',
			erreur5        : '',
		}

		this.onChange      = this.onChange.bind(this)
		this.onSubmit      = this.onSubmit.bind(this)
		this.onSubmitLogin = this.onSubmitLogin.bind(this)
	}

	componentDidMount(){
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	fileSelectedHandlerChange = async (e) => {
		const base64 = await this.convertBase64(e.target.files[0]);
		this.setState({ photo: base64 });
	}

	convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}

	_validateEmail(email) {
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return regex.test(String(email).toLowerCase());
	}
	isValidLogin() {
		if (this.state.username === "" || this.state.password === "") {
			this.setState({ erreur5: 'Please enter the required fields' });
			return false
		}
		else {
			this.setState({ erreur5: '' });
			return true
		}
	}

	isValidRegister() {
		if (this.state.firstName === "" || this.state.lastName === "" || this.state.city === "" || this.state.phone === "" || this.state.email === ""
			|| this.state.password === "" || this.state.password2 === "") {
			this.setState({ erreur: 'Please enter the required fields', erreur4: '', erreur3: '', erreur2: '', erreur1: '' });
			return false
		}
		else if (isNaN(parseInt(this.state.year)) || isNaN(parseInt(this.state.month)) || isNaN(parseInt(this.state.day)) || isNaN(parseInt(this.state.phone))) {
			this.setState({ erreur1: 'Please enter a valid fields', erreur4: '', erreur3: '', erreur2: '', erreur: '' });
			return false
		}
		else if (!this._validateEmail(this.state.email)) {
			this.setState({ erreur2: 'Please enter a valid email address', erreur4: '', erreur3: '', erreur1: '', erreur: '' });
			return false
		}
		else if (this.state.password.length < 6) {
			this.setState({ erreur3: 'Password must be contain at least 6 characters', erreur4: '', erreur2: '', erreur1: '', erreur: '' });
			return false;
		} else if (this.state.password !== this.state.password2) {
			this.setState({ erreur4: 'password confirm does not match', erreur3: '', erreur2: '', erreur1: '', erreur: '' });
			return false;
		}
		else {
			this.setState({ erreur4: '', erreur3: '', erreur2: '', erreur1: '', erreur: '' });
			return true
		}

	}
	onSubmit = (e) => {
		e.preventDefault();
		if (this.isValidRegister()) {
			const newUser = {
				gender         : this.state.gender,
				username       : this.state.firstName,
				lastName       : this.state.lastName,
				day            : this.state.day,
				month          : this.state.month,
				year           : this.state.year,
				phone          : this.state.phone,
				prefix         : this.state.prefix,
				email          : this.state.email,
				company        : this.state.company,
				currentPosition: this.state.currentPosition,
				website        : this.state.website,
				password       : this.state.password,
				password2      : this.state.password2,
				photo          : this.state.photo,
			}

			if (this.state.password !== this.state.password2) {
				alert('password confirm does not match')
				return
			} else {
				this.props.registerUser(newUser)
				this.props.history.push('/home');
			}
		}
	}
	onSubmitLogin(e) {
		e.preventDefault();
		if (this.isValidLogin()) {
			const user = {
				username: this.state.username,
				password: this.state.password
			};
			this.props.loginUser(user)
		}
	}
	render() {
		const insideHeader = (
			<div className="my-account col-md-12 relative padding-t-50 clearfix">
				<div className="col-md-6 float-left">
					<p>Bonjour,</p>
					<div className="name">Welcome</div>
				</div>
			</div>
		);
		return (
			<div>
				<HeaderIndex insideHeader={insideHeader} />
				<div className="col-md-12">
					<div className="col-md-12 list-account" id="overview">
						<div className="top-list col-md-10 container-center">
							<div className="col-md-10 container-center overflow-h">
								<div className="col-md-4 float-left">
									<p>Données personnelles</p>
								</div>
								<div className="col-md-7 float-left">
									<div className="form-mes-infos">
										<form onSubmit={this.onSubmit}>
											<div class="erreur">
												{this.state.erreur !== "" ? <FormattedMessage
													id="error.required"
													defaultMessage={this.state.erreur}
												/> : null}
												{this.state.erreur1 !== "" ? <FormattedMessage
													id="error.valid"
													defaultMessage={this.state.erreur1}
												/> : null}
												{this.state.erreur2 !== "" ? <FormattedMessage
													id="error.email"
													defaultMessage={this.state.erreur2}
												/> : null}
												{this.state.erreur3 !== "" ? <FormattedMessage
													id="error.password"
													defaultMessage={this.state.erreur3}
												/> : null}
												{this.state.erreur4 !== "" ? <FormattedMessage
													id="error.confirmPassword"
													defaultMessage={this.state.erreur4}
												/> : null}
											</div>
											<div className="form-wrapper">
												<div className="form-file">
													<label>Photo de profil</label>

													{/* <img src="/images/profil-nolan.png" alt="Welt" /> */}
													<input name="profil" type="file" onChange={this.fileSelectedHandlerChange} />

												</div>
											</div>
											<div className="form-wrapper">
												<div className="form-select half">
													<label>Titre</label>
													<div className="select">
														<select name="gender" onChange={this.onChange}>
															<option value="Monsieur">Monsieur</option>
															<option value="Madame">Madame</option>
															<option value="Mademoiselle">Mademoiselle</option>
														</select>
													</div>
												</div>
											</div>
											<div className="form-wrapper">
												<div className="half">
													<label>Prénom</label>
													<input type="text" name="firstName" className="form-text" placeholder="Prénom" onChange={this.onChange} />
												</div>
												<div className="half">
													<label>Nom</label>
													<input type="text" name="lastName" className="form-text" placeholder="Nom" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="quart">
													<label>Jour</label>
													<input type="text" type="number" name="day" min="1" max="31" className="form-text" onChange={this.onChange} />
												</div>
												<div className="half">
													<label>Mois</label>
													<input type="text" name="month" type="number" min="1" max="12" className="form-text" onChange={this.onChange} />
												</div>
												<div className="quart">
													<label>Année</label>
													<input type="text" name="year" type="number" min="1930" max="2008" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="quart">
													<label>Préfixe</label>
													<input type="text" className="form-text" name="prefix" onChange={this.onChange} />
												</div>
												<div className="third">
													<label>Numéro</label>
													<input type="text" name="phone" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="full">
													<label>Adresse e-mail</label>
													<input type="email" name="email" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="full">
													<label>Votre société</label>
													<input type="text" name="company" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="form-select full">
													<label>Titre</label>
													<div className="select">
														<select name="currentPosition" onChange={this.onChange}>
															<option value="">Sélectionner </option>
															<option value="design">Studio de design</option>
															<option value="dev">Studio de dev</option>
															<option value="managment">Studio de managment</option>
														</select>
													</div>
												</div>
											</div>
											<div className="form-wrapper">
												<div className="full">
													<label>Adresse web</label>
													<input type="text" name="website" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="full">
													<label>Pasword</label>
													<input type="password" name="password" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="full">
													<label>Confirm Pasword</label>
													<input type="password" name="password2" className="form-text" onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper">
												<div className="full">
													<input type="submit" className="form-submit" defaultValue="Enregistrer" />
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							{/* Connexion */}
							{this.props.auth.isAuthenticated ?
								null
								:
								<div className="col-md-10 container-center overflow-h">
									<div className="col-md-4 float-left">
										<p>Connexion</p>
									</div>
									<div className="col-md-7 float-left">
										<div className="form-mes-infos">
											<form onSubmit={this.onSubmitLogin}>
												<div class="erreur">
													{this.state.erreur5 !== "" ? <FormattedMessage
														id="error.required"
														defaultMessage={this.state.erreur5}
													/> : null}
												</div>
												<div className="form-wrapper">
													<div className="third">
														<label>Adresse e-mail</label>
														<input
															type="text"
															name="username"
															value={this.state.username}
															onChange={this.onChange}
															className="form-text"
														/>
													</div>
												</div>
												<div className="form-wrapper">
													<div className="third">
														<label>Mot de passe</label>
														<input
															type="password"
															name="password"
															value={this.state.password}
															className="form-text"
															onChange={this.onChange}
														/>
													</div>
												</div>
												<div className="form-wrapper">
													<div className="full">
														<input type="submit" className="form-submit" defaultValue="Créer un compte" />
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>

		)
	}
}
Register.protoTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	loginUser: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
	auth: state.auth,
})
export default connect(mapStateToProps, { registerUser, loginUser })(Register)