import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { changeCurrency, getCurrencyData } from '../../../actions/currencyAction';
import { getMedias } from '../../../actions/socialMediaAction';
import ImagePaiement from '../../../assets/images/delivery.svg';
import Spinner from '../../utils/Spinner';

class FooterIndex extends Component {
	componentDidMount() {
		this.props.getCurrencyData()
		this.props.getMedias()
	}

	changeCurrency = (e) => {
		const currencies = this.props.currencies.currency
		const currentCurrency = currencies.filter(item => item.key == e.target.value)[0]
		this.props.changeCurrency(currentCurrency)
		setTimeout(() => {
			// window.location.reload()
		}, 500);
	}

	render() {
		let footer = this.props.footer;
		const { currencies, medias } = this.props;
		let displayCurrencies, displaySocialMedia, displaySocialIcon;
		var bg = require('../../../assets/images/img-footer.jpg')
		var ig = require('../../../assets/images/instagram.svg')
		var ig2 = require('../../../assets/images/instagram-2.svg')
		if (currencies.currency == null || currencies.loading) {
			displayCurrencies = <Spinner />
		}
		else {
			displayCurrencies = currencies && currencies.currency && currencies.currency !== null && currencies.currency[0] && currencies.currency.map((currency, i) => {
				return (
					<span key={i}>
						<input type="radio" name="currency" id={i} value={currency.key} />
						{this.props.currencyChecked.key === currency.key ?
							<label style={{ background: '#EBEBEB', color: 'black' }} >{currency.key}</label> :
							<label style={{ background: 'black', color: '#EBEBEB', border: '1px solid #EBEBEB' }} >{currency.key}</label>
						}
					</span>
				)
			})
		}
		if (medias.medias == null || medias.loading) {
			displaySocialMedia = <Spinner />
			displaySocialIcon = <Spinner />
		}
		else {
			displaySocialMedia = medias && medias.medias && medias.medias[0] && medias.medias.map((media, i) => {
				if (media.slug != 'facebook' && media.slug != 'twitter' && media.slug != 'youtube') {
					return (<span key={i}>
						<a href={media.url}  target="_blank">
							{media.slug == "weltkern" ?
							<img src={ig} alt="" />
							:
							<img src={ig2} alt="" />
							}
							{/* {media.slug.replace('-', '.')} */}
						</a><br />
					</span>)
				}
			})
			displaySocialIcon = medias && medias.medias && medias.medias[0] && medias.medias.map((media, i) => {
				if (media.slug == 'facebook' || media.slug == 'twitter' || media.slug == 'youtube') {
					return (
						<a key={i} className={media.slug} href={media.url} target="_blank">{media.slug}</a>
					)
				}
			})

		}

		return (
			<footer className="footerSansLogo">
				<div className="footer-bottom">
					<LeftSide.Content className="col-md-6 first half-height float-left d-flex-column-s-b padding-t-b-10 padding-l-r-12">
						<div className="col-md-12">
							<p>WELTKERN®</p>
						</div>
						<div className="main-login-social col-md-12 d-flex-row">
							<div className="main-social col-md-12 float-left">
								<LeftSide.SocialNetworks className="col-md-6 float-left height100 d-flex-column-s-b">
									<div className="col-md-12">
										<p>FOLLOW</p>
									</div>
									<div>

										<div className="social-instagram">
											<div className="instagram">
												{displaySocialMedia}
											</div>
										</div>
										<div className="social-langue">
											<div className="social">
												{displaySocialIcon}
											</div>
										</div>
									</div>
								</LeftSide.SocialNetworks>
								<LeftSide.Currencies className="col-md-6 float-left height100 d-flex-column-s-b">
									<div className="details-info">
										<div className="option-gift">
											<p>LANGUAGE/CURRENCY</p>
											<div className="col-md-12 licenses">
												<span>
													<input type="radio" name="currency" id="fr" value="FR" />
													<label style={{ background: '#EBEBEB', color: 'black' }} >FRA</label>
												</span>
												{displayCurrencies}
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<img src={ImagePaiement} className="logoFooter" alt="Welt" />
									</div>
								</LeftSide.Currencies>
							</div>
						</div>
					</LeftSide.Content>

					<RightSide.Content className="col-md-6 last half-height float-left d-flex-column-s-b padding-t-b-10 padding-l-r-12">
						<RightSide.Sommary>
							<div className="col-md-6 float-left site-map-footer">
								<Sommary.Left className="col-md-4 padding-l-r-4 float-left">
									<ul>
										<li className="title"><a href="/shop">Shop</a></li>
										<li><a href="/shop/books">Books</a></li>
										<li><a href="/shop/typefaces">Typefaces</a></li>
										<li><a href="/shop/supplies">SUPPLY</a></li>
										<li><a href="/shop/stationery">STATIONERY</a></li>
										<li><a href="/shop/rare">Rare</a></li>
										<li><a href="/shop/other">Others</a></li>
									</ul>

									<ul>
										<li className="title"><Link to="/typeface">Typefaces</Link></li>
										<li><Link to="/shop/detail-typeface/lausanne">Lausanne</Link></li>
										<li><Link to="/shop/detail-typeface/lausanne-pan">Lausanne Pan</Link></li>
										<li><Link to="/shop/detail-typeface/everett">Everett</Link></li>
										<li><Link to="/shop/detail-typeface/everett-mono">Everett Mono</Link></li>
										<li><Link to="/shop/detail-typeface/ghost">GHOST</Link></li>
									</ul>
								</Sommary.Left>

								<Sommary.Center className="col-md-4 padding-l-r-4 float-left">
									<ul><li className="title"><Link to="/club">Club</Link></li></ul>
									<ul>
										<li className="title"><Link to="/support">Support</Link></li>
										<li><Link to="/support_/about">About</Link></li>
										<li><Link to="/support_/informations">Informations</Link></li>
										<li><Link to="/support_/download">Download</Link></li>
										<li><Link to="/support_/updates">Updates</Link></li>
										<li><Link to="/support_/contact">Contact</Link></li>
										<li><Link to="/support_/faq">FAQ</Link></li>
									</ul>
								</Sommary.Center>

								<Sommary.Right className="col-md-4 padding-l-r-4 float-left">
									<ul>
										<li className="title"><Link to="/account" >Account</Link></li>
										<li><Link to="/account">DASHBOARD</Link></li>
										<li><Link to="/account/informations">Informations</Link></li>
										<li><Link to="/account/inventory">INVENTORY</Link></li>
										<li><Link to="/favorites">FAVORITES</Link></li>
									</ul>
								</Sommary.Right>
							</div>
						</RightSide.Sommary>
						<RightSide.Copyrights>
							<div className="col-md-6 float-left">
								<div className="col-md-4 float-left">
									<p>2021-2022 WELTKERN SÀRL</p>
								</div>
								<div className="col-md-8 float-left">
									<p>PROUDLY DESIGNED AND ART DIRECTED <br /> IN SWITZERLAND BY <strong>NIZAR KAZAN + CO</strong></p>
								</div>
							</div>
						</RightSide.Copyrights>
					</RightSide.Content>
				</div>
			</footer>
		)
	}
}
FooterIndex.propTypes = {

	getCurrencyData: PropTypes.func.isRequired,
	changeCurrency: PropTypes.func.isRequired,
	getMedias: PropTypes.func.isRequired
}
const mapStateToprops = (state) => ({
	auth: state.auth,
	currencies: state.currencies,
	currencyChecked: state.currencies.currencyChecked,
	medias: state.medias
})


const LeftSide = {
	Content: styled.div`
    background: white;
    display: flex;
    justify-content: space-between;
    `,
	SocialNetworks: styled.div`
    font-size: 10px;
    `,
	Currencies: styled.div`
    p { font-size: 10px; }
    `,
}

const RightSide = {
	Content: styled.div`
    text-transform: uppercase;
    `,
	Sommary: styled.div`
    display: flex;
    justify-content: flex-end;
    .title {
        font-family: 'TWKLausanne-600';
    }
    `,
	Copyrights: styled.div`
    display: flex;
    justify-content: flex-end;
    p, strong {
        color: #D0D0D0;
        font-size: 8px;
        line-height: 8px;
    }
    `,
}

const Sommary = {
	Left: styled.div``,
	Center: styled.div``,
	Right: styled.div``
}
export default connect(mapStateToprops, { getCurrencyData, changeCurrency, getMedias })(FooterIndex)
