import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";

export class Delivery extends Component {
  constructor() {
    super();
    this.state = {
      addressEmpty: false,
      zipCodeEmpty: false,
      cityEmpty: false,
      countryEmpty: false,
    };
  }
  continue = (e) => {
    e.preventDefault();
    const { values } = this.props;
    if (!values.street) {
      this.setState({ addressEmpty: true });
      return;
    }

    if (!values.zipcode) {
      this.setState({ zipCodeEmpty: true });
      return;
    }

    if (!values.ville) {
      this.setState({ cityEmpty: true });
      return;
    }

    if (!values.country) {
      this.setState({ countryEmpty: true });
      return;
    }
    console.log(values);
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, onChange, changePhone } = this.props;
    return (
      <div className="clearboth register__delivery">
        <div className="col-md-12">
          <h4>3. Address</h4>
        </div>
        <div className="col-md-12">
          <div className="delivery__desc">
            <div className="col-md-12">
              <div className="form-wrapper  col-md-12">
                <input
                  defaultValue={values.company}
                  type="text"
                  className="form-text"
                  name="company"
                  placeholder="Company"
                  onChange={onChange}
                />
              </div>
              <div className="form-wrapper  col-md-12">
                <input
                  defaultValue={values.street}
                  required="required"
                  type="text"
                  className="form-text"
                  name="street"
                  placeholder="Address*"
                  onChange={onChange}
                  autoFocus="true"
                />
                <div className="erreur">
                  {this.state.addressEmpty == false ? (
                    ""
                  ) : (
                    <p>Please fill in this field</p>
                  )}
                </div>
              </div>
              <div className="form-wrapper  col-md-12">
                <input
                  defaultValue={values.complement}
                  type="text"
                  className="form-text"
                  name="complement"
                  placeholder="Address complement"
                  onChange={onChange}
                />
              </div>

              <div className="form-wrapper col-md-12 delivery__adress">
                <div className="col-md-3">
                  <input
                    defaultValue={values.zipcode}
                    type="text"
                    name="zipcode"
                    className="form-text"
                    placeholder="Zip code*"
                    onChange={onChange}
                  />
                  <div className="erreur">
                    {this.state.zipCodeEmpty == false ? (
                      ""
                    ) : (
                      <p>Please fill in this field</p>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <input
                    defaultValue={values.ville}
                    required="required"
                    type="text"
                    name="ville"
                    className="form-text"
                    placeholder="City*"
                    onChange={onChange}
                  />
                  <div className="erreur">
                    {this.state.cityEmpty == false ? (
                      ""
                    ) : (
                      <p>Please fill in this field</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-wrapper delivery__birthday col-md-12">
                <input
                  defaultValue={values.streetNumber}
                  type="text"
                  name="streetNumber"
                  className="form-text"
                  placeholder="State, Area code, Other"
                  onChange={onChange}
                />
              </div>
              <div className="form-wrapper delivery__country col-md-12">
                <div className="form-select col-md-12">
                  <label>Country*</label>
                  <div className="select">
                    <select
                      required
                      oninvalid="this.setCustomValidity('Lütfen işaretli yerleri doldurunuz')"
                      name="country"
                      id="country"
                      onChange={onChange}
                    >
                      <option value="">Please select</option>
                      {values && values.pays != null
                        ? values &&
                          values.pays[0] &&
                          values.pays.map((pays) => (
                            <optgroup
                              className="group-list"
                              label={pays.zone_name}
                            >
                              {pays.zone
                                .sort(function (a, b) {
                                  if (
                                    a.pays.toLowerCase() < b.pays.toLowerCase()
                                  )
                                    return -1;
                                  if (
                                    a.pays.toLowerCase() > b.pays.toLowerCase()
                                  )
                                    return 1;
                                  return 0;
                                })
                                .map((location) => {
                                  return (
                                    <option
                                      value={location.code}
                                      dangerouslySetInnerHTML={{
                                        __html: location.pays,
                                      }}
                                    ></option>
                                  );
                                })}
                            </optgroup>
                          ))
                        : ""}
                    </select>
                    <div className="erreur">
                      {this.state.countryEmpty == false ? (
                        ""
                      ) : (
                        <p>Please fill in this field</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-wrapper col-md-12 delivery__adress">
                <div className="col-md-12">
                  <PhoneInput
                    className="form-text phone"
                    required="true"
                    autoFocus="true"
                    name="phone"
                    country={"ch"}
                    enableAreaCodes={true}
                    defaultValue={values.phone}
                    value={values.phone}
                    onChange={(phone) => changePhone(phone)}
                  />
                  <label>
                    (The number will only be transmitted to the transporter.)
                  </label>
                </div>
              </div>
              <button
                color="primary"
                class={`btnForm1 float-right ${
                  !values.street ||
                  !values.zipcode ||
                  !values.ville ||
                  !values.pays ||
                  !values.phone
                    ? "btnRegister"
                    : ""
                }`}
                variant="contained"
                onClick={this.continue}
                disabled={
                  !values.street ||
                  !values.zipcode ||
                  !values.ville ||
                  !values.pays ||
                  !values.phone
                }
              >
                Continue
              </button>
              <button
                color="secondary"
                class="float-left"
                variant="contained"
                onClick={this.back}
                disabled={
                  !values.street ||
                  !values.zipcode ||
                  !values.ville ||
                  !values.country
                }
              >
                Back
              </button>
            </div>
          </div>
          <div className="informations__keyboard col-md-12"></div>
        </div>
      </div>
    );
  }
}

export default Delivery;
