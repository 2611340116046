import React, { Component } from 'react';

class NotFound extends Component {
	render() {
		return (
			<div className="page-404">
				<h1 class="page-404-title">404</h1>
				<h4 class="page-404-sub-title">Page not found</h4>
				<p class="page-404-text">
					Oops...The link you clicked may be broken or the page may have been removed. We’re sorry.
        </p>
			</div>
		);
	}
}
export default NotFound;