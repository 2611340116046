import Swal from "sweetalert2";
import API, { subUrl } from "../utils/api";
import setAuthToken from "../utils/setAuthToken";
import { SET_CURRENT_USER } from "./types";
import history from "../utils/history";
import $ from "jquery";

export const registerUser = (userData) => (dispatch) => {
  API.post(subUrl.register, userData)
    .then((res) => console.log(res))
    .catch((error) => alert(error.response.data.message));
};

export const parrainageUser = (userData) => (dispatch) => {
  API.post(subUrl.newParrainage, userData)
    .then((res) => console.log(res))
    .catch((error) => alert(error.response.data.message));
};

export const changePassword = (userData) => (dispatch) => {
  API.post(subUrl.newPassword, userData)
    .then((res) => {
      setAuthToken(false);
      localStorage.removeItem("persist:root", "authenticated");
      //remove user's data
      dispatch(setCurrentUser({}));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your password has been updated successfully!",
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .catch((error) => alert(error.response.data.message));
};

export const resetUser = (email) => (dispatch) => {
  API.post(subUrl.reset, email)
    .then((res) => console.log(res))
    .catch((error) => alert(error.response.data.message));
};

export const verifyUser = (userData) => (dispatch) => {
  API.post(subUrl.verify, userData)
    .then((res) => console.log(res))
    .catch((error) => console.log(error.response.data.message));
};

export const updateUser = (userData) => (dispatch) => {
  API.put(subUrl.updateUser + "/" + userData.id, userData)
    .then((res) => console.log(res))
    .catch((error) => alert(error.response.data.message));
};

// Get user Token
export const loginUser = (userData) => (dispatch) => {
  API.post(subUrl.login, userData)
    .then((res) => {
      const { token } = res.data;
      const { active } = res.data;
      const { first_connection } = res.data;
      console.log(active);
      if (active != 0) {
        setAuthToken("Bearer " + token);
        dispatch(setCurrentUser(res.data));
        if (first_connection != 0) {
          /* Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: 'Successful authentication',
					showConfirmButton: false,
					timer: 3000
				}) */
          setTimeout(function () {
            $("body").removeClass("overflow-hidden");
            if(localStorage.getItem("carte-showing"))
            {
              history.push("/checkout")
              localStorage.removeItem("carte-showing",true)
            }
            window.location.reload();
          }, 3000);
        }
      } else {
        
        return "false";
      }
    })
    .catch((error) => {
      console.log(error.status);
      Swal.fire({
        icon: "error",
        title: "Incorrect Email or Password",
        text: "Incorrect Email or Password",
      });
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
export const logoutUser = () => (dispatch) => {
  //remove token from auth header
  setAuthToken(false);
  localStorage.removeItem("persist:root", "authenticated");
  //remove user's data
  dispatch(setCurrentUser({}));
  window.location.reload();
};
