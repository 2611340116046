import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrencyData, changeCurrency } from '../../actions/currencyAction'
import { getMedias } from '../../actions/socialMediaAction'
import { logoutUser } from '../../actions/authActions'
import HeaderIndex from "../_includes/header/Index";
import Support from "../_includes/menu/support";
import Detail from "../_includes/menu/detailSupport";
import { getCustomer } from '../../actions/userActions'
import { parrainageUser } from '../../actions/authActions';
import $ from 'jquery';


class Index extends Component {
	constructor() {
		super()
		this.state = {
		}
	}

	componentDidMount() {
		this.props.getCurrencyData()
		this.props.getMedias()
		this.props.getCustomer()
		$("body").removeClass("body-lock")
		$(".account, .account-offine").on("click", function () {
			$(".top-header").addClass("dark")
		})
		$(".top-header").addClass("dark")
	}


	render() {
		const { isAuthenticated } = this.props.auth
		const insideHeader = (
			<div className="my-account col-md-12 relative padding-t-50 clearfix">
				
					<>
						<Detail />
						<Support />
					</>
				
			</div>
		);
	

		return (
			<>
				{/* <Helmet>
					<title>WELT | Account</title>
					<meta name="Account" content="Page Account de welt." />
				</Helmet> */}
				<div className="headerAccount">
					<HeaderIndex insideHeader={insideHeader} />
				</div>
			</>
		)
	}
}
Index.propTypes = {
	auth: PropTypes.object.isRequired,
	getCurrencyData: PropTypes.func.isRequired,
	changeCurrency: PropTypes.func.isRequired,
	getMedias: PropTypes.func.isRequired,
	logoutUser: PropTypes.func.isRequired,
	getCustomer: PropTypes.func.isRequired,
	parrainageUser: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth,
	currencies: state.currencies,
	currencyChecked: state.currencies.currencyChecked,
	medias: state.medias,
	users: state.users
})

export default connect(mapStateToprops, { getCurrencyData, changeCurrency, getMedias, logoutUser, getCustomer, parrainageUser })(Index) 