import React, { Component } from 'react';
import MenuIndex from "./_includes/menu";
import FooterIndex from "./_includes/footer/FooterIndex";
import HeaderPage from "./_includes/headersPage/HeaderPage"
import SimplePost from "./posts/SimplePost"
import Spinner from './utils/Spinner'
import { getProducts } from '../actions/productsActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPages } from '../actions/pagesAction'
import { getPostData } from '../actions/postActions'
import $ from 'jquery';
import API, { subUrl } from '../utils/api'

class Index extends Component {
	constructor() {
		super()
		this.state = {
			isShowing: false,
			visible: 10,
			vignette:[],
		}
	}
	componentDidMount() {
		 $(".top-header").toggleClass('dark')
		$("body.body").removeClass("body-lock")
		//const { currentLange } = this.props;
		//const { language }     = currentLange;
		const { currentLange, currencies } = this.props;
		const { currencyChecked } = currencies;
		const { language }     = currentLange;
		this.props.getPages()
		this.props.getPostData(language)
		
		this.props.getProducts(language, currencyChecked.key);

		$(".cart,.menu,.account, .account-offine").on('click',function(){
			$("header.top-header").toggleClass('dark')
		})

		API
			.get(subUrl.vignette)
			.then(res => {
				this.setState({ vignette: res.data })
			}
			)
			.catch(error => console.log(error))
	}
	showMore = () => {
		this.setState({ isShowing: true, visible: this.state.visible + 5 })
	}

	render() {
		const {posts, pages, products } = this.props;
		let displayedHeaders;
		let displayFooter;
		let bannerView;
		const simplePost = [];
		const inlinePost = [];
		const fullPost   = [];

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayedHeaders = <Spinner />
		} else {
			let foundHeader  = false;
			let foundBanners = false;
			let foundFooter  = false;
			displayedHeaders = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					const founded = (page.slug.indexOf("index") > -1);
					if (founded) {
						foundHeader = true;
						return (
							<HeaderPage
								key={i}
								header={page}
							/>
						)
					}
				}
			})
			displayFooter = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("index") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})
			bannerView = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("index") > -1)
					if (founded) {
						if (page.banner_1.background_image !== false) {
							foundBanners = true;
							return (
								<img key={i} src={page.banner_1.background_image.url} alt="Welt" />
							)
						}
					}
				}
			})

			if (!foundHeader) {
				displayedHeaders = <HeaderPage />
			}
			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
			if (!foundBanners) {
				bannerView = ""
				// bannerView=<img src="images/img7.png" alt="Welt"/>  
			}
		}
		if (posts.post !== null && !posts.loading) {
			posts.post.forEach((post, i) => {
				const simplePostFounded = ((post.post_template.indexOf("col-md-4") > -1 || post.post_template.indexOf("col-md-6") > -1)) && post.categories[0].id.slug === "typeface";
				const inlinePostFounded = (post.post_template.indexOf("main-book") > -1);
				const fullPostFounded   = (post.post_template.indexOf("col-md-12") > -1);

				if (simplePostFounded) {
					simplePost.push(post)
				}
				if (inlinePostFounded) {
					inlinePost.push(post)
				}
				if (fullPostFounded) {
					fullPost.push(post)
				}
			})
		}
		return (
			<>
				<MenuIndex currentPage={"shop typeface journal"} />
				<div className="row vignette">
					{this.state.vignette != null && this.state.vignette.map((item) => {
						return (
							<>
								<div class="col-md-2-2">
									<a href={item.external_link ? item.url_externe : item.url_interne} target={item.external_link ? "_blank" : ''} className="flex-col">
										<div className="hover-preview">
											<img src={item.image}/>
										</div>
										<h2>{item.title}</h2>
									</a>
								</div>
							</>
						)
					})}
				</div>

				<div className="col-md-12 padding-t-b-l-r-8 overflow-h bg-corps text-center margin-b-30">
					{simplePost.length > 0 &&
						simplePost.slice(0, 5).map((post, i) => {
							return (
								<SimplePost key={i} post={post} />
							)
						}
						)
					}
				</div>
			</>)
	}
}
Index.propTypes = {
	getPages    : PropTypes.func.isRequired,
	auth        : PropTypes.object.isRequired,
	products    : PropTypes.object.isRequired,
	getPostData : PropTypes.func.isRequired,
	currentLange: PropTypes.object.isRequired,
	currencies  : PropTypes.object.isRequired,
	getProducts : PropTypes.func.isRequired,

}
const mapStateToprops = (state) => ({
	auth        : state.auth,
	products    : state.products,
	posts       : state.postObject,
	pages       : state.pages,
	currentLange: state.translation,
	currencies: state.currencies
})

export default connect(mapStateToprops, { getProducts,getPages, getPostData })(Index) 
