import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Spinner from '../../assets/images/loading.gif'

export default () => {
	return (
		<div className="spinner" style={{}}>
			<div className="container-center col-md-10 relative">
				<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
			</div>
		</div>
	)

}
