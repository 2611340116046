import $ from 'jquery';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { loginUser, registerUser } from '../../../actions/authActions';
import API, { subUrl } from '../../../utils/api';
import LoginIndex from "../../_includes/login/Login";
import HeaderIndex from "../header/Indexsanslogo";
import Cart from '../lastProduits/Cart';
import User from '../login/Login';
import Conditions from './Conditions';
import Delivery from './Delivery';
import Informations from './Informations';
import UserName from './UserName';
// import 'react-phone-input-2/lib/high-res.css'

class Register extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      mess: "",
      pays: [],
      firstName: '',
      lastName: '',
      day: '',
      showPopup: false,
      popupText: "Please wait...",
      month: '',
      street: '',
      company: '',
      streetNumber: '',
      year: '',
      email: '',
      status: '',
      currentPosition: '',
      password: '',
      password2: '',
      user: '',
      prefix: '',
      phone: '',
      zipcode: '',
      country: '',
      passwordUser: '',
      username: '',
      ville: '',
      erreur: '',
      erreur1: '',
      erreur2: '',
      erreur3: '',
      erreur4: '',
      erreur5: '',
      btnresponse: true
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmitLogin = this.onSubmitLogin.bind(this)
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  changePhone = (number) => {
    this.setState({ phone: number });
  }

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  fileSelectedHandlerChange = async (e) => {
    const base64 = await this.convertBase64(e.target.files[0]);
    this.setState({ photo: base64 });
  }

   // Proceed to next step
   nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  componentDidMount() {


    $(".popup--download-close").on('click', function (e) {
			$(".popup--download").removeClass('open');
			$(".scrollTop").removeClass('not-showing');
			$('body').removeClass('overflow-hidden');
      window.location.href='/home';
			e.preventDefault()
		});
    $(".popup--password-close").on('click', function (e) {
			$(".popup--password").removeClass('open');
			$(".scrollTop").removeClass('not-showing');
			$('body').removeClass('overflow-hidden');
			e.preventDefault()
		});
    $(".popup--email-close").on('click', function (e) {
			$(".popup--email").removeClass('open');
			$(".scrollTop").removeClass('not-showing');
			$('body').removeClass('overflow-hidden');
			e.preventDefault()
		});

    /* document.addEventListener("DOMContentLoaded", function () {
      var elements = document.getElementsByTagName("INPUT");
      for (var i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function (e) {
          e.target.setCustomValidity("");
          if (!e.target.validity.valid) {
            e.target.setCustomValidity("Please fill in this field");
          }
        };
        elements[i].oninput = function (e) {
          e.target.setCustomValidity("");
        };
      }
    })

    document.addEventListener("DOMContentLoaded", function () {
      var elements = document.getElementsByTagName("OPTION");
      for (var i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function (e) {
          e.target.setCustomValidity("");
          if (!e.target.validity.valid) {
            e.target.setCustomValidity("Please fill in this field");
          }
        };
        elements[i].oninput = function (e) {
          e.target.setCustomValidity("");
        };
      }
    }) */



    API
      .get(subUrl.zones)
      .then(res => {
        this.setState({ pays: res.data })
      }
      )
      .catch(error => console.log(error))

    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });

    $('html,body').animate({
      scrollTop: 0
    }, 'slow');

    $("body.body").removeClass("body-lock");

    $(".cart,.account, .account-offine,.menu").on('click', function () {
      $(".top-header").toggleClass("dark");
    })

    $(".account, .account-offine").on('click', function () {
      $(".reset-password.register").toggleClass("hide");
    })

    $("#date-birth").on('click', function () {
      $("#date-birth").attr("type", "date");
    })

    $(".ok1").on("click", function () {
      var fieldInput = $('#Prenom');
      var fldLength = fieldInput.val().length;
      fieldInput.focus();
      fieldInput[0].setSelectionRange(fldLength, fldLength);

    });



    // $(".terms__desc").scroll(function () {
    //   if ($(".terms__desc").scrollTop() == 11984) {
    //     $('.form-check-conditions label').removeClass('unaccept')
    //     $('.form-check-conditions label').addClass('colorBlack')
    //     function accept(){
    //       accept = () => {
    //         this.setState({btnresponse:false})
    //       }
    //     }
    //   } else {
    //   $('.form-check-conditions label').addClass('unaccept')
    //   }
    // });

    $('.form-check-conditions label').on("click", function () {
      $('.form-mes-infos button').removeClass('btnRegister')
      function accept() {
        accept = () => {
          this.setState({ btnresponse: false })
        }
      }
    })





    $(document).on("keypress", "input", function (e) {
      var val = $(this).val();
      if (e.which === 13) {
        var fieldInput = $('#Prenom');
        var fldLength = fieldInput.val().length;
        fieldInput.focus();
        fieldInput[0].setSelectionRange(fldLength, fldLength);
      }
    });
  }
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);

      }

    })

  }
  _validateEmail(email) {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  }
  isValidLogin() {
    if (this.state.username === "" || this.state.password === "") {
      this.setState({ erreur5: 'Please enter the required fields' });
      return false
    }
    else {
      this.setState({ erreur5: '' });
      return true
    }

  }
  isValidRegister() {
    return true;

    // if (this.state.firstName === "" || this.state.lastName === "" || this.state.email === "" || this.state.password === "" || this.state.password2 === "") {
    //   this.setState({ erreur: 'Please enter the required fields' });
    //   // this.setState({ erreur: 'Please enter the required fields', erreur4: '', erreur3: '', erreur2: '', erreur1: '' });
    //   return false
    // }
    /* else if (isNaN(parseInt(this.state.year)) || isNaN(parseInt(this.state.month)) || isNaN(parseInt(this.state.day)) ) {
      this.setState({ erreur1: 'Please enter a valid fields', erreur4: '', erreur3: '', erreur2: '', erreur: '' });
      return false
    } */
    // else if (!this._validateEmail(this.state.email)) {
    //   this.setState({ erreur2: 'Please enter a valid email address', erreur4: '', erreur3: '', erreur1: '', erreur: '' });
    //   return false
    // }
    // else if (this.state.password.length < 6) {
    //   this.setState({ erreur3: 'Password must be contain at least 6 characters', erreur4: '', erreur2: '', erreur1: '', erreur: '' });
    //   return false;
    // } else if (this.state.password !== this.state.password2) {
    //   this.setState({ erreur4: 'password confirm does not match', erreur3: '', erreur2: '', erreur1: '', erreur: '' });
    //   return false;
    // }
    // else {
    //   this.setState({ erreur4: '', erreur3: '', erreur2: '', erreur1: '', erreur: '' });
    //   return true
    // }

  }
  onSubmit = (e) => {
    e.preventDefault();
    if (this.isValidRegister() && this.state.phone.length > 6) {
      let valid = ""
      this.state.pays.forEach(element => {
        element.zone.forEach(zone => {
          if(zone.code == this.state.country)
          valid = zone.pays
        });
      });
      const facturationadresse = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        address_1: this.state.street,
        company: this.state.company,
        address_2: this.state.complement,
        state: this.state.streetNumber,
        zipcode: this.state.zipcode,
        country: valid,
        city: this.state.ville,
        type: 'billing',
      }

      const defaultadress = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        company: this.state.company,
        address_1: this.state.street,
        address_2: this.state.complement,
        state: this.state.streetNumber,
        zipcode: this.state.zipcode,
        country: valid,
        city: this.state.ville,
        type: "shipping"
      }

      const newUser = {
        username: this.state.firstName,
        lastName: this.state.lastName,
        day: this.state.day,
        month: this.state.month,
        year: this.state.year,
        email: this.state.email,
        complement: this.state.complement,
        company: this.state.company,
        street: this.state.street,
        streetNumber: this.state.streetNumber,
        prefix: this.state.prefix,
        phone: this.state.phone,
        zipcode: this.state.zipcode,
        country: this.state.country,
        ville: this.state.ville,
        password: this.state.password,
        password2: this.state.password2,
        key: this.props.match.params.key,
        parrain: this.props.match.params.user,
        defaultAdresse: defaultadress,
        facturationAdresse: facturationadresse
      }
      if (this.state.password !== this.state.password2) {
        $(".popup--password").addClass('open');
        $(".scrollTop").addClass('not-showing');
        $('body').addClass('overflow-hidden');
        /* Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Password confirm does not match !',
        }) */

      } else {
        this.setState({showPopup : true})

        API
          .post(subUrl.register, newUser)
          .then(res => {
            if (res.data.status === 200) {
              setTimeout(() => {
                $(".popup--download").addClass('open');
                $(".scrollTop").addClass('not-showing');
                $('body').addClass('overflow-hidden');
              }, 3000);
            }
            /* Swal.fire(
              'Good job!',
              'Your account has been successfully created !',
              'success'
            ) */
          })
          .catch(error => {
            this.setState({mess : error.response.data.message})
            this.setState({showPopup : false})
            $(".popup--email").addClass('open');
            $(".scrollTop").addClass('not-showing');
            $('body').addClass('overflow-hidden');
            
            /* Swal.fire(
            error.response.data.message,
            error.response.data.message,
            'error'
          ) */} )
      }

    }
    else {
      this.setState({erreur : 'Invalide phone number'})
    }
  }



  onSubmitLogin(e) {
    e.preventDefault();
    if (this.isValidLogin()) {
      const user = {
        username: this.state.username,
        password: this.state.password
      };
      this.props.loginUser(user)
      Swal.fire(
        'Good job!',
        'You clicked the button!',
        'success'
      )
    }
  }


  accept = () => {
    this.setState({ btnresponse: false })
  }


  render() {
    const { step } = this.state;
    const { firstName, lastName, email, company, street, password, password2, complement, zipcode, ville, streetNumber, country, phone, erreur, pays } = this.state;
    const values = { firstName, lastName, email, company, street, password2, password, complement, zipcode, ville, streetNumber, country, phone, erreur, pays };

    return (

      <>
        <Helmet>
          <title>WELT | Register</title>
          <meta name="Register" content="Page Register de welt." />
        </Helmet>
        <div className="reset-password register">
          <a href="/home" className="default-btn-cart back"> Back</a>
        </div>
        <div className="register__weltform">
          <HeaderIndex />
          <div className="col-md-12">
            <div className="col-md-6 list-account container-center" id="overview">
              <div className="top-list col-md-10 container-center register">
                <div className="col-md-12 container-center overflow-h">
                  <div className="register__title">
                    <p className="text text--xmedium text--medium-grey">Register</p>
                    <p className="text text--xmedium">Join the Club!</p>
                  </div>
                  <div className="register__lets-start col-md-12">

                  </div>
                  <div className="register__form col-md-12">
                    <div className="form-mes-infos">
                      <form onSubmit={this.onSubmit}>
                        { step == 1 ?
                        <Informations
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onChange={this.onChange}
                        values={values}
                        />
                        : step == 2 ?
                        <UserName
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onChange={this.onChange}
                        values={values}
                         /> :
                        step == 3 ? <Delivery
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onChange={this.onChange}
                        changePhone={this.changePhone}
                        values={values}
                         />
                        : <Conditions
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onChange={this.onChange}
                        values={values}
                         /> }
                      </form>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <LoginIndex />
          <User />
          <Cart />
        </div>
        <Popups>
          <div className="popup--download col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
								<div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30"></p>
										<p className="mb-30">Please confirm your email address by clicking on the link we just sent you!</p>
									</div>
								</div>
								<div className="col-md-12 right">
									<button className="popup--download-close btn btn--close"><p>Close</p></button>
								</div>
							</div>
						</div>
					</div>
          <div className="popup--password col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
								<div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30">Oops!</p>
										<p className="mb-30">Incorrect password</p>
									</div>
								</div>
								<div className="col-md-12 right">
									<button className="popup--password-close btn btn--close"><p>Close</p></button>
								</div>
							</div>
						</div>
					</div>
          <div className="popup--email col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
								<div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30">Oops!</p>
										<p className="mb-30">{this.state.mess}</p>
									</div>
								</div>
								<div className="col-md-12 right">
									<button className="popup--email-close btn btn--close"><p>Close</p></button>
								</div>
							</div>
						</div>
					</div>
        </Popups>
        {this.state.showPopup && 
        <div className="popup-checkout">
          {this.state.popupText}
        </div>
        }
      </>

    )
  }


}
const Popups = styled('div')`
.popup--licence,
.popup--download,
.popup--password,
.popup--email,
.popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(235,235,235,0.98);
    font-family: 'TWKLausanne-600';

    &.open {
        opacity: 1;
        visibility: visible;
        z-index: 9999999999;
    }

    .popup__content {
        display: flex;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin-top: 400px;
        padding-bottom: 100px;
    }
    .center {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .right {
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 480px){
          justify-content: center;
        }
    }
    .popup__title { margin-bottom: 70px; text-align:center;}
    .mb-30 { margin-bottom: 0px; }
    .mb-40 { margin-bottom: 40px; }
    .underline { text-decoration: underline; }
}
@media screen and (max-width: 480px){
	.popup--licence,
	.popup--download,
  .popup--password,
  .popup--email,
	.popup--price{
		.popup__title { margin-bottom: 30px; }
		&.open {
			padding: 0 20px;
		}

	}
	h3 {
		color: #000000;
	}

}
`;
Register.protoTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loginUser: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, { registerUser, loginUser })(Register)
