import React, { Component } from 'react';
import { logoutUser } from '../../../actions/authActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import $ from 'jquery'

class MenuSupport extends Component {
	componentDidMount() {
		$(".Menu").on('click', function () {
			$('li a.Menu').addClass("activeMenu")
		})
	}

	render() {
		const { isAuthenticated, user, date } = this.props.auth
		return (
			<div className="col-md-3 float-left linkAccountLeft">
				<ul>
					<li><a href="/support_/about" className="Menu">About us</a></li>
					<li><a href="/support_/informations" className="Menu2">Informations</a></li>
					<li><a href="/support_/download" className="Menu3">Download</a></li>
					<li><a href="/support_/updates" className="Menu4">Updates</a></li>
					<li><a href="/support_/contact" className="Menu5">Contact</a></li>
					<li><a href="/support_/faq" className="Menu6">FAQ</a></li>
				</ul>
			</div>
		)
	}
}

MenuSupport.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth      : PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth
})

export default connect(mapStateToprops, { logoutUser })(MenuSupport)