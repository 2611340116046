import { GET_CUSTOMER } from '../actions/types'

const initialState = { 
    loading: false,
    user : null
}

export default function (state= initialState, action)  {
    switch (action.type) {
        
        case GET_CUSTOMER:
            return {
                ...state,
                user: action.payload,
                loading: true
            }
        default :
            return state

}


}