import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { loginUser } from '../../../actions/authActions'
import styled from 'styled-components';
import $ from 'jquery';


class LoginCheckout extends Component {
	constructor() {
		super()
		this.state = {
			username: '',
			password: '',
		}
		this.onChange = this.onChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}
	onSubmit(e) {
		e.preventDefault();
		const user = {
			username: this.state.username,
			password: this.state.password
		};
		this.props.loginUser(user)
	}

	componentDidMount(){
		$(".BtnLogin").on("click", function(){
			onclick="document.location.reload();return(false)"
		})
	}

	render() {
		return (
			<FormPage.Content className="connect-content ">
				<FormPage.Form className="nom-account form-login">
					<div className="sign-in">
						<div className="form__title">
							<h3 className="uppercase title__weltclub text text--xmedium">WeltClub</h3>
							<h3 className="title__sign-in text text--xmedium">Sign In</h3>
						</div>
						<form className="identification" onSubmit={this.onSubmit}>
							<div className="form-text">
								<input
									type="text"
									placeholder="E-mail"
									name="username"
									value={this.state.username}
									onChange={this.onChange}
								/>
							</div>
							<div className="form-text form-password">
								<input
									type        = "password"
									name        = "password"
									placeholder = "Mot de passe"
									value       = {this.state.password}
									className   = "password"
									onChange    = {this.onChange}
								/>
								<Link className="password-forget" to="/passwort-reset">Mot de passe oublié ?</Link>
							</div>
							<div className="form-submit">
								<button type="submit" className="BtnLogin">Login</button>
							</div>
						</form>
					</div>
					<div className="joint-club">
						<div className="form__title">
							<h3 className="title__weltclub text text--xmedium">Pas encore membre?</h3>
							<h3 className="title__sign-in text text--xmedium">Rejoins le club !</h3>
						</div>
						<p>L’inscripition est nécessaire pour cumuler vos precieux points W, retrouver vos historiques de commandes, vos licenses, cartes cadeaux et beaucoup d’autres choses !</p>
						<Link to="/register">Sign in</Link>
					</div>
				</FormPage.Form>
			</FormPage.Content>
		)
	}
}
const mapStateToProps = (state) => ({
	cart: state.cart,
	auth: state.auth
})
const mapDispatchToProps = (dispatch) => {
	return {
		initializeCarte: () => dispatch({ type: 'INIALIZE_CART' }),
		loginUser: (user) => dispatch(loginUser(user)),

	}
}


const FormPage = {
	Content: styled.div`
      height: 100%;
      .form-login { height: 100%; }
      `,
	Form: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form__title {
          font-family: 'TWKLausanne-600';
          .title__weltclub { color: #BBBBBB; }
          .title__sign-in { color: #000000; }
      }
      .joint-club, 
      .identification,
      h3 {
        font-family: 'TWKLausanne-600';
      }
      `,
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginCheckout)