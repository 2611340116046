import axios from 'axios';
import $ from 'jquery';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { verifyUser } from '../../../actions/authActions';

class ActiveUser extends Component {
	constructor() {
		super();
		this.state = {
			username : '',
			key      : '',
			response : null,
			password : '',
			password2: '',
		}
	}
	async componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');

	$(".popup--download-close").on('click', function (e) {
		$(".popup--download").removeClass('open');
		$(".scrollTop").removeClass('not-showing');
		$('body').removeClass('overflow-hidden');
  		window.location.href='/home';
		e.preventDefault()
	});

		const newUser = {
			key: this.props.match.params.key,
			userid: this.props.match.params.user
		}
		const { data } = await axios.post(`${process.env.REACT_APP_BaseURL}/wp-json/custom-routes/v1/active/user`, newUser)
		this.setState(({
			response: data
		}))
		if (data != null && data.status) 
			if (data.status == 200){
				/* Swal.fire(
					"Your account has been actived !",
					"You clicked the button!",
					"success",			  
					this.props.history.push('/home')
				  ); */
				$(".popup--download").addClass('open');
                $(".scrollTop").addClass('not-showing');
                $('body').addClass('overflow-hidden');
			}
		
	}

	render() {
		

		return (
			<Popups>
          		<div className="popup--download col-md-12 popup-licence-download">
					<div className="popup__content">
						<div className="col-md-8 center">
							<div className="popup__text-content">
								<div className="popup__title">
                    				<p className="mb-30">Thank you for confirming your email!</p>
									<p className="mb-30">
									You can now login into your account.</p>
								</div>
							</div>
							<div className="col-md-12 right">
								<button className="popup--download-close btn btn--close"><p>Close</p></button>
							</div>
						</div>
					</div>
				</div>
        </Popups>
		);
	}
}

const Popups = styled('div')`
.popup--licence,
.popup--download,
.popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(235,235,235,0.98);
    font-family: 'TWKLausanne-600';

    &.open {
        opacity: 1;
        visibility: visible;
        z-index: 9999999999;
    }

    .popup__content {
        display: flex;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin-top: 400px;
        padding-bottom: 100px;
    }
    .center {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .right {
        display: flex;
        justify-content: flex-end;
    }
    .popup__title { margin-bottom: 70px; text-align:center;}
    .mb-30 { margin-bottom: 0px; }
    .mb-40 { margin-bottom: 40px; }
    .underline { text-decoration: underline; }
}
@media screen and (max-width: 480px){
	.popup--licence,
	.popup--download,
	.popup--price{
		.popup__title { margin-bottom: 30px; }
		&.open {
			padding: 0 20px;
		}

	}
	h3 {
		color: #000000;
	}

}
`;

ActiveUser.protoTypes = {
	verifytUser: PropTypes.func.isRequired,
	changePassword: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => ({
	auth: state.auth,
})

const mapDispatchToProps = (dispatch) => {
	return {
		verifyUser: (newUser) => dispatch(verifyUser(newUser))
	}
}
export default connect(mapStateToProps, { mapDispatchToProps })(ActiveUser)
