import React, { Component } from "react";

export class UserName extends Component {
  constructor() {
    super();
    this.state = {
      firstEmpty: false,
      lastEmpty: false,
    };
  }
  continue = (e) => {
    e.preventDefault();
    const { values, onChange } = this.props;
    if (!values.firstName) {
      this.setState({ firstEmpty: true });
    }
    if (!values.lastName) {
      this.setState({ lastEmpty: true });
    }
    if (values.firstName && values.lastName) this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, onChange } = this.props;
    return (
      <div className="clearboth register__delivery">
        <div className="col-md-12">
          <h4>2. Name</h4>
        </div>

        <div className="col-md-12">
          <div className="delivery__desc">
            <div className="col-md-12">
              <div className="form-wrapper float-left">
                <div className="">
                  <input
                    defaultValue={values.firstName}
                    required="required"
                    type="text"
                    name="firstName"
                    id="Prenom"
                    className="form-text"
                    placeholder="First name*"
                    onChange={onChange}
                    autoFocus="true"
                  />
                </div>
                <div className="erreur">
                  {this.state.firstEmpty == false ? (
                    ""
                  ) : (
                    <p>Please fill in this field</p>
                  )}
                </div>
              </div>
              <div className="form-wrapper float-right">
                <input
                  defaultValue={values.lastName}
                  required="required"
                  type="text"
                  name="lastName"
                  className="form-text"
                  placeholder="Last name*"
                  onChange={onChange}
                />
                <div className="erreur">
                  {this.state.lastEmpty == false ? (
                    ""
                  ) : (
                    <p>Please fill in this field</p>
                  )}
                </div>
              </div>
              <button
                color="primary"
                class={`btnForm1 float-right ${
                  !values.firstName || !values.lastName ? "btnRegister" : ""
                }`}
                variant="contained"
                onClick={this.continue}
                disabled={!values.firstName || !values.lastName}
              >
                Continue
              </button>
              <button
                color="secondary"
                class="float-left"
                variant="contained"
                onClick={this.back}
              >
                Back
              </button>
            </div>
          </div>
          <div className="informations__keyboard col-md-12"></div>
        </div>
      </div>
    );
  }
}

export default UserName;
