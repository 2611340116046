import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux'
import { getFavorite } from '../../../../actions/favoriteAction'

class Likes extends Component {
	componentDidMount() {
		this.props.getFavorite();
	}

	getLocalStorage() {
		let data = localStorage.getItem('state')
		localStorage.clear();
	}

	render() {
		let likeImg = false
		this.props.favorites && this.props.favorites.length > 0 ?
			likeImg = true
			: likeImg = false
		return (
			
			<Link /* style={{backgroundImage : `url(${likeImg})`, backgroundSize: 40, backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', backgroundPositionY:'center'}} */ to={{
				pathname: '/favorites',
			}}
				className={`${likeImg ? 'liked' : ''} btn-heart like`}
				
			>
				
                Favories
			</Link>
		)
	}
}
Likes.protoTypes = {
	favorites: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
	favorites: state.favorites.favorites,
})
export default connect(mapStateToProps, {getFavorite})(Likes)
