import React, { Component } from 'react';
import HeaderIndex from "../../components/support/header";
import FooterIndex from "../_includes/footer/FooterSanaHeader";

import Spinner from '../utils/Spinner'
import Cart from "../_includes/lastProduits/Cart"
import { getPages } from '../../actions/pagesAction'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import $ from 'jquery'
import FooterSanaHeader from '../_includes/footer/FooterSanaHeader';
import { Helmet } from 'react-helmet';

class Contact extends Component {
	componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');

		this.props.getPages()
		$(".cart").on('click', function(){
			$("header.top-header").toggleClass("dark")
			return false
		})

	$('li a.Menu5').addClass("activeMenu")
}
	render() {
		const { pages } = this.props;
		let displayFooter;
		let displayContent, content;

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent = <Spinner />
		} else {
			let foundBanners = false;
			let foundFooter = false;
			let foundContent = false;

			displayFooter = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-contact") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})
			displayContent = pages.pageData.map((page, i) => {

				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-contact") > -1)
					if (founded) {
						let description = page.contact_infos;
						content = description.map((result)=>{
							return(
								<div className="col-md-10 container-center support-style">
									<div className="col-md-5 float-left">
										<h4>{result.title}</h4>
									</div>
									<div className="col-md-7 float-left">
									{result.text.map((item)=>{
										return(
											<p>
											<span dangerouslySetInnerHTML={{__html:item.sub_title.replace("<p>","").replace("</p>","")}}/><br />
											<a href={`mailto:${item.content}`}>{item.content}</a></p>
										)
									})}
									</div>
								</div>
							)
						})

					}
				}
			})

			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}

		return (<>
				<Helmet>
					<title>Contact – WK®</title>
					<meta name="Support-About" content="Contact – WK®" />
				</Helmet>
				<HeaderIndex/>

			<div className="col-md-12 clearfix scroll-top-div">
				<div className="col-md-10 container-center bg-blanc padding-t-10 padd-t12">
					<div className="col-md-10 container-center support-style">
						{content}
					</div>
				</div>
				<div className="col-md-12">
					<div className="img-l-full">
						{/* <img src="/images/support-contact.png" alt="Welt" /> */}
					</div>
				</div>
			</div>
			<Cart />
			<FooterSanaHeader/>
		</>)
	}
}
Contact.propTypes = {
	getPages: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,
})

export default connect(mapStateToprops, { getPages })(Contact)
