import React, { Component } from "react";
import Moment from "react-moment";
import $ from "jquery";
class OrdersComponent extends Component {

	componentDidMount(){
		$(".price-virgule").text(function () {
			return $(this).text().replace(".", ","); 
		});
	
	}
	render() {
		let orderItems;
		if (this.props.order.line_items !== undefined) {
			orderItems = this.props.order.line_items.map(item => {
				return (
					<div className="list-licenses d-flex-row">
						<div className="col-md-2">
							<img src={item.image.url} alt="Welt" />
						</div>
						<div className="col-md-10 d-flex-column-s-b">
							<div className="col-md-12">
								<div className="col-md-4 float-left">
									<h3>{item.name}</h3>
								</div>
								<div className="col-md-3 float-left">
									<div className="col-md-12">
										<div className="icon" />
										<div className="date">
											<Moment format="ddd DD MMM YYYY">
												{this.props.order.date_created}
											</Moment>
											{/* {   moment(new Date(this.props.order.date_created)).format("DD-MMM-YYYY")} */}
										</div>
									</div>
									<div className="col-md-12">
										<div className="icon" />
										<div className="date">
											<Moment format="ddd DD MMM YYYY">
												{this.props.order.date_modified}
											</Moment>
										</div>
									</div>
									<div className="col-md-12">
										<div className="icon" />
										<div className="date">
											{this.props.order.date_completed ==
												null ? (
													"En cours"
												) : (
													<Moment format="ddd DD MMM YYYY">
														{
															this.props.order
																.date_completed
														}
													</Moment>
												)}
										</div>
									</div>
								</div>
								<div className="col-md-5 float-left">
									<div className="qte">fr</div>
									<div className="prix">
										<span>CHF</span>
										<span className="price-virgule">{item.total}</span>
									</div>
									<div className="primes">{this.props.order.status} </div>

									<div className="primes">-{item.paid_point}W</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="col-md-2 float-left">
									Reward
                                </div>
								<div className="col-md-2 float-left reward-prix">
									<div className="col-md-12">
										<div className="col-md-5 float-left">
											Prime
                                        </div>
										<div className="col-md-7 float-left align-right">

											{
												item.multiplier.value != 'false' ?
													<span className="point">
														<div className="multiplication">W{item.multiplier.value} </div>
													</span>
													:
													''
											}

											{
												item.multiplier.value != 'false' ?
													//+{ item.points.prime }W
													<div>{item.points.prime}W{item.multiplier.value}x → <span>+{item.points.prime * item.multiplier.value}W</span></div>
													:
													<div>+{item.points.prime}W</div>

											}
										</div>
									</div>
									<div className="col-md-12 status">
										<div className="col-md-5 float-left">
											Status
                                        </div>
										<div className="col-md-7 float-left align-right">
											+{item.points.status}W
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			});
		}
		return (
			<div className="col-md-12 list-account" id="commandes">
				<div className="col-md-10 container-center my-order">
					{/* Licenses */}
					{orderItems}
					{/* Licenses */}
					{/* Paiement listes */}
					<div className="paiment-lists">
						{/* Listes */}
						<div className="lists">
							<div className="col-md-4 float-left">
								Livraison offerte *
                            </div>
							<div className="col-md-4 float-left"></div>
							<div className="col-md-4 float-left align-right">
								<i className="fas fa-check" />
							</div>
						</div>
						{/* Listes */}

						{/* Listes */}
						<div className="lists">
							<div className="col-md-4 float-left">Bonus</div>
							<div className="col-md-4 float-left tarif-livraison">
								+200W
                            </div>
							<div className="col-md-4 float-left align-right">
								✓
                            </div>
						</div>
						{/* Listes */}
					</div>
					{/* Paiement listes */}
				</div>
			</div>
		);
	}
}

export default OrdersComponent;
