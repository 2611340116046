
import { VERSION_LOADING, GET_VERSION } from './types'
import API, {subUrl} from '../utils/api'
export const getVersion =() => dispatch =>{
    dispatch(setVersionLoading());
     API
     .get(subUrl.version)
     .then(res => 
        {
            dispatch({
            type: GET_VERSION,
            payload: res.data
        })
    }
    )
    .catch(error => console.log(error))
}
export const setVersionLoading = () => {
    return {
        type:  VERSION_LOADING
    };
};
