import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import InputRange from "react-input-range";
import {
  getOrders,
  newCheckout,
  setCurrentOrder,
} from "../../../actions/checkoutsActions";
import CartProductItemDesktop from "../../../components/cart/CartProductItemDesktop";
import CartProductItemMobile from "../../../components/cart/CartProductItemMobile";
import API, { subUrl } from "../../../utils/api";
import sort from "../../../utils/helpers";
import history from "../../../utils/history";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pays: [],
      value: null,
      value2: localStorage.getItem("ratePoint")
        ? localStorage.getItem("ratePoint")
        : 0,
      frais_livraison: 0,
      select: "false",
      prix_kg: 0,
      bonus: 0,
      surprise: null,
      total: 0,
      userPoints: 0,
      ratePoint: localStorage.getItem("ratePoint")
        ? localStorage.getItem("ratePoint") * 0.01
        : 0,
      default: "Please select",
    };
  }
  componentDidMount() {
    // this.props.getAdresses()

    API.get(subUrl.zones)
      .then((res) => {
        this.setState({ pays: res.data });
        if (this.props.cart.cartItems.length == 0) {
          localStorage.setItem("ratePoint", 0);
          this.setState({ ratePoint: 0, value2: 0 });
        }
      })
      .catch((error) => console.log(error));
    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });
    $(".popup-price").on("click", function (e) {
      e.preventDefault();
      $(".popup--price").addClass("open");
      $(".scrollTop").addClass("not-showing");
      $("body").addClass("overflow-hidden");
    });
    $(".popup--price-close").on("click", function (e) {
      $(".popup--price").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });
    //console.log("cart", this.props.cart);
    API.get(subUrl.adresses)
      .then((res) => {
        this.onCheck(res.data[0]);
      })
      .catch((error) => console.log(error));
    API.get(subUrl.customerPoint)
      .then((res) => {
        this.setState({ userPoints: res.data.point.prime });
      })
      .catch((error) => console.log(error));
    if (
      this.props.adresses != null &&
      this.props.adresses.adresses != null &&
      this.props.adresses.adresses[0]
    ) {
      this.setState({ default: this.props.adresses.adresses[0].country });
    } else this.setState({ default: "Please select" });
    if (this.state.default != "Please select") {
      let lineItems = [];
      this.props.cart.cartItems.map((item) => {
        let givenItem = {
          product_id: item.id,
          quantity: item.quantity,
          points:
            item && item.options && item.options.only_welt_point
              ? item.pointPrice.value
              : item.pointPrice.value,
          attributes: item.featureProduct,
          fontfaces: item.faceOption,
          gift_wrap: item.gift_wrap,
          licences: item.licences,
          price: item.price,
          variant:
            item.variant && item.variant.length > 0 ? item.variant : null,
          options: item.featureProduct,
          licenceChecked: item.licenceChecked,
          weight: item.weight,
          newPrice: item.newPrice,
        };
        lineItems.push(givenItem);
      });
      const checkout = {
        country_code: this.props.adresses.adresses[0].country,
        total_shipping: this.returnPrice(),
        line_items: lineItems,
      };

      let prix = 0;
      this.props.cart.cartItems.map((item) => {
        prix += item.quantity * item.weight;
      });
      API.post(subUrl.updateOrders, checkout)
        .then((res) => {
          this.setState({
            value: res.data.price,
            bonus: res.data.bonus,
            surprise: res.data.cadeaux,
            total: res.data.total,
            default: res.data.country,
          });
        })
        .catch((error) => console.log(error));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    $(".surprise").removeClass("active");
    $(".surprise").first().addClass("active");
    $(".encore-text").removeClass("active");
    $(".encore-text").first().addClass("active");
    //console.log("cart", this.props.cart);
    if (prevProps.cart.cartItems != this.props.cart.cartItems) {
      let lineItems = [];
      if (this.props.cart.cartItems.length == 0) {
        localStorage.setItem("ratePoint", 0);
        this.setState({ ratePoint: 0, value2: 0 });
      }
      this.props.cart.cartItems.map((item) => {
        let givenItem = {
          product_id: item.id,
          quantity: item.quantity,
          points:
            item && item.options && item.options.only_welt_point
              ? item.pointPrice.value
              : item.pointPrice.value,
          attributes: item.featureProduct,
          fontfaces: item.faceOption,
          gift_wrap: item.gift_wrap,
          licences: item.licences,
          price: item.licenceChecked == "Trial version" ? 0 : item.price,
          variant:
            item.variant && item.variant.length > 0 ? item.variant : null,
          options: item.featureProduct,
          licenceChecked: item.licenceChecked,
          weight: item.weight,
          newPrice: item.newPrice,
        };
        lineItems.push(givenItem);
      });
      const checkout = {
        country_code:
          this.state.default != "Please select"
            ? this.state.default
            : this.props.adresses &&
              this.props.adresses &&
              this.props.adresses &&
              this.props.adresses.adresses &&
              this.props.adresses.adresses[0]
            ? this.props.adresses.adresses[0].country
            : "Please select",
        total_shipping: this.returnPrice(),
        line_items: lineItems,
      };

      let prix = 0;
      this.props.cart.cartItems.map((item) => {
        prix += item.quantity * item.weight;
      });

      API.post(subUrl.updateOrders, checkout)
        .then((res) => {
          //console.log(res.data, checkout)
          this.setState({
            value: res.data.price,
            bonus: res.data.bonus,
            surprise: res.data.cadeaux,
            total: res.data.total,
            default: res.data.country,
          });
        })
        .catch((error) => console.log(error));
    }
  }

  estimation(date) {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + parseInt(date) + 1);
    return newDate;
  }

  returnPrice = () => {
    const { cart, currencyChecked } = this.props;

    let currentPrice = 0,
      unitPrice;
    return cart.cartItems.reduce((acc, current) => {
      const { currencyChecked } = this.props.currencies;
      const ratePiont = current.pointPrice.value * 0.01;
      if (current.choice_product === "Typeface") {
        let start = 0;
        unitPrice = Object.values(current.faceOption).reduce((acc, curr) => {
          if (curr.isChecked) return acc + parseInt(curr.price);
        }, 0);
        let check =
          current.licences &&
          current.licences.filter((s) => s.name == current.licenceChecked);
        if (check != null && check.length != 0)
          if (check[0].name != "Trial version") {
            if (current.newPrice == 0) unitPrice = current.newPrice;
            else unitPrice = current.newPrice;
          } else unitPrice = 0;
      } else {
        if (current.variant && current.variant.length > 0)
          current.variant.map((variant) => {
            if (
              variant.name.attribute_pa_edition ==
              current.featureProduct.edition
            ) {
              unitPrice =
                parseFloat(variant.price) + parseInt(current.gift_wrap_price);
            } else if (
              variant.name.attribute_pa_color == current.featureProduct.color
            ) {
              unitPrice =
                parseFloat(variant.price) + parseInt(current.gift_wrap_price);
            } else {
              unitPrice =
                parseFloat(current.variant[0].price) +
                parseInt(current.gift_wrap_price);
            }
          });
        else {
          if (current.gift_wrap_price != undefined)
            unitPrice = current.price + parseFloat(current.gift_wrap_price);
          else unitPrice = current.price;
        }
      }
      current && current.options && current.options.only_welt_point
        ? (currentPrice = 0)
        : (currentPrice =
            Math.round(
              (unitPrice * currencyChecked.value * current.quantity -
                (current.choice_product != "Typeface" && ratePiont)) *
                100
            ) /
              100 <
            0
              ? 0
              : (currentPrice =
                  Math.round(
                    (unitPrice * currencyChecked.value * current.quantity -
                      (current.choice_product != "Typeface" && ratePiont)) *
                      100
                  ) / 100));
      return acc + currentPrice;
    }, 0);
  };
  returnPointPrice = () => {
    const { cart } = this.props;
    return cart.cartItems.reduce((acc, current) => {
      let pointValue =
        current && current.options && current.options.only_welt_point
          ? current.pointPrice.value * current.quantity
          : current.pointPrice.value;

      return this.state.ratePoint.toFixed(2);
    }, 0);
  };

  now() {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  onChange = (e) => {
    let lineItems = [];
    this.props.cart.cartItems.map((item) => {
      let givenItem = {
        product_id: item.id,
        quantity: item.quantity,
        points:
          item && item.options && item.options.only_welt_point
            ? item.pointPrice.value
            : item.pointPrice.value,
        attributes: item.featureProduct,
        fontfaces: item.faceOption,
        gift_wrap: item.gift_wrap,
        licences: item.licences,
        price: item.licenceChecked == "Trial version" ? 0 : item.price,
        variant: item.variant && item.variant.length > 0 ? item.variant : null,
        options: item.featureProduct,
        licenceChecked: item.licenceChecked,
        weight: item.weight,
        newPrice: item.newPrice,
      };
      lineItems.push(givenItem);
    });
    const checkout = {
      country_code: e.target.value,
      total_shipping: this.returnPrice(),
      line_items: lineItems,
    };
    let prix = 0;
    this.props.cart.cartItems.map((item) => {
      prix += item.quantity * item.weight;
    });

    API.post(subUrl.updateOrders, checkout)
      .then((res) => {
        this.setState({
          value: res.data.price,
          bonus: res.data.bonus,
          surprise: res.data.cadeaux,
          total: res.data.total,
          default: res.data.country,
        });
      })
      .catch((error) => console.log(error));
  };

  onCheck = (address) => {
    let lineItems = [];
    this.props.cart.cartItems.map((item) => {
      let givenItem = {
        product_id: item.id,
        quantity: item.quantity,
        points:
          item && item.options && item.options.only_welt_point
            ? item.pointPrice.value
            : item.pointPrice.value,
        attributes: item.featureProduct,
        fontfaces: item.faceOption,
        gift_wrap: item.gift_wrap,
        licences: item.licences,
        price: item.licenceChecked == "Trial version" ? 0 : item.price,
        variant: item.variant && item.variant.length > 0 ? item.variant : null,
        options: item.featureProduct,
        licenceChecked: item.licenceChecked,
        weight: item.weight,
        newPrice: item.newPrice,
      };
      lineItems.push(givenItem);
    });
    const checkout = {
      country_code: address.country,
      total_shipping: this.returnPrice(),
      line_items: lineItems,
    };
    let prix = 0;
    this.props.cart.cartItems.map((item) => {
      prix += item.quantity * item.weight;
    });
    API.post(subUrl.updateOrders, checkout)
      .then((res) => {
        this.setState({
          value: res.data.price,
          bonus: res.data.bonus,
          surprise: res.data.cadeaux,
          total: res.data.total,
          default: res.data.country,
        });
      })
      .catch((error) => console.log(error));
  };

  login = () => {
    $(".account, .account-offine").click();
    localStorage.setItem("carte-showing",true)
  };

  checkout = () => {
    const { cart, currencyChecked, auth } = this.props;
    let lineItems = [];

    cart.cartItems.map((item) => {
      let givenItem = {
        product_id: item.id,
        quantity: item.quantity,
        points:
          item && item.options && item.options.only_welt_point
            ? item.pointPrice.value
            : item.pointPrice.value,
        attributes: item.featureProduct,
        fontfaces: item.faceOption,
        gift_wrap: item.gift_wrap,
        family: item.family,
        licences: item.licences,
        licenceChecked: item.licenceChecked,
        newPrice: item.newPrice,
        newWeltPrice: item.newWeltPrice,
      };
      lineItems.push(givenItem);
    });

    const checkout = {
      payment_details: {
        method_title: "",
      },
      currency: currencyChecked,
      line_items: lineItems,
      frais: this.state.frais,
      bonus: this.state.bonus,
    };

    const totalPoint = checkout.line_items.reduce((acc, current) => {
      return acc + current.points;
    }, 0);
    if (auth.user.point.prime < totalPoint) {
      this.setState({ showModalPoint: true });
    } else {
      this.props.newCheckout(checkout);
      setTimeout(() => {
        history.push("/checkout");
        // window.location.reload()
        //window.open('/checkout')
      }, 3000);
    }
  };
  render() {
    const { currencyChecked } = this.props.currencies;
    let productPrice = 0,
      unitPrice,
      unitPriceWelt,
      isPhysique = "no";
    let currentPrice = this.returnPrice();
    let totalPoints = this.returnPointPrice();
    const { isAuthenticated, user } = this.props.auth;
    //Pas de couleur ni de size pour l'instant
    /* this.props.cart.cartItems.sort((a, b) =>
      a.featureProduct.color > b.featureProduct.color
        ? 1
        : a.featureProduct.color === b.color
        ? a.featureProduct.size > b.featureProduct.size
          ? 1
          : -1
        : -1
    ); */
    let orderItems;
    let onBack = false;
    let sui, eur, word;
    this.props.cart.cartItems.map((item) => {
      if (
        item?.quantite <= 0 &&
        item?.backorder_check == "yes" &&
        item?.choice_product != "Typeface"
      ) {
        onBack = true;
        item.estimation_de_livraison.map((pays) => {
          pays.pays === "Suisse"
            ? (sui = pays.nombre_de_jours)
            : pays.pays === "Europe"
            ? (eur = pays.nombre_de_jours > 0 ? pays.nombre_de_jours : sui)
            : (word = pays.nombre_de_jours > 0 ? pays.nombre_de_jours : eur);
        });
      }
    });
    return (
      <Panier.Content
        className={`panier-content ${
          window.location.pathname == "/shop" ? "show-boxes" : ""
        }`}
      >
        <Panier.Header className="header-panier col-md-12 uppercase">
          <div className="content">
            <div className="col-md-10 rightside">
              <div className="articles products col-md-6">
                <p className="text text--small">Product(s)</p>
              </div>
              <div className="informations col-md-3">
                <p className="text text--small">Informations</p>
              </div>
              <div className="qty col-md-3">
                <p className="text text--small">Quantity / Price / Balance</p>
              </div>
            </div>
          </div>
        </Panier.Header>
        <Panier.Products className="cart-product content-panier">
          {sort(this.props.cart.cartItems).map((item, i) => {
            if (item.choice_product === "Typeface") {
              let start = 0;
              unitPrice = Object.values(item.faceOption).reduce((acc, curr) => {
                if (curr.isChecked) start += 1;
                return acc + parseInt(curr.price);
              }, 0);
              let check =
                item.licences &&
                item.licences.filter((s) => s.name == item.licenceChecked);
              if (check != null && check.length != 0)
                if (check[0].name != "Trial version") {
                  if (item.newPrice == 0) unitPrice = item.newPrice;
                  else unitPrice = item.newPrice;
                }
                //unitPrice = unitPrice + (check[0].price * start)
                else unitPrice = 0;
              unitPriceWelt = Object.values(item.faceOption).reduce(
                (acc, curr) => {
                  return acc + parseInt(curr.price) * 100;
                },
                0
              );
            } else {
              //onBack = item?.quantite <= 0 && item?.backorder_check == "yes" && true;
              if (item.variant && item.variant.length > 0)
                item.variant.map((variant) => {
                  if (
                    variant.name.attribute_pa_edition ==
                    item.featureProduct.edition
                  ) {
                    unitPrice =
                      parseFloat(variant.price) +
                      parseInt(item.gift_wrap_price);
                    unitPriceWelt = parseFloat(variant.price) * 100;
                  } else if (
                    variant.name.attribute_pa_color == item.featureProduct.color
                  ) {
                    unitPrice =
                      parseFloat(variant.price) +
                      parseInt(item.gift_wrap_price);
                    unitPriceWelt = parseFloat(variant.price) * 100;
                  } else {
                    unitPrice =
                      parseFloat(item.variant[0].price) +
                      parseInt(item.gift_wrap_price);
                    unitPriceWelt = parseFloat(item.variant[0].price) * 100;
                  }
                });
              else {
                this.state.frais_livraison =
                  this.state.frais_livraison +
                  item.poids * item.quantity * this.state.value;
                isPhysique = "yes";
                if (item.gift_wrap_price != undefined)
                  unitPrice =
                    (item.price + parseFloat(item.gift_wrap_price)) *
                    item.quantity;
                else unitPrice = item.price * item.quantity;
                unitPriceWelt = item.welt_price;
              }
            }

            const ratePiont = item.pointPrice.value * 0.01;

            return (
              <div className="content col-md-12" key={i}>
                <button
                  className="delete"
                  onClick={() => {
                    this.props.removeAllFromCart(item);
                  }}
                >
                  <img
                    src={require("../../../assets/images/btn-fermer-panier.svg")}
                  />
                </button>
                {window.innerWidth > 768 ? (
                  <CartProductItemDesktop
                    item={item}
                    onBack={onBack}
                    sui={sui}
                    eur={eur}
                    word={word}
                    addToCart={this.props.addToCart}
                    removeFromCart={this.props.removeFromCart}
                    currencyChecked={currencyChecked}
                    unitPrice={unitPrice}
                    unitPriceWelt={unitPriceWelt}
                  />
                ) : (
                  <CartProductItemMobile
                    item={item}
                    onBack={onBack}
                    sui={sui}
                    eur={eur}
                    word={word}
                    addToCart={this.props.addToCart}
                    removeFromCart={this.props.removeFromCart}
                    currencyChecked={currencyChecked}
                    unitPrice={unitPrice}
                    unitPriceWelt={unitPriceWelt}
                  />
                )}
              </div>
            );
          })}
          {this.props.cart.cartItems.length == 0 ? (
            <p className="empty-basket">No items in the cart </p>
          ) : (
            ""
          )}

          <Panier.Extras className="paiment-lists">
            {this.props.cart.cartItems.length == 0 ? (
              ""
            ) : (
              <div className="lists">
                {this.state.value != null && this.state.value == 0 ? (
                  <div className="list col-md-12">
                    <>
                      <div className="col-md-2-2">
                        <p>Free delivery</p>
                      </div>
                      <div className="col-md-10 float-right align-right __img__">
                        <img src="/images/checked.svg" />
                      </div>
                    </>
                  </div>
                ) : (
                  ""
                )}
                {this.state.surprise != null &&
                  this.state.surprise &&
                  this.state.surprise.map((result) =>
                    this.state.total >= result.total_panier
                      ? result.surprise_a_debloquer.point_a_gagner && (
                          <div className="list col-md-12">
                            <div className="col-md-2-2">
                              <p>Bonus</p>
                            </div>
                            <div className="col-md-10 float-right align-right flex">
                              <div className="col-md-6">
                                <div className="col-md-12">
                                  <div className="col-md-9">
                                    <p>
                                      +
                                      {
                                        result.surprise_a_debloquer
                                          .point_a_gagner
                                      }
                                      W
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 __img__">
                                <img src="/images/checked.svg" />
                              </div>
                            </div>
                          </div>
                        )
                      : ""
                  )}
              </div>
            )}
          </Panier.Extras>
          <Panier.Paiement className="paiment-lists">
            <div className="paiement">
              <div className="padding col-md-6 float-left">
                <div className="bloc-paiement col-md-12">
                  <div className="col-md-4">
                    <p className="bloc-paiement__title">You cumulate</p>
                  </div>
                  <div className="content col-md-8 float-right prime">
                    <div>
                      <div className="nom">Prime</div>
                      <div className="nbr">
                        {this.props.cart.cartItems.reduce((acc, current) => {
                          const currentVal =
                            current.multiplier != false
                              ? Math.floor(current.newWeltPrice / 100) *
                                current.multiplier *
                                current.quantity
                              : Math.floor(current.newWeltPrice / 100) *
                                current.quantity;
                          return (acc + currentVal)  //(localStorage.getItem("ratePoint") ? localStorage.getItem("ratePoint")/100 : 0);
                        }, 0) + this.state.bonus } 
                        W
                      </div>
                    </div>
                    <div className="statut">
                      <div className="nom">Status</div>
                      <div className="nbr">
                        {this.props.cart.cartItems.reduce((acc, current) => {
                          return (
                            acc +
                            Math.floor(
                              current.newWeltPrice /
                                100 /
                                current.categorie_multiplier
                            ) *
                              current.quantity
                          );
                        }, 0)}
                        W
                      </div>
                    </div>
                    <p className="text-panier-bottom">
                      There are many ways to spend your Status W Points. You can
                      redeem your presious collected points directly on your
                      favorite product(s) zhenever you want.
                    </p>
                    <p className="text-panier-bottom">
                      More information on CLUB.ZELTKERN{" "}
                    </p>
                  </div>
                </div>
                <div className="bloc-paiement bloc-surprise">
                  <div className="col-md-4">
                    <p className="bloc-paiement__title">
                      Surprises to <br />
                      unlock
                    </p>
                  </div>
                  <div className="content col-md-8">
                    <div className="text-all-surprise">
                      {this.state.surprise != null &&
                        this.state.surprise &&
                        this.state.surprise[0] &&
                        this.state.total >
                          this.state.surprise[
                            parseInt(this.state.surprise.length) - 1
                          ].total_panier && (
                          <div className="encore-text-debloque">
                            <p>All surprises have been unlocked</p>
                            <div className="col-md-12 col-all"></div>
                          </div>
                        )}
                    </div>
                    <div className="text-still">
                      {this.state.surprise != null &&
                        this.props.cart.cartItems.length != 0 &&
                        this.state.surprise &&
                        this.state.surprise.map(
                          (result) =>
                            result.total_panier - parseInt(this.state.total) >
                              0 && (
                              <div className="encore-text">
                                Still{" "}
                                {result.total_panier -
                                  parseInt(this.state.total)}{" "}
                                CHF
                              </div>
                            )
                        )}
                    </div>
                    <div className="col-md-12">
                      {this.state.surprise != null &&
                        this.props.cart.cartItems.length != 0 &&
                        this.state.surprise &&
                        this.state.surprise.map(
                          (result) =>
                            result.total_panier - parseInt(this.state.total) >
                              0 && (
                              <div
                                className="surprise"
                                style={{
                                  width:
                                    Math.round(
                                      (parseInt(this.state.total) * 100) /
                                        parseInt(result.total_panier)
                                    ) + "%",
                                }}
                              ></div>
                            )
                        )}
                    </div>
                  </div>
                </div>
                <Total.Bonus className="flex col-md-8">
                  <div className="bloc-paiement col-md-12">
                    {this.props.cart.cartItems.length == 0 ? (
                      <div className="total-points col-md-12 float-right">
                        <div className="col-md-12 surprise-locked">
                          <div className="first">
                            <img src="/images/lock.svg" />
                          </div>
                          <div className="last">???</div>
                        </div>
                        <div className="col-md-12 surprise-locked">
                          <div className="first">
                            <img src="/images/lock.svg" />
                          </div>
                          <div className="last">???</div>
                        </div>
                        <div className="col-md-12 surprise-locked">
                          <div className="first">
                            <img src="/images/lock.svg" />
                          </div>
                          <div className="last">???</div>
                        </div>
                        <div className="col-md-12 surprise-locked">
                          <div className="first">
                            <img src="/images/lock.svg" />
                          </div>
                          <div className="last">???</div>
                        </div>
                        <div className="col-md-12 surprise-locked">
                          <div className="first">
                            <img src="/images/lock.svg" />
                          </div>
                          <div className="last">???</div>
                        </div>
                      </div>
                    ) : (
                      <div className="total-points col-md-12 float-right">
                        {this.state.value != null && this.state.value == 0 && (
                          <div className="col-md-12">
                            <div className="first">
                              <img src="/images/unlock.svg" />
                            </div>
                            <div className="bonus">
                              <div className="last">Free delivery</div>
                            </div>
                          </div>
                        )}
                        {this.state.surprise != null ? (
                          this.state.surprise &&
                          this.state.surprise.map((result) =>
                            this.state.total >= result.total_panier ? (
                              <>
                                {result.surprise_a_debloquer.point_a_gagner !=
                                  0 && (
                                  <div className="col-md-12">
                                    <div className="first">
                                      <img src="/images/unlock.svg" />
                                    </div>
                                    <div className="bonus">
                                      <div className="last">
                                        +
                                        {
                                          result.surprise_a_debloquer
                                            .point_a_gagner
                                        }
                                        W
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="col-md-12 surprise-locked">
                                <div className="first">
                                  <img src="/images/lock.svg" />
                                </div>
                                <div className="last">???</div>
                              </div>
                            )
                          )
                        ) : (
                          <>
                            <div className="total-points col-md-8 float-right">
                              <div className="col-md-12 surprise-locked">
                                <div className="first">
                                  <img src="/images/lock.svg" />
                                </div>
                                <div className="last">???</div>
                              </div>
                              <div className="col-md-12 surprise-locked">
                                <div className="first">
                                  <img src="/images/lock.svg" />
                                </div>
                                <div className="last">???</div>
                              </div>
                              <div className="col-md-12 surprise-locked">
                                <div className="first">
                                  <img src="/images/lock.svg" />
                                </div>
                                <div className="last">???</div>
                              </div>
                              <div className="col-md-12 surprise-locked">
                                <div className="first">
                                  <img src="/images/lock.svg" />
                                </div>
                                <div className="last">???</div>
                              </div>
                              <div className="col-md-12 surprise-locked">
                                <div className="first">
                                  <img src="/images/lock.svg" />
                                </div>
                                <div className="last">???</div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </Total.Bonus>
              </div>
              <Total.Price className="total-paiement col-md-6">
                <div className="sous-total-paiement col-md-10 float-right">
                  <div className="item-paiement item-grey col-md-12">
                    <div className="item-label col-md-4">Subtotal</div>
                    <div className="item-prix col-md-6">
                      <span className="col-md-2-2 item__devise">
                        {currencyChecked.key}
                      </span>
                      <span className="col-md-10 item__price price-virgule _price__">
                        {currentPrice && currentPrice.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="item-paiement">
                    <div className="item-label col-md-4">Delivery</div>
                    <div className="item-prix flex col-md-6">
                      <div className="pays col-md-12">
                        <div className="select">
                          <select
                            name="nameValueSelect"
                            style={{
                              color:
                                this.state.select === "Please select" ||
                                this.state.select === "false"
                                  ? "#BBBBBB"
                                  : "",
                            }}
                            className="select__field"
                            required
                            onChange={this.onChange}
                          >
                            <option
                              value={this.state && this.state.default}
                              dangerouslySetInnerHTML={{
                                __html: this.state && this.state.default,
                              }}
                            ></option>
                            {this.state && this.state.pays !== null
                              ? this.state &&
                                this.state.pays[0] &&
                                this.state.pays.map((pays) => (
                                  <optgroup
                                    className="group-list"
                                    label={pays.zone_name}
                                  >
                                    {pays &&
                                      pays.zone &&
                                      pays.zone
                                        .sort(function (a, b) {
                                          if (
                                            a.pays.toLowerCase() <
                                            b.pays.toLowerCase()
                                          )
                                            return -1;
                                          if (
                                            a.pays.toLowerCase() >
                                            b.pays.toLowerCase()
                                          )
                                            return 1;
                                          return 0;
                                        })
                                        .map((location) => {
                                          if (
                                            this.state.default !== location.pays
                                          ) {
                                            return (
                                              <option
                                                value={location.pays}
                                                dangerouslySetInnerHTML={{
                                                  __html: location.pays,
                                                }}
                                              ></option>
                                            );
                                          }
                                        })}
                                  </optgroup>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>
                      {/* <div className="pays col-md-12">Switzerland</div> */}
                      <div className="padding col-md-12">
                        <span>{currencyChecked.key}</span>
                        <span className="price-virgule">
                          {this.state.value !== 0 && this.state.value !== null
                            ? this.state.value.toFixed(2)
                            : "0.00"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {isAuthenticated ? (
                    <>
                      <div className="item-paiement redeem-points">
                        <div className="item-label col-md-4">Redeem Points</div>
                        <div className="item-prix">
                          <span className="col-md-2-2 item__devise"></span>
                          <span className="col-md-10 item__price item-grey _price__">
                            +{this.state.value2}W
                          </span>
                        </div>
                        <div className="input-element">
                          <InputRange
                            draggableTrack
                            maxValue={
                              this.state.userPoints > currentPrice * 100
                                ? currentPrice * 100
                                : this.state.userPoints
                            }
                            minValue={0}
                            step="5"
                            value={currentPrice > 0 ? this.state.value2 : 0}
                            onChange={(value) =>
                              this.setState({ value2: value })
                            }
                            onChangeComplete={(value) => {
                              this.props.addRangeValueToReducer({ value });
                              console.log(
                                this.state.userPoints * 0.001,
                                value,
                                currentPrice
                              );
                              this.setState({ ratePoint: value * 0.01 });
                              localStorage.setItem("ratePoint", value);
                            }}
                          />
                          <span class="popup-price text text--small">?</span>
                        </div>
                      </div>
                      <div className="item-paiement saved-point col-md-12">
                        <div className="item-label col-md-4">Saved 🤑</div>
                        <div className="item-prix">
                          <span className="col-md-2-2 item__devise">
                            {currencyChecked.key}
                          </span>
                          <span className="col-md-10 item__price ">
                            {(
                              currentPrice -
                              totalPoints -
                              currentPrice
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="total col-md-10 float-right">
                  <div className="padding">
                    <div className="item-label col-md-4">Total</div>

                    <div className="item-prix">
                      <span>{currencyChecked.key}</span>
                    </div>
                    <div className="item-prix rigth">
                      <span className="price-virgule price-total">
                        {/* {this.props.cart.totalPrices * currencyChecked.value} */}
                        {currentPrice != undefined &&
                          currentPrice >= 0 &&
                          (
                            currentPrice -
                            totalPoints +
                            this.state.value
                          ).toFixed(2)}
                      </span>
                    </div>
                  </div>

                  {/*
                                    <div className="points">
                                        +{this.props.cart.cartItems.reduce((acc,current) => {
                                                let pointValue = current.options.only_welt_point ? current.pointPrice.value * current.quantity : current.pointPrice.value
                                                return acc + pointValue
                                        }, 0)}W selected
                                    </div>*/}
                </div>
                <div className="points">
                   <span className="del">
                      <del>
                        {totalPoints > 0 && currentPrice != undefined &&
                          currentPrice >= 0 &&
                          (currentPrice + this.state.value).toFixed(2)}
                      </del>
                    </span>
                
                </div>
                <div className="btn-paiement-panier col-md-10">
                  <a
                    onClick={() => {
                      if (window.location.pathname == "/shop") {
                        $(".panier-content").removeClass("open");
                        $("header").removeClass("panier-show");
                        $(".cart").removeClass("active");
                        $("body.body").toggleClass("body-lock");
                      }
                    }}
                    href="/shop"
                    className="continue col-md-6"
                  >
                    Continue shopping
                  </a>
                  {isAuthenticated
                    ? this.props.cart.cartItems.length != 0 && (
                        <Link
                          className="col-md-6 checkout"
                          to={{ pathname: "/checkout" }}
                        >
                          Proceed to checkout
                        </Link>
                      )
                    : this.props.cart.cartItems.length != 0 && (
                        <a
                          className="col-md-6 checkout"
                          onClick={() => {
                            this.login();
                          }}
                        >
                          Proceed to checkout
                        </a>
                      )}
                </div>
              </Total.Price>
              <Popups>
                <div className="popup--price col-md-12">
                  <div className="popup__content">
                    <div className="col-md-8 center">
                      <div className="popup__text-content">
                        <div className="popup__title">
                          <h3 className="uppercase text text--xmedium">
                            HOW TO REDEEM YOUR POINTS
                          </h3>
                        </div>
                        <div className="popup__text popup-redeem-all">
                          <p className="mb-30">
                            Your points : {this.state.userPoints}W
                          </p>
                          <p className="mb-30">
                            There are many ways to spend your Status W Points.
                            You can redeem your precious collected points
                            directly for attractive products or benefits. For
                            example, to get a discount, your next favorite book
                            as a gift or, in the future, enjoy services from our
                            selected partners.
                          </p>

                          <p className="mb-30">
                            A. You determine how many W points to spend on your
                            favorite product.
                            <br />
                            B. Enough points? Treat yourself and get the product
                            of your choice in full!
                            <br />
                            C. What’s more, in the future, you will also benefit
                            from our partners’ advantages.
                          </p>

                          <p>
                            Create an account and receive 500W deductible right
                            away or later.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 right">
                        <button className="popup--price-close btn btn--close">
                          <p>Close</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popups>
            </div>
          </Panier.Paiement>
        </Panier.Products>
      </Panier.Content>
    );
  }
}
const mapStateToProps = (state) => ({
  cart: state.cart,
  currencies: state.currencies,
  auth: state.auth,
  checkouts: state.checkouts,
  orders: state.orders,
  adresses: state.adresses,
});
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch({ type: "ADD", payload: item });
    },
    removeFromCart: (item) => {
      dispatch({ type: "REMOVE", payload: item });
    },
    removeAllFromCart: (item) => {
      dispatch({ type: "REMOVE_ALL", payload: item });
    },
    addRangeValueToReducer: (item) => {
      dispatch({ type: "GET_CART_VALUE", payload: item });
    },
    // getAdresses: () => dispatch(getAdresses()),
    newCheckout: (checkoutData) => dispatch(newCheckout(checkoutData)),
    // getCurrencyData: () => dispatch(getCurrencyData()),
    getOrders: () => dispatch(getOrders()),
    setCurrentOrder: (checkoutData) => dispatch(setCurrentOrder(checkoutData)),
  };
};

const Panier = {
  Content: styled.div`
    font-family: "TWKLausanne-600";
    overflow: scroll;
  `,
  Header: styled.div`
    .rightside {
      float: right;
      padding: 0 8px;
    }
    .products {
      float: left;
    }
    .informations {
      float: left;
    }
    .qty {
      float: right;
    }
  `,

  Products: styled.div`
    .delete {
      z-index: 2;
    }
    .content {
      margin: 4px 0;
    }
    .empty-basket {
      padding: 105px 8px;
      background: white;
      color: #bbbbbb;
      margin-bottom: 250px;
    }
  `,

  Extras: styled.div`
    .lists {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .list {
        background: white;
        display: flex;
        padding: 12px 8px;
        .flex {
          display: flex;
        }
      }

      .__img__{
        display: flex;
        justify-content: right;
        align-items: center;

      }
    }
  `,
  Paiement: styled.div`
    padding: 8px;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    .paiement {
      width: 100%;
    }

    .bloc-paiement {
      .bloc-paiement__title {
        width: 100%;
      }
      &.flex {
        display: flex;
      }
      .content {
        display: flex;
        flex-direction: column;
      }
      .surprise-locked {
        .first,
        .last {
          color: #bbbbbb !important;
        }
      }
    }
  `,
};

const Products = {
  Articles: styled.div`
    min-height: 230px;
    background: white;
    .product__infos {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .delivery {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
      .delivery__national,
      .delivery__international {
        display: flex;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 0.9em;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .date {
        background: #ebebeb;
        padding: 4px 14px;
        width: max-content;
        border-radius: 50px;
      }
      .date time {
        color: #000000;
      }
    }
    .product__img {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      @media screen and (max-width: 480px) {
        img {
          height: auto;
        }
      }
    }
    .product__img,
    .product__name {
      float: left;
    }
  `,

  ArticleLeft: styled.div``,
  ArticleRight: styled.div`
    padding: 8px;

    .product__name,
    .product__infos,
    .product__qty {
      float: left;
    }
    .product__price {
      float: right;
      .license__choice {
        padding: 13px 15px;
        background: black;
        color: white !important;
        border-radius: 50px;
        width: max-content;
        min-width: 50px;
        display: flex;
        justify-content: center;
      }
    }
    .informations__typeface {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      float: left;
      .license__choice {
        padding: 13px 15px;
        background: black;
        color: white !important;
        border-radius: 50px;
        width: max-content;
        min-width: 50px;
        display: flex;
        justify-content: center;
      }

      ul {
        .text--grey {
          color: #d2d2d2 !important;
        }
        li:first-child {
          margin-bottom: 20px;
        }
      }
    }

    .leftside {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      float: left;
      .prix-statut {
        display: flex;
        .statut {
          float: right;
          .flex {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .money__content {
              display: flex;
              align-items: baseline;
            }
          }
        }
      }
    }

    .rightside {
      display: flex;
      height: 100%;
      float: right;

      .product__qty-price-balance {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product__qty {
          .products__number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 10px;
          }
          .increment,
          .decrement {
            position: relative;
            border: 0;
            background-color: transparent;
            display: flex;
            align-content: center;
            justify-content: center;
            width: 20px;
          }
        }

        .product__price {
          display: flex;
          align-items: flex-end;
          .price {
            display: flex;
            float: left;
          }
          .price--welt {
            float: right;
            text-align: right;
            span {
              color: #d2d2d2 !important;
            }
          }
        }
      }
    }
  `,
};

const Total = {
  Price: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
    .del {
      color: #bebebe;
    }
    .del del {
      text-decoration: line-through;
      color: #bebebe;
    }
    .input-element {
      display: flex;
      .popup-price {
        font-family: "TWKLausanne-600";
        background: #ffffff;
        display: flex !important;
        border-radius: 50px;
        height: 15px;
        width: 15px;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        cursor: pointer;
        margin-top: 5px;
        z-index: 1;
      }
    }
    .points {
      width: 83.33%;
      margin: 0 auto;
      text-align: right;
      min-height: 14.67px;
    }
    .btn-paiement-panier {
      float: right;
      padding-top: 70px;
      display: flex;
      gap: 5px;
      .checkout {
        background: black;
        color: white;
      }
    }

    .item-paiement {
      &.item-grey {
        div,
        span {
          color: #bbbbbb;
        }

        ._price__ {
          margin-left: 120px;
        }
      }

      .flex {
        display: flex;
        flex-direction: column;
      }
      .item-label {
        float: left;
      }
      .item-prix {
        display: flex;
        .item__devise {
          float: left;
        }
        .item__price {
          float: right;
          text-align: right;
          word-break: break-all;
        }
        .price-total {
          font-size: 22px;
        }

        .select {
          margin-left: 70px;
        }
      }
      .item-prix {
        .padding {
          padding-top: 5px;
        }
      }
    }
    .redeem-points {
      .item-label {
        float: none;
      }
    }
    .saved-point {
      .item-label,
      .item-prix,
      .item__devise,
      .item__price {
        color: chartreuse;
      }
    }

    .total {
      .padding {
        padding-top: 5px;
      }
      .item-label {
         float: left;
      }
      .item-prix {
        float: left;
        word-break: break-all;
      }
      .rigth {
        float: right;
      }
      .points {
        float: right;
        word-break: break-all;
        text-align: right;
      }
    }
  `,
  Bonus: styled.div`
    display: flex;
    float: right;
  `,
};
const Popups = styled("div")`
  .popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(0, 0, 0, 0.5);
    font-family: "TWKLausanne-600";

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }

    .popup__content {
      display: flex;
      justify-content: flex-start;
      height: auto;
      width: 60%;
      margin: auto;
      padding-bottom: 100px;
      background: RGBA(235, 235, 235, 1);
    }
    .center {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .right {
      display: flex;
      justify-content: flex-end;
    }
    .popup__title {
       margin-bottom: 70px;
    }
    .mb-30 {
       margin-bottom: 30px;
    }
    .mb-40 {
       margin-bottom: 40px;
    }
    .underline {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 480px) {
    .popup--price {
      .popup__title {
         margin-bottom: 30px;
      }
      &.open {
        padding: 0 20px;
      }
    }
    h3 {
      color: #000000;
    }
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
