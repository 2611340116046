import { GET_CURRENCIES, CURRENCY_LOADING } from "./types";
import API, { subUrl } from "../utils/api";
// get pages Currency
export const getCurrencyData = () => (dispatch) => {
  dispatch(setCurrenciesLoading());
  API.get(subUrl.currencies)
    .then((res) => {
      dispatch({
        type: GET_CURRENCIES,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
// Currencies loading
export const setCurrenciesLoading = () => {
  return {
    type: CURRENCY_LOADING,
  };
};
export const changeCurrency = (currency) => (dispatch) => {
  dispatch({ type: "CHANGE_CURRENCY", payload: currency });
};
