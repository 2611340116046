import $ from 'jquery';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

export const CheckBox = props => {
	return (
		<div className="list-typeface">
			<input type="checkbox" key={props.id} onClick={props.handleCheckChieldElement} checked={props.isChecked} name={props.value.name.toLowerCase().replace(/ /g, '_')} id={props.value.name.toLowerCase().replace(/ /g, '_') + "_" + props.group} value={props.value.name} />
			<label for={props.value.name.toLowerCase().replace(/ /g, '_') + "_" + props.group} className={props.value.name}>{props.value.name}</label>
		</div>
	)
}

class TypeFaceOptions extends Component {
	constructor(props) {
		super(props)
		const persistedOption = this.props.option
		this.state = {
			options: [],
		}
		if (this.props.elements) {
			this.props.elements.map((persisted, index) => {
				this.state.options.push({
					group: persisted.group,
					id: persisted.id,
					value: persisted.value,
					isChecked: persistedOption == null ?  true : persistedOption[index].isChecked,
				})

			})
		}
		this.state.options = lodash.groupBy(this.state.options, 'group');
	}

	componentDidMount() {
		this.props.getCurrentOption(this.state.options)
		this.props.getFont(this.props.option1)

		console.log("option",this.state.option1)
		let options = this.state.options
		let fam_ess=0;
		let famhal = 0;
		let all= 0;
		let by=0;

		this.props.group.forEach(group => options[group].forEach(option => {
				//console.log(option.isChecked);
				if (option.isChecked) {
					all++;
				}

				if (option.value.family.includes('Essential') && option.isChecked) {
					fam_ess++;
				}

				if (option.value.family.includes('Half Family') && option.isChecked) {
					famhal++;
				}
				if (option.value.family.includes('By Item') && option.isChecked) {
					by++;
				}


			}))

		//console.log('displays',this.props.display)


			if(all == this.props.total_complete){
				$('#complete_family').prop("checked", true);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", false);
				this.props.getFamilyPrice(this.props.priceFamily.complete)
			}

			else if (famhal == this.props.total_half && all <= this.props.total_half){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", true);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", false);
				this.props.getFamilyPrice(this.props.priceFamily.half)
			}
			else if (fam_ess == this.props.total_essen && all <= this.props.total_essen){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", true);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", false);
				this.props.getFamilyPrice(this.props.priceFamily.essential)
			}
			else if (by == 1){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", true);
				$('#deselectAll').prop("checked", false);
				this.props.getFamilyPrice(0)
			}
			else{
				{
					$('#complete_family').prop("checked", false);
					$('#half').prop("checked", false);
					$('#essentials').prop("checked", false);
					$('#byitem').prop("checked", false);
					$('#deselectAll').prop("checked", true);
				}
			}




		$('#deselectAll').change(function () {
			if ($(this).is(':checked')) {
				$('.face-style').each(function () {
					$(this).find('label').removeClass('checked');
				})
			}
		})
	}
	componentDidUpdate(prevProps, prevState) {
		 
		let options = this.state.options
		let fam_ess=0;
		let famhal = 0;
		let all= 0;
		let by=0;

		this.props.group.forEach(group => options[group].forEach(option => {
				
				if (option.isChecked) {
					all++;
				}

				if (option.value.family.includes('Essential') && option.isChecked) {
					fam_ess++;
				}

				if (option.value.family.includes('Half Family') && option.isChecked) {
					famhal++;
				}
				if (option.value.family.includes('By Item') && option.isChecked) {
					by++;
				}


			}))

			if(all == 0){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", true);
			}

			else if(all == this.props.total_complete && this.props.display.complete){
				$('#complete_family').prop("checked", true);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", false);
			}

			else if (famhal == this.props.total_half && all <= this.props.total_half && this.props.display.half){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", true);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", false);
			}
			else if (fam_ess == this.props.total_essen && all <= this.props.total_essen && this.props.display.essential){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", true);
				$('#byitem').prop("checked", false);
				$('#deselectAll').prop("checked", false);
			}
			else if (by == 1 && all == 1){
				$('#complete_family').prop("checked", false);
				$('#half').prop("checked", false);
				$('#essentials').prop("checked", false);
				$('#byitem').prop("checked", true);
				$('#deselectAll').prop("checked", false);
			}
			else{
				{
					$('#complete_family').prop("checked", false);
					$('#half').prop("checked", false);
					$('#essentials').prop("checked", false);
					$('#byitem').prop("checked", false);
					$('#deselectAll').prop("checked", false);
				}
			}
	}
	handleAllChecked = (event) => {

		let options = this.state.options
		if (event.target.value == "complete_family") {
			this.props.group.forEach(group => options[group].forEach(option => option.isChecked = event.target.checked))

			this.props.getFamilyPrice(this.props.priceFamily.complete)
		}
		if (event.target.value == "essentials") {
			this.props.group.forEach(group => options[group].forEach(option => option.isChecked = false))
			this.props.group.forEach(group => options[group].forEach(option => {
				option.value.family.filter((e) => e === 'Essential' ? option.isChecked = event.target.checked : console.log('NO'))
				this.props.getFamilyPrice(this.props.priceFamily.essential)

			}))

		}
		if (event.target.value == "half") {
			this.props.group.forEach(group => options[group].forEach(option => option.isChecked = false))
			this.props.group.forEach(group => options[group].forEach(option => {
				option.value.family.filter((e) => e === 'Half Family' ? option.isChecked = event.target.checked : console.log('NO'))
				this.props.getFamilyPrice(this.props.priceFamily.half)

			}))

		}
		if (event.target.value == "byitem") {
			this.props.group.forEach(group => options[group].forEach(option => option.isChecked = false))
			this.props.group.forEach(group => options[group].forEach(option => {
				option.value.family.filter((e) => e === 'By Item' ? option.isChecked = event.target.checked : console.log('NO'))
				this.props.getFamilyPrice(0)

			}))

		}
		this.setState({ options: options })
		this.props.getCurrentOption(this.state.options)


	}



	handleCheckChieldElement = (event) => {
		let options = this.state.options
		let fam_ess=0;
		let famhal = 0;
		let all= 0;
		let by=0;
		this.props.getFamilyPrice(0)
		this.props.group.forEach(group => options[group].forEach(option => {
			if (option.value.name.toLowerCase().replace(/ /g, '_') + '_' + group === event.target.id) {
				option.isChecked = event.target.checked
			}
			if (option.isChecked) {
				all++;
			}

			if (option.value.family.includes('Essential') && option.isChecked) {
				fam_ess++;
			}

			if (option.value.family.includes('Half Family') && option.isChecked) {
				famhal++;
			}
			if (option.value.family.includes('By Item') && option.isChecked) {
				by++;
			}

		}))

		if(all == 0){

		}

		else if(all == this.props.total_complete){
			this.props.getFamilyPrice(this.props.priceFamily.complete)
		}

		else if (famhal == this.props.total_half && all <= this.props.total_half){
			this.props.getFamilyPrice(this.props.priceFamily.half)
		}
		else if (fam_ess == this.props.total_essen && all <= this.props.total_essen){
			this.props.getFamilyPrice(this.props.priceFamily.essential)
		}
		else if (by == 1 && all == 1){
			this.props.getFamilyPrice(0)
		}
		else{
			{
				this.props.getFamilyPrice(0)
			}
		}




		this.setState({ options: options })
		this.props.getCurrentOption(this.state.options)


	}
	handleDeselectAllChecked = (event) => {
		let options = this.state.options
		this.props.group.forEach(group => options[group].forEach(option => option.isChecked = false))
		this.setState({ options: options })
		this.props.getCurrentOption(this.state.options)
	}
	render() {
		return (
			<Options.Content>
				<Options.Categories className="col-md-4 float-left">
					<form name>
						{this.props.display.complete ? 
						<div className="col-md-12 options--complete">
							<div className="list-typeface" style={{ paddingTop: 47 }}>
								<input type="radio" name="complete_family" id="complete_family" value="complete_family" onClick={this.handleAllChecked} />
								<label for="complete_family">Complete Family</label>
							</div>
							</div>
							: ''}
						{this.props.display.half ? 
						<div className="col-md-12 options--half">
							<div className="list-typeface">
								<input type="radio" name="complete_family" id="half" value="half" onClick={this.handleAllChecked} />
								<label for="half">Half Family</label>
							</div>
							</div>
							: ''}
						{this.props.display.essential ? 
						<div className="col-md-12 options--essentials">
							<div className="list-typeface">
								<input type="radio" name="complete_family" id="essentials" value="essentials" onClick={this.handleAllChecked} />
								<label for="essentials">Essential</label>
							</div>
						</div>
							: ''}
						<div className="col-md-12 options--by-item">
							<div className="list-typeface">
								<input type="radio" name="byitem" id="byitem" value="byitem" onClick={this.handleAllChecked} />
								<label for="byitem">By Item</label>
							</div>
						</div>
						<div className="col-md-12 options--none">
							<div className="list-typeface">
								<input type="radio" name="complete_family" id="deselectAll" value="deselectAll" onClick={this.handleDeselectAllChecked} />
								<label for="deselectAll" className="btn--deselect-all">Deselect all</label>
							</div>
						</div>
					</form>
				</Options.Categories>
				<Options.Options className="col-md-4 float-left all-fonts">
					<form name>
						<p>{this.props.fontName}</p>
						<ul className="col-md-12">
							{
								this.props && this.props.group.map((group => {
									return (
										<>
											<p>{group}</p>
											{this.state.options[group].map((option) => {
												return (
													<>
														<CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...option} />
													</>
												)
											})
											}
										</>)
								}))
							}
						</ul>
					</form>
				</Options.Options>
			</Options.Content>
		);
	}
}

const mapStateToProps = (state) => {
	return ({
		currentOption: state.currentProduct,
	})
}
const mapDispatchToProps = (dispatch) => {
	return {

		getCurrentOption: (item) => {
			dispatch({ type: 'GET_CURRENT_OPTION', payload: item })
		},
		getFamilyPrice: (item) => {
			dispatch({ type: 'GET_CURRENT_FAMILY', payload: item })

		},
		getFont: (item) => {
			dispatch({ type: 'GET_CURRENT_FONT', payload: item })

		},
	}
}

const Options = {
	Content: styled.div``,
	Categories: styled.div`
    .options--essentials { margin-bottom: 40px; }
    .btn--deselect-all { color: #bbbbbb; }
    .btn--deselect-on { color: black; }
    `,
	Options: styled.div``,
}
export default connect(mapStateToProps, mapDispatchToProps)(TypeFaceOptions)
