import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../../utils/Spinner';
import { getCustomer } from '../../../actions/userActions'
import API, { subUrl } from '../../../utils/api'

class MenuDetail extends Component {
	constructor() {
		super()
		this.state = {
			status: []

		}
		API
			.get(subUrl.status)
			.then(res => {

				console.log(res.data)
				this.setState({ status: res.data })
			}
			)
			.catch(error => console.log(error))
	}
	componentDidMount() {
		this.props.getCustomer()
	}

	render() {
		const { users, status } = this.props;
		let displayUser;

		if (users.user == null) {
			displayUser = <Spinner />
		}
		else {
			displayUser = (
				<div className="col-md-9 float-left">
					<p className="Hello">Hello,</p>
					<div className="name UserInfos">

						{
							users && users.user && users.user.user_firstname
						}
						{
							" "
						}
						{
							users && users.user && users.user.user_lastname
						}
					</div>


					{this.state.status && this.state.status[0] && this.state.status[0].points && this.state.status[0].points != undefined ?

						users.user.point.status >= this.state.status[0].points && users.user.point.status < this.state.status[1].points ?
							<div className="status basic UserInfos" style={{ backgroundColor: this.state.status[0].color }}>REGULAR</div>
							: users.user.point.status >= this.state.status[1].points && users.user.point.status < this.state.status[2].points ?
								<div className="status silver UserInfos" style={{ backgroundColor: this.state.status[1].color }}>MEDIUM</div>
								: users.user.point.status >= this.state.status[2].points && users.user.point.status < this.state.status[3].points ?
									<div className="status gold UserInfos" style={{ backgroundColor: this.state.status[2].color }}>BOLD</div>
									: users.user.point.status >= this.state.status[3].points ?
										<div className="status platinium UserInfos" style={{ backgroundColor: this.state.status[3].color }}>HEAVY</div>
										:
										''
						:
						''
					}
				</div>
			)
		}
		return (
			displayUser
		)
	}
}
MenuDetail.propTypes = {
	getCustomer: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	users: state.users
})

export default connect(mapStateToprops, { getCustomer })(MenuDetail)
