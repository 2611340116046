export const SET_CURRENT_USER        = 'SET_CURRENT_USER'
export const GET_CUSTOMER            = 'GET_CUSTOMER'
export const GET_PRODUCTS            = 'GET_PRODUCTS'
export const GET_PRODUCTS_PAGE       = 'GET_PRODUCTS_PAGE'
export const ADD_PRODUCT_ON_PRODUCTS_PAGE       = 'ADD_PRODUCT_ON_PRODUCTS_PAGE'
export const GET_PRODUCT_BY_ID       = 'GET_PRODUCT_BY_ID'
export const GET_PAGES               = 'GET_PAGES'
export const GET_HEADERS             = 'GET_HEADERS'
export const GET_CATEGORIES          = 'GET_CATEGORIES'
export const TOGGLE_FAVORIE          = 'TOGGLE_FAVORIE'
export const TOGGLE_FAVORITE          = 'TOGGLE_FAVORITE'
export const CHANGE_LANGUE           = 'CHANGE_LANGUE'
export const CATEGORIES_LOADING      = ' CATEGORIES_LOADING'
export const FAVORITES_LOADING      = ' FAVORITES_LOADING'
export const PRODUCT_LOADING         = ' PRODUCT_LOADING'
export const PAGES_LOADING           = 'PAGES_LOADING'
export const GET_FOOTER              = 'GET_FOOTER'
export const FOOTER_LOADING          = 'FOOTER_LOADING'
export const BANNER_LOADING          = 'BANNER_LOADING'
export const GET_BANNER              = 'GET_BANNER'
export const GET_POSTS               = 'GET_POSTS'
export const POST_LOADING            = 'POST_LOADING'
export const GET_CURRENCIES          = 'GET_CURRENCIES'
export const CURRENCY_LOADING        = 'CURRENCY_LOADING'
export const CHANGE_CURRENCY         = 'CHANGE_CURRENCY'
export const GET_ADRESSES            = 'GET_ADRESSES'
export const ADRESSES_LOADING        = 'ADRESSES_LOADING'
export const DELET_ADRESSES          = 'DELET_ADRESSES'
export const UPDATE_ADRESSES         = 'UPDATE_ADRESSES'
export const ADD_ADRESSES            = 'ADD_ADRESSES'
export const CHANGE_ADDRESS          = 'CHANGE_ADDRESS'
export const CHANGE_ADDRESS_FACTURATION          = 'CHANGE_ADDRESS_FACTURATION'
export const GET_ORDERS              = 'GET_ORDERS'
export const ORDERS_LOADING          = 'ORDERS_LOADING'
export const GET_MEDIAS              = 'GET_MEDIAS'
export const MEDIA_LOADING           = 'MEDIA_LOADING'
export const GET_FAQS                = 'GET_FAQS'
export const FAQ_LOADING             = 'FAQ_LOADING'
export const SET_CURRENT_ORDER       = 'SET_CURRENT_ORDER'
export const SET_STATUS              = 'SET_STATUS'
export const PAYED_LOADING           = 'PAYED_LOADING'
export const INIALIZE_CART           = 'INIALIZE_CART'
export const INIALIZE_CHECKOUT       = 'INIALIZE_CHECKOUT'
export const GET_VERSION             = 'GET_VERSION'
export const VERSION_LOADING         = 'VERSION_LOADING'
export const PAYMENT_LOADING         = 'PAYMENT_LOADING'
export const GET_PAYMENT             = 'GET_PAYMENT'
export const GET_CATEGORIES_POST     = 'GET_CATEGORIES_POST'
export const CATEGORIES_POST_LOADING = 'CATEGORIES_POST_LOADING'
export const GET_RESET_CODE          = 'GET_RESET_CODE'