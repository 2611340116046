import $ from "jquery";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  InitialiazeCart,
  InitialiazeCheckout,
} from "./actions/checkoutsActions";
import { getVersion } from "./actions/versionActions";
import "./App.css";
import PaymentFailed from "./components/";
import Account from "./components/account/index";
import Informations from "./components/account/informations";
import MesInformations from "./components/account/mes-informations";
import Index from "./components/index";
import NotFound from "./components/NotFound";
import IndexShop from "./components/shop";
import Shop2 from "./components/shop/shop2";
import Apparel from "./components/shop/apparel";
import Books from "./components/shop/books";
import Details from "./components/shop/details";
import Other from "./components/shop/other";
import Rare from "./components/shop/rare";
import Stationeries from "./components/shop/stationeries";
import Stationery from "./components/shop/stationery";
import Supplies from "./components/shop/supplies";
import Typefaces from "./components/shop/typefaces";
import SupportAbout from "./components/support/about";
import SupportCalendar from "./components/support/calendar";
import SupportContact from "./components/support/contact";
import SupportDownload from "./components/support/download";
import Faq from "./components/support/faq";
import Support from "./components/support/index";
import SupportInformation from "./components/support/informations";
import TypeFaceDetails from "./components/typeface/TypeFaceDetails";
import TypeFaceShop from "./components/typeface/TypeFaceShop";
import Weltclub from "./components/weltclub";
import ActiveUser from "./components/_includes/account/ActiveUser";
import CreateNewPassword from "./components/_includes/account/CreateNewPassword";
import DetailProduct from "./components/_includes/account/DetailProduct";
import ForgetPassword from "./components/_includes/account/ForgetPassword";
import Inventory from "./components/_includes/account/Inventory";
import Register from "./components/_includes/account/Register";
import RegisterParr from "./components/_includes/account/RegisterParr";
import ResetPassword from "./components/_includes/account/ResetPassword";
import Checkout from "./components/_includes/Checkout/Checkout";
import CheckoutStripe from "./components/_includes/Checkout/CheckoutStripe";
import PaymentSuccess from "./components/_includes/Checkout/PaymentSuccess";
import FavoritesList from "./components/_includes/favorite/FavoritesList";
import ScrollTop from "./ScrollArrow";
import API, { subUrl } from "./utils/api";
import messages from "./utils/messages";
import PrivateRoute from "./utils/PrivateRoute";
const tokenProvider = require("axios-token-interceptor");

class App extends Component {
  state = {
    version: null,
  };

  componentDidMount() {
    $("html,body").animate(
      {
        scrollTop: 0,
      },
      "slow"
    );

    API.get(subUrl.version)
      .then((res) => {
        this.setState({ version: res.data });
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { translation, auth, version } = this.props;
    if (version.version != null && this.state.version != null) {
      console.log(version.version.version, this.state.version.version);
      if (this.state.version.version !== 0.1) {
        this.props.InitialiazeCart();
      }
    }

    if (auth.isAuthenticated) {
      API.interceptors.request.use(
        tokenProvider({
          token: auth.user.token,
        })
      );
      const currentTime = Date.now();
      if (auth.date / 1000 + auth.user.exp < currentTime / 1000) {
        this.props.logoutUser();
        localStorage.clear();
      }
    }
    /* let i = 0;
    API.interceptors.request.use(req => {
      console.log(`axios request ${req.method} ${req.url} ${++i}`);
      // Important: request interceptors **must** return the request.
      
      return req;
    }); */

    return (
      <>
        <IntlProvider locale={translation} messages={messages[translation]}>
          <BrowserRouter basename={"/"}>
            <Switch>
              <Route
                exact
                path={`/`}
                component={(props) => (
                  <Index timestamp={new Date().toString()} {...props} />
                )}
              />
              <Route
                exact
                path={`/home`}
                component={(props) => (
                  <Index timestamp={new Date().toString()} {...props} />
                )}
              />
              <Route exact path={`/account`} component={Account} />
              <Route
                exact
                path={`/account/mes-informations`}
                component={MesInformations}
              />
              <Route
                exact
                path={`/account/informations`}
                component={Informations}
              />
              <PrivateRoute
                exact
                path={`/account/inventory`}
                component={Inventory}
              />
              <Route
                exact
                path={`/checkout/payment-successful`}
                component={PaymentSuccess}
              />
              <Route
                exact
                path={`/checkout/payment-failed`}
                component={PaymentFailed}
              />
              <Route exact path={`/shop`} component={IndexShop} />
              <Route exact path={`/shop2`} component={Shop2} />
              <Route exact path={`/shop/other`} component={Other} />
              <Route
                exact
                path={`/shop/stationeries`}
                component={Stationeries}
              />
              <Route exact path={`/shop/stationery`} component={Stationery} />
              <Route exact path={`/shop/supplies`} component={Supplies} />
              <Route exact path={`/shop/typefaces`} component={Typefaces} />
              <Route exact path={`/shop/books`} component={Books} />
              <Route exact path={`/shop/rare`} component={Rare} />
              <Route exact path={`/shop/apparel`} component={Apparel} />
              <Route
                exact
                path={`/detailProduct/:id`}
                component={DetailProduct}
              />
              <Route
                exact
                path={`/shop/detail-product/:id`}
                component={Details}
              />
              <Route
                exact
                path={`/shop/detail-typeface/:id`}
                component={TypeFaceDetails}
              />
              <Route exact path={`/typeface`} component={TypeFaceShop} />
              
              <Route exact path={`/club`} component={Weltclub} />
              <Route exact path={`/support`} component={Support} />
              <Route exact path={`/support_/about`} component={SupportAbout} />
              <Route
                exact
                path={`/support_/informations`}
                component={SupportInformation}
              />
              <Route
                exact
                path={`/support_/download`}
                component={SupportDownload}
              />
              <Route
                exact
                path={`/support_/updates`}
                component={SupportCalendar}
              />
              <Route
                exact
                path={`/support_/contact`}
                component={SupportContact}
              />
              <Route exact path={`/support_/faq`} component={Faq} />
              <Route exact path={`/checkout`} component={Checkout} />
              <Route exact path={`/register`} component={Register} />
              <Route
                exact
                path={`/register/:key/:user`}
                component={RegisterParr}
              />
              <Route exact path={`/favorites`} component={FavoritesList} />
              <Route
                exact
                path={`/passwort-reset`}
                component={ForgetPassword}
              />
              <Route
                exact
                path={`/password-reset/:key/:user`}
                component={ResetPassword}
              />
              <Route
                exact
                path={`/active-user/:key/:user`}
                component={ActiveUser}
              />
              <Route
                exact
                path={`/passwort-confirm`}
                component={CreateNewPassword}
              />
              <PrivateRoute
                exact
                path={`/checkout/payment`}
                component={CheckoutStripe}
              />
              <Route exact path="*" component={NotFound} />
              {/* <Route exact path="*" render={({ location, history }) => process.env.NODE_ENV ? <Redirect to={`/site${location}`} /> : <NotFound />} > </Route> */}
            </Switch>
          </BrowserRouter>
        </IntlProvider>
        <ScrollTop />
        <div className="technical-content-site">
          <h2>WELTKERN®</h2>
          <p>
            The Website is experiencing technical issues, we wiil be back very
            soon.
          </p>
          <div className="hosting-info">
            <a href="https://www.instagram.com/weltkern/">WELTKERN</a>
            <a href="https://www.instagram.com/type.weltkern/">TYPE.WELTKERN</a>
          </div>
        </div>

        <div className="responsive-content-site">
          <h2>WELTKERN®</h2>
          <p>
            The responsive version of the site <br />
            is under maintenance
            <br />
            <br />
            please browse the site on a desktop
          </p>
        </div>
      </>
    );
  }
}

App.protoTypes = {
  translation: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getVersion: PropTypes.func.isRequired,
  InitialiazeCart: PropTypes.func.isRequired,
  InitialiazeCheckout: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  translation: state.translation.language,
  auth: state.auth,
  version: state.version,
});
export default connect(mapStateToProps, {
  getVersion,
  InitialiazeCart,
  InitialiazeCheckout,
})(App);
