import { GET_FOOTER, FOOTER_LOADING } from "./types";
import API, { subUrl } from "../utils/api";
export const getfooterData = () => (dispatch) => {
  dispatch(setFooterLoading());

  API.get(subUrl.fouters)
    .then((res) => {
      dispatch({
        type: GET_FOOTER,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

// Categorie loading

export const setFooterLoading = () => {
  return {
    type: FOOTER_LOADING,
  };
};
