import React, { Component } from 'react';
import { logoutUser } from '../../../actions/authActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import $ from 'jquery'

class MenuAccount extends Component {

	componentDidMount() {
		$(".linkAccountLeft li a").on('click', function () {
			$('header.my-account ul li a').toggleClass("actif")
			//// window.location.reload()
		})
	}

	render() {
		const { isAuthenticated, user, date } = this.props.auth
		if (isAuthenticated) {
			const currentTime = Date.now();
			if ((date / 1000) + user.exp < currentTime / 1000) {
				this.props.logoutUser()
			}
		}
		return (
			<div className="col-md-3 float-left linkAccountLeft">
				<ul>
					<li><a href="/account" className="Menu">Dashboard</a></li>
					<li><a href="/account/informations" className="Menu2">Informations</a></li>
					<li><a href="/account/inventory" className="Menu3">Inventory</a></li>
					{/*}<li><a href="/favorites" className="Menu4">Mes préférences</a></li>
				<li><a className="link-chat Menu5">Tchat</a></li>*/}
					<li><a className="logout" onClick={() => {this.props.logoutUser()}} >Log out</a></li>
				</ul>
			</div>
		)
	}
}
MenuAccount.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth
})

export default connect(mapStateToprops, { logoutUser })(MenuAccount)
