import { SET_CURRENT_USER, GET_RESET_CODE } from '../actions/types'
import isEmpty from '../validation/is-empty';


const initialState = {
	isAuthenticated: false,
	user: {},
	date: null,
	code: ''
}
const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !isEmpty(action.payload),
				user: action.payload,
				date: !isEmpty(action.payload) ? Date.now() : null
				
			};
		case GET_RESET_CODE:
			return {
				...state,
				code: action.payload,
				loading: false
			};

		default:
			return state
	}
}
export default authReducer