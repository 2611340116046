import {GET_BANNER,  BANNER_LOADING} from '../actions/types'

const initialState = { 
    banner: null,
    loading: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case BANNER_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_BANNER:
            return {
                ...state,
                banner: action.payload,
                loading: false
            };
        default: 
           return state
    }

}