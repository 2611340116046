import React, { Component } from 'react';
import HeaderIndex from "../header/Index";
import FooterIndex from "../footer/FooterIndex";
import Spinner from '../../utils/Spinner'
import Cart from "../lastProduits/Cart"
import OrdersComponent from '../account/OrdersComponent'
import { getfooterData } from '../../../actions/footerActions';
import { getOrders } from '../../../actions/checkoutsActions';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import $ from 'jquery'


class PaymentSuccess extends Component {
	componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
		this.props.getfooterData()
		this.props.getOrders()
	}
	render() {
		const insideHeader = (
			<div className="my-account col-md-12 relative padding-t-50 clearfix">
				<div className="col-md-4 float-left">
					<p>Bonjour,</p>
					<div className="name">Anthony Monnier</div>
					<div className="status silver">SILVER</div>

				</div>
				<div className="col-md-4 float-left">
					<h2> Payment Successful </h2>
				</div>
				{/* <Account /> */}
			</div>
		);

		// Dynamic Footer
		const { footerData, checkouts } = this.props;
		const { orders } = checkouts
		let displayFooter, displayOrders;
		if (footerData.footerData === null || footerData.loading) {
			displayFooter = <Spinner />
		} else {
			let found = false;
			displayFooter = footerData.footerData.map((footer, i) => {
				if (footer.pages !== null) {
					let founded = (footer.pages.indexOf("inventaire") > -1);
					if (founded) {
						found = true;
						return (
							<FooterIndex
								key={i}
								footer={footer}
							/>
						)
					}
				}
			})
			if (!found) {
				displayFooter = <FooterIndex />
			}
		}
		// Orders
		if (orders === null || checkouts.loading) {
			displayOrders = <Spinner />
		} else {

			displayOrders = orders.map((order, i) => {
				return (
					<OrdersComponent
						key={i}
						order={order}
					/>
				)
			})
			// })
		}


		return (
			<div>
				<HeaderIndex insideHeader={insideHeader} />
				<div className="col-md-12 clearfix">
					<div className="col-md-10 container-center">
						<div className="menu-account">
							<ul className="d-flex-row menu-my-account">
								<li><a href="#commandes">Mes commandes</a></li>
								<li><a href="#licenses">Mes licenses</a></li>
								<li><a href="#code_cadeaux">Code / cartes cadeaux</a></li>
								<li><a href="#avis">Mes points</a></li>
							</ul>
						</div>
					</div>
					{/* Overview */}
					{displayOrders}
					{/* <OrdersComponent /> */}
					{/* Overview */}
					{/* Adresse */}
					<div className="col-md-12 list-account" id="address">
						<div className="top-list col-md-10 container-center">
							<div className="col-md-6 float-left">
								<h4>Addresses Shipping</h4>
								<div className="list">

								</div>

							</div >
							<div className="col-md-6 float-left">
								<h4>Addresses Billing</h4>
								<div className="list">

								</div>
							</div >
						</div>
					</div>
					{/* Adresse */}
					{/* Avantages status */}
					{/* Avantages status */}
				</div>

				{displayFooter}
				<Cart />
			</div>
		)
	}
}
PaymentSuccess.propTypes = {
	getfooterData: PropTypes.func.isRequired,
	getOrders: PropTypes.func.isRequired,
	footerData: PropTypes.object.isRequired,
	checkouts: PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	footerData: state.footerData,
	checkouts: state.checkouts
})
export default connect(mapStateToprops, { getfooterData, getOrders })(PaymentSuccess)