import {GET_FOOTER,  FOOTER_LOADING} from '../actions/types'

const initialState = { 
    footerData: null,
    loading: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case FOOTER_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_FOOTER:
            return {
                ...state,
                footerData: action.payload,
                loading: false
            };
        default: 
           return state
    }

}