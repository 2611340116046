/** @format */

import classnames from "classnames";
import $ from "jquery";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import InputRange from "react-input-range";
import { Link } from "react-router-dom";
import styled from "styled-components";
import API, { subUrl } from "../../utils/api";
import isEmpty from "../../validation/is-empty";
import TypeFaceOptions from "./TypeFaceOptions";

class OverviewTypeFace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      color: "black",
      size: "large",
      model: "a",
      isWrapCheched: false,
      licenceChecked:
        this.props.cartItem != undefined
          ? this.props.cartItem.licenceChecked
          : "S",
      lastName:
        this.props.cartItem !== undefined &&
        this.props.cartItem.licence !== null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.lastName
          : "",
      Company:
        this.props.cartItem !== undefined &&
        this.props.cartItem.licence !== null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.Company
          : "",
      Website:
        this.props.cartItem !== undefined &&
        this.props.cartItem.licence !== null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.Website
          : "",
      firstName:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.firstName
          : "",
      complememt:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.complememt
          : "",
      address:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.address
          : "",
      city:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.city
          : "",
      numero:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.numero
          : "",
      paye:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.paye
          : "",
      societe:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.societe
          : "",
      titre:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.titre
          : "",
      email:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.email
          : "",
      state:
        this.props.cartItem != undefined &&
        this.props.cartItem.licence != null &&
        this.props.cartItem.licence !== undefined
          ? this.props.cartItem.licence.state
          : "",
      licence: null,
      active: false,
      message: "",
      primes: 0,
      pays: [],
      favorites: [],
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitLisence = (e) => {
    e.preventDefault();
    const licence = {
      lastName: this.state.lastName,
      Company: this.state.Company,
      Website: this.state.Website,
      firstName: this.state.firstName,
      address: this.state.address,
      complememt: this.state.complememt,
      city: this.state.city,
      numero: this.state.numero,
      paye: this.state.paye,
      email: this.state.email,
      state: this.state.state,
    };

    this.setState({ licence: licence });
    $(".popup-license-form").removeClass("open");
    $(".scrollTop").removeClass("not-showing");
    $("body").removeClass("overflow-hidden");
  };

  _displayFavoriteImage(product) {
    var sourceImage = require("../../assets/images/heart-like.svg");
    if (
      this.props.favorites &&
      this.props.favorites.findIndex((item) => item.id === product.id) !== -1
    ) {
      sourceImage = require("../../assets/images/heart-dislike.svg");
    }
    return <img src={sourceImage} width="36" height="36" />;
  }

  componentDidMount() {
    this.initData();
  }

  initData() {
    const totalPoint =
      this.props.cart.cartItems.length != 0
        ? this.props.cart.cartItems.reduce((acc, current) => {
            return acc + current.points;
          }, 0)
        : 0;

    API.get(subUrl.zones)
      .then((res) => {
        this.setState({ pays: res.data });
      })
      .catch((error) => console.log(error));
    API.get(subUrl.customerPoint)
      .then((res) => {
        this.setState({ primes: res.data.point.prime - parseInt(totalPoint) });
      })
      .catch((error) => console.log(error));

    $(".third").on("click", function (e) {
      e.preventDefault();
      $(".popup-license-form").addClass("open");
      $(".scrollTop").addClass("not-showing");
      $("body").addClass("overflow-hidden");
    });

    $(".panier-type").on("click", function () {
      $(".cart").addClass("animate");
      setTimeout(() => {
        $(".cart").removeClass("animate");
      }, 2000);
    });

    $(".price-virgule").text(function () {
      return $(this).text().replace(".", ",");
    });
    $(".close-license").on("click", function (e) {
      $(".popup-license-form").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });

    /* $(".popup-download").on('click', function (e) {
			e.preventDefault()
			$(".popup--download").addClass('open');
			$(".scrollTop").addClass('not-showing');
			$('body').addClass('overflow-hidden');

		}); */
    $(".popup--download-close").on("click", function (e) {
      $(".popup--download").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });

    $(".popup-licence").on("click", function (e) {
      e.preventDefault();
      $(".popup--licence").addClass("open");
      $(".scrollTop").addClass("not-showing");
      $("body").addClass("overflow-hidden");
    });
    $(".popup--licence-close").on("click", function (e) {
      $(".popup--licence").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });
    $(".popup-price").on("click", function (e) {
      e.preventDefault();
      $(".popup--price").addClass("open");
      $(".scrollTop").addClass("not-showing");
      $("body").addClass("overflow-hidden");
    });
    $(".popup--price-close").on("click", function (e) {
      $(".popup--price").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });

    $(".account, .account-offine").on("click", function () {
      $(".top-header").toggleClass("dark");
    });

    if (this.props.product.options.only_welt_point) {
      this.setState({
        value: this.props.product.welt_price,
      });
    } else {
      this.setState({
        value:
          this.props.cartItem != undefined &&
          this.props.product.pointPrice != undefined &&
          this.props.product.pointPrice.value != undefined
            ? this.props.product.pointPrice.value
            : 0,
      });
    }
    this.props.cartItem != undefined &&
      this.setState({ isWrapCheched: this.props.cartItem.gift_wrap });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.product != this.props.product) this.initData();
  }
  handleWrapChecked = (event) => {
    this.setState({ isWrapCheched: event.target.checked });
  };
  handleMeChecked = () => {
    this.setState({ licence: null });
  };
  handleLicenceChecked = (event) => {
    //console.log(event.target.value)
    this.setState({ licenceChecked: event.target.value });
  };

  toggleFavorite = (current) => {
    const id = {
      id: current.id,
    };
    API.post(subUrl.postFavorit, id)
      .then((res) => {
        this.props.getFavorite();
        this.forceUpdate();
      })
      .catch((error) => console.log(error));
  };
  render() {
    const featureProduct = {
      model: this.state.model,
      color: this.state.color,
      size: this.state.size,
    };

    const product = this.props.product;
    const productInCart = this.props.cartItem;
    // image Thumbs
    let images, model, colors, size, getProduct;
    if (product !== null && product !== undefined) {
      if (
        product.gallery_image.length > 0 &&
        typeof product.gallery_image === "object"
      ) {
        images = product.gallery_image.map((image, i) => {
          return (
            <div key={i} className="img-l-full">
              <img src={image.url} key={i} alt={image.alt} />
            </div>
          );
        });
      }
    } else {
      images = <p>empty</p>;
    }
    if (!isEmpty(product.model)) {
      model = product.model.map((model, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="model"
              value={model.slug}
              id={model.slug}
              onChange={this.onChange}
            />
            <label for={model.slug}>{model.name}</label>
          </div>
        );
      });
    } else {
      model = null;
    }
    if (!isEmpty(product.colors)) {
      colors = product.colors.map((colors, i) => {
        return (
          <div key={i}>
            <input
              type="radio"
              name="color"
              value={colors.color}
              id={colors.slug}
              onChange={this.onChange}
            />
            <label
              for={colors.slug}
              style={{ background: colors.color }}
              title={colors.name}
            ></label>
          </div>
        );
      });
    } else {
      colors = null;
    }
    if (!isEmpty(product.sizes)) {
      size = product.sizes.map((size, i) => {
        return (
          <option key={i} value={size.slug}>
            {size.name}
          </option>
        );
      });
    } else {
      size = null;
    }
    if (model !== null && colors !== null && size !== null) {
      getProduct = this.props.cart.cartItems.filter(
        (cartItem) =>
          cartItem.id === product.id &&
          JSON.stringify(cartItem.featureProduct) ==
            JSON.stringify(featureProduct)
      )[0];
    } else {
      getProduct = this.props.cart.cartItems.filter(
        (cartItem) => cartItem.id === product.id
      )[0];
    }
    const handleAddBtn = classnames({
      qty: true,
      disabled:
        featureProduct.model == "" ||
        featureProduct.color == "" ||
        featureProduct.size == "",
    });
    const pointPrice = {
      value: this.state.value,
    };
    const faceOption = [];
    const faceOptions = [];
    const group = [];
    const priceFamily = [];
    if (product.typefaces != null) {
      //console.log(product.typefaces)
      priceFamily["complete"] = product.typefaces.complete_family_price;
      priceFamily["half"] = product.typefaces.half_family_price;
      priceFamily["essential"] = product.typefaces.essential_price;
    }
    if (product.typefaces != null) {
      //console.log("Product : ", product)
      product.typefaces.fonts__collections != null &&
        product.typefaces.fonts__collections != false &&
        product.typefaces.fonts__collections.map((font) => {
          group.push(font.title);
          font.style.map((style, index) => {
            faceOption.push({
              group: font.title,
              id: index,
              value:
                this.props.currentOption != null &&
                this.props.currentOption[font.title] != undefined &&
                this.props.currentOption[font.title][index].isChecked
                  ? this.props.currentOption[font.title][index].value
                  : "",
              isChecked:
                this.props.currentOption != null &&
                this.props.currentOption[font.title]
                  ? this.props.currentOption[font.title][index].isChecked
                  : true,
              price:
                this.props.currentOption != null &&
                this.props.currentOption[font.title] &&
                this.props.currentOption[font.title][index].isChecked
                  ? this.props.currentOption[font.title][index].value.price
                  : 0,
            });
            faceOptions.push({
              group: font.title,
              id: index,
              value: style,
              isChecked: true,
            });
          });
        });
    }

    const licence = this.state.licence;
    let start = 0;
    let multiplier = 0;

    let check =
      product.licences &&
      product.licences.filter((s) => s.name == this.state.licenceChecked);

    if (check != null && check.length != 0)
      if (check[0].name != "Trial version") {
        multiplier = parseInt(check[0].price);
      } else {
        multiplier = 0;
      }
    // Calcul prices , price: sum of selected fonts option
    const initialPrice = Object.values(faceOption).reduce((acc, curr) => {
      //console.log(curr)
      if (curr.isChecked) start += 1;
      if (this.props.currentFamilyPrice != 0)
        return this.props.currentFamilyPrice;
      else return acc + parseInt(curr.price, 10);
    }, 0);

    let initialWeltPrice = Object.values(faceOption).reduce((acc, curr) => {
      if (this.props.currentFamilyPrice != 0)
        return this.props.currentFamilyPrice * 100 * multiplier;
      else return acc + parseInt(curr.price, 10) * 100 * multiplier;
    }, 0);
    const ratePiont = this.state.value * 0.01;

    let currentPrice =
      productInCart == undefined
        ? Math.round(
            (initialPrice * multiplier * this.props.currencyChecked.value -
              ratePiont) *
              1 *
              100
          ) / 100
        : Math.round(
            (initialPrice * multiplier * this.props.currencyChecked.value -
              ratePiont) *
              productInCart.quantity *
              100
          ) / 100;
    // console.log(productInCart.typefaces.licences.filter(s => s.post_title == productInCart.licenceChecked ))
    //console.log("Current price",currentPrice)

    return (
      <>
        <Helmet>
          <title>
            {product != null &&
              product.name != null &&
              product.name.replace("<br>", "")}{" "}
            – WK®
          </title>
          <meta name="Shop" content="Inventory – WK®" />
        </Helmet>
        <div className="scroll-top-div">
          <div className={"col-md-12 d-flex-row " + this.props.dark}>
            <LeftSide className="col-md-6 float-left bg-gris padding-8 padding-t-100 list-typefaces typefaceOptionsList">
              <div className="select__title">
                <p className="options__title text text--xsmall">
                  Buying options
                </p>
                <p className="options__details text text--xsmall col-md-8">
                  Click to select or deselect
                </p>
              </div>

              <TypeFaceOptions
                //onClick = { this.Clicked()}
                group={group}
                elements={faceOptions}
                priceFamily={priceFamily}
                option={
                  productInCart != undefined ? productInCart.faceOption : null
                }
                total_complete={product != null && product.complete_total}
                total_half={product != null && product.half_total}
                total_essen={product != null && product.essential_total}
                display={product != null && product.typefaces.display_pack}
                option1={product != null && product.typefaces.layout_1_opt}
              />
            </LeftSide>
            <Overview.Content className="col-md-6 float-left bg-blanc d-flex-column-s-b details-info">
              <Overview.Options>
                <div className="overview__details">
                  <ul className="details__content">
                    {/*Add dynamic name (tag.name)*/}
                    <li className="details__author uppercase">
                      Designed by{" "}
                      {product.header != false &&
                      product.header[0] &&
                      product.header[0].header != null &&
                      product.header[0].header.author_information != null &&
                      product.header[0].header.author_information.author != null
                        ? product.header[0].header.author_information.author
                            .name
                        : ""}
                    </li>
                    <li
                      className="details__typename text text--xmedium"
                      dangerouslySetInnerHTML={{
                        __html:
                          product != null &&
                          product.name != null &&
                          product.name,
                      }}
                    ></li>
                  </ul>
                </div>

                <Overview.Licenses className="overview__licenses option-gift">
                  <div class="alert">
                    <p className="text text--xsmall">{this.state.message} </p>
                  </div>
                  <div class="licenses">
                    <p className="text text--xsmall licenses__title">
                      License{" "}
                    </p>
                    <div className="licenses__details col-md-9">
                      {product != null &&
                      product.typefaces != null &&
                      product.typefaces.licences != false
                        ? product.typefaces.licences.map((licence) => {
                            return (
                              <>
                                <div className="label-licence">
                                  <input
                                    type="radio"
                                    name="license"
                                    defaultChecked={
                                      (getProduct != undefined &&
                                        getProduct.licenceChecked ==
                                          licence.post_title) ||
                                      (this.state.licenceChecked ==
                                        licence.post_title &&
                                        true)
                                    }
                                    id={licence.post_title}
                                    value={licence.post_title}
                                    onClick={this.handleLicenceChecked}
                                  />
                                  <label
                                    className={licence.post_title.toLowerCase()}
                                    for={licence.post_title}
                                  >
                                    <span class="__licence">
                                      {licence.post_title}
                                    </span>
                                    {licence.post_title === "S" ? (
                                      <span className="sub-title">
                                        1-3 People/Company
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {licence.post_title === "M" ? (
                                      <span className="sub-title">
                                        4-10 People/Company
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {licence.post_title === "L" ? (
                                      <span className="sub-title">
                                        11-35 People/Company
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {licence.post_title === "XL" ? (
                                      <span className="sub-title">
                                        36-70 People/Company
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </div>
                              </>
                            );
                          })
                        : ""}
                      <div class="popup-msg">
                        <div class="icon-msg">
                          <img src="/images/icon.png" />
                        </div>
                        <div class="group-button">
                          <button className="popup-licence">
                            How does your licenses work?
                          </button>
                          <a
                            className="mail-type"
                            href="mailto:type@weltkern.com"
                          >
                            <button className="popup-download">
                              More than 70 People/Company?
                            </button>
                          </a>
                          <Link to="/support_/faq">
                            <button>
                              Another question? Check out the FAQ<span>↗</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <input type="checkbox" name="" id="option-gift" checked={this.state.isWrapCheched} onClick={this.handleWrapChecked} /> */}
                  {/* <label for="option-gift">Add Gift Wrap (+{this.props.currencyChecked.key} {product.font_gift_wrap})</label> */}
                </Overview.Licenses>

                <Overview.Gift class="overview__options">
                  <div className="col-md-12">
                    <p className="text text--xsmall options__title">Options</p>
                    <div className="options__licenses col-md-9">
                      <div class="license-third">
                        <a
                          onClick={this.handleMeChecked}
                          href="#0"
                          className={`${
                            (productInCart != undefined &&
                              productInCart.licence != null) ||
                            this.state.licence != null
                              ? "ajout-license me"
                              : "ajout-license me checked"
                          }`}
                        >
                          License for me/us
                        </a>
                        <a
                          href="#0"
                          className={`${
                            (productInCart != undefined &&
                              productInCart.licence != null) ||
                            this.state.licence != null
                              ? "ajout-license third checked"
                              : "third ajout-license"
                          }`}
                        >
                          License for a third party
                        </a>
                      </div>
                    </div>
                  </div>
                </Overview.Gift>
              </Overview.Options>

              <Overview.Price>
                <div className="col-md-12 prices">
                  <div
                    className={`price--chf monnaie-chf ${
                      product.options.only_welt_point ? "disabled" : ""
                    }`}
                  >
                    <sup className="text text--small">
                      {this.props.currencyChecked.key}
                    </sup>
                    {currentPrice < 0 ? 0 : parseFloat(currentPrice).toFixed(2)}
                  </div>
                  <div className="price--welt monnaie-welt">
                    <label className="text text--medium">+</label>
                    {product.options.only_welt_point
                      ? getProduct == undefined
                        ? initialWeltPrice
                        : initialWeltPrice * getProduct.quantity
                      : this.state.value}
                    <span>W</span>
                  </div>
                </div>
                <div className="range">
                  <InputRange
                    maxValue={
                      productInCart == undefined
                        ? initialWeltPrice >= this.state.primes
                          ? this.state.primes
                          : initialWeltPrice
                        : initialWeltPrice * productInCart.quantity >=
                          this.state.primes
                        ? this.state.primes
                        : productInCart.quantity
                    }
                    minValue={0}
                    step={5}
                    value={
                      product.options.only_welt_point
                        ? getProduct == undefined
                          ? initialWeltPrice
                          : initialWeltPrice * getProduct.quantity
                        : this.state.value
                    }
                    disabled={
                      product.options.only_welt_point
                        ? true
                        : false || this.props.auth
                        ? false
                        : true
                    }
                    onChange={(value) => this.setState({ value })}
                    onChangeComplete={(value) => {
                      // this.props.addRangeValueToReducer(value)
                      if (getProduct !== undefined) {
                        if (model == null) {
                          featureProduct.model = "";
                        }
                        if (colors == null) {
                          featureProduct.color = "";
                        }
                        if (size == null) {
                          featureProduct.size = "";
                        }
                        this.props.addRangeValueToReducer({
                          ...product,
                          featureProduct,
                          ...faceOption,
                          ...pointPrice,
                          pointPrice,
                          gift_wrap: this.state.isWrapCheched,
                          licence,
                          licenceChecked: this.state.licenceChecked,
                          newPrice: currentPrice,
                        });
                      }
                    }}
                  />
                  <span className="popup-price text text--small">?</span>
                  {!this.props.auth && (
                    <span className="text-range-of-line">
                      Login to redeem your W points !
                    </span>
                  )}
                </div>
                <Overview.PriceDetails className="col-md-12">
                  {this.props.product.multiplier != 0 ? (
                    <div className="prix-multiplier left-side">
                      <span className="white-text-welt">
                        {Math.round(
                          (currentPrice *
                            100 *
                            this.props.product.categorie_multiplier) /
                            100
                        ) +
                          "W×" +
                          this.props.product.multiplier}{" "}
                        =
                      </span>
                      +
                      {Math.round(
                        (currentPrice *
                          100 *
                          this.props.product.categorie_multiplier) /
                          100
                      ) * this.props.product.multiplier}
                      <span>W</span>
                    </div>
                  ) : (
                    <div className="prix-chf left-side">
                      +
                      {Math.round(
                        (currentPrice *
                          100 *
                          this.props.product.categorie_multiplier) /
                          100
                      )}
                      <span>W</span>
                    </div>
                  )}

                  <div className="qty-add-favori right-side">
                    <button
                      className="btn-heart like"
                      onClick={() => {
                        this.toggleFavorite(product);
                      }}
                    >
                      {this._displayFavoriteImage(product)}
                    </button>

                    <div className={model !== null ? handleAddBtn : "btn-type"}>
                      <button
                        disabled={start == 0 ? true : false}
                        className={
                          productInCart != undefined
                            ? "active increment panier-type uppercase"
                            : "increment panier-type uppercase"
                        }
                        onClick={() => {
                          //console.log(this.state.licenceChecked)
                          if (model == null) {
                            featureProduct.model = "";
                          }
                          if (colors == null) {
                            featureProduct.color = "";
                          }
                          if (size == null) {
                            featureProduct.size = "";
                          }

                          if (this.state.licenceChecked != "notSelected") {
                            //console.log(this.state.licenceChecked)
                            //console.log(start)
                            this.setState({ message: "" });
                            this.props.cart.cartItems.filter(
                              (cartItem) => cartItem.id === product.id
                            );
                            this.props.addToCart({
                              ...product,
                              featureProduct,
                              faceOption,
                              ...pointPrice,
                              pointPrice,
                              gift_wrap: this.state.isWrapCheched,
                              licence,
                              licenceChecked: this.state.licenceChecked,
                              newWeltPrice:
                                currentPrice *
                                this.props.product.categorie_multiplier *
                                100,
                              family: this.props.family,
                              newPrice: currentPrice,
                              quantity: 1,
                            });
                          } else {
                            this.setState({
                              message: "Please select a license",
                            });
                          }
                        }}
                      >
                        <p>Add to cart</p>
                        <div className="img-cart">
                          <img src="/images/panier-add.svg" />
                        </div>
                      </button>
                    </div>
                  </div>
                </Overview.PriceDetails>
              </Overview.Price>
            </Overview.Content>
          </div>
          <License.Form className="popup-license-form license__container">
            <div className="col-md-6 container-center">
              <form name onSubmit={this.onSubmitLisence}>
                <h2 className="title-big">License for a third party</h2>
                <div className="col-md-12">
                  <div className="form-items full">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="Company"
                      value={this.state.Company}
                      onChange={this.onChange}
                      placeholder="Company"
                    />
                  </div>
                  <div className="form-items">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="lastName"
                      required
                      value={this.state.lastName}
                      onChange={this.onChange}
                      placeholder="First name*"
                    />
                  </div>
                  <div className="form-items even">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="firstName"
                      required
                      value={this.state.firstName}
                      onChange={this.onChange}
                      placeholder="Last name*"
                    />
                  </div>
                  <div className="form-items full">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="address"
                      required
                      onChange={this.onChange}
                      placeholder="Address*"
                    />
                  </div>
                  <div className="form-items full">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="complememt"
                      onChange={this.onChange}
                      placeholder="Address complement"
                    />
                  </div>
                  <div className="form-items numero">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="numero"
                      value={this.state.numero}
                      onChange={this.onChange}
                      placeholder="Zip code*"
                    />
                  </div>
                  <div className="form-items rue">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="city"
                      required
                      value={this.state.city}
                      onChange={this.onChange}
                      placeholder="City*"
                    />
                  </div>
                  <div className="form-items full">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="state"
                      onChange={this.onChange}
                      value={this.state.state}
                      placeholder="State, Area code, Other"
                    />
                  </div>
                  <div className="form-items">
                    <label>Country</label>
                    <div className="select" onChange={this.onChange}>
                      <select name="paye">
                        {this.state && this.state.pays !== null
                          ? this.state &&
                            this.state.pays[0] &&
                            this.state.pays.map(
                              (pays) =>
                                pays &&
                                pays.zone &&
                                pays.zone
                                  .sort(function (a, b) {
                                    if (
                                      a.pays.toLowerCase() <
                                      b.pays.toLowerCase()
                                    )
                                      return -1;
                                    if (
                                      a.pays.toLowerCase() >
                                      b.pays.toLowerCase()
                                    )
                                      return 1;
                                    return 0;
                                  })
                                  .map((location) => {
                                    return <option>{location.pays}</option>;
                                  })
                            )
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="form-items full">
                    <input
                      type="email"
                      className="text text--medium-grey"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.onChange}
                      placeholder="Email*"
                    />
                  </div>
                  <div className="form-items full">
                    <input
                      type="text"
                      className="text text--medium-grey"
                      name="Website"
                      value={this.state.Website}
                      onChange={this.onChange}
                      placeholder="Website"
                    />
                  </div>
                  <div className="form-button">
                    <a href="#0" className="close-license">
                      Close
                    </a>
                    <input
                      type="submit"
                      value="Send"
                      name
                      id
                      defaultValue="Confirmer"
                    />
                  </div>
                </div>
              </form>
            </div>
          </License.Form>

          <Popups>
            <div className="popup--licence col-md-12">
              <div className="popup__content">
                <div className="popup__overflow col-md-8 center">
                  <div className="popup__text-content">
                    <div class="license__newbie">
                      <div className="popup__text">
                        <h6>ALL-INCLUSIVE</h6>
                        OUR LICENSES COVER ALL MEDIA.
                        <br />
                        <h6>ONE METRIC</h6>
                        OUR PRICES ARE SOLELY BASED ON THE NUMBER OF PEOPLE
                        WORKING FOR YOUR COMPANY OR YOUR CLIENT’S COMPANY
                        <br />
                        <h6>TRIAL LICENCE</h6> FREE AND CONTAINING ABSOLUTELY
                        ALL GLYPHS, MADE FOR DESIGNERS AND STUDENTS
                        <br />
                        <br />
                      </div>
                    </div>

                    <div class="license__chill">
                      <div className="popup__text">
                        At WELTKERN®, we believe in making font licensing easy
                        and straightforward. That’s why we base our prices on
                        the size of your company or your client’s company, with
                        the number of employees being the only factor considered
                        rather than the size of the design agency, design
                        department, or number of font users.
                        <br />
                        <br />
                        Our licenses include unlimited uses for all media,
                        including Print, Websites, Mobile Applications, Global
                        Corporate, Electronic Publications, Social Networks,
                        Videos, and Movies.
                        <br />
                        <br />
                        You can choose from our “S, M, L, XL“ licenses, perfect
                        for companies with 70 or fewer employees, or go for our
                        “XXL to X“ licenses, designed for larger companies with
                        more than 70 employees.
                        <br />
                        <br />
                        We at WELTKERN® understand the importance of trying out
                        a font to make the right design decisions. Since 2017,
                        we offer a free and one of a kind Trial license to test
                        our fonts, which contain absolutely all glyphs! We also
                        extend this trial license offer to students who can use
                        our fonts freely for their study projects and personal
                        projects.
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>

                    <div class="license__insider">
                      <div className="popup__text">
                        LICENSE GRANT <br />
                        We grant you a perpetual, non-exclusive,
                        non-transferable license to use our font software. Our
                        licenses include unlimited uses for all media, including
                        Print, Websites, Mobile Applications, Global Corporate,
                        Electronic Publications, Social Networks, Videos, and
                        Movies. We base our prices on the size of your company
                        or your client’s company, with the number of employees
                        being the only factor considered rather than the size of
                        the design agency, design department, or number of font
                        users. The only factor considered for licensing purposes
                        is the total number of employees within your or your
                        client’s organization.
                        <br />
                        <br />
                        LICENSE OPTIONS <br />
                        Our font software are available in four perpetual
                        licenses (S, M, L, XL) which must be purchased once and
                        are valid forever, covering up to 70 employees in your
                        organization. The licenses XXL and X can be purchased or
                        rented, covering more than 71 employees. With a
                        purchase, you own the right to use the font software.
                        With a rental, you temporarily have the right to use the
                        font software. You can purchase the S, M, L, XL licenses
                        directly online at type.weltkern.com. To obtain a
                        license XXL to X, please contact support@weltkern.com.
                        <br />
                        <br />
                        SELF-CHECKING AND LICENSE
                        <br />
                        UPDATE It’s important to keep your license up to date,
                        so please inform us of any significant changes in the
                        number of employees in your organization and update your
                        license accordingly, at a minimum of once per year. We
                        may also conduct internal checks using publicly
                        available database information.
                        <br />
                        <br />
                        BUY OR RENT
                        <br />
                        Our S, M, L, XL licenses are only available in perpetual
                        licenses which must be purchased online. We offers two
                        licensing options for our XXL to X licenses, the choice
                        between purchasing the right to use the font (Annual
                        Billing) or renting the right to use the font (Single
                        Bill).
                        <br />
                        <br />
                        REFUND POLICY
                        <br />
                        We hope that you will be completely satisfied with your
                        purchase of our software licenses. However, please note
                        that all sales are final and we do not offer refunds for
                        software licenses. If you made a mistake in your
                        weight/pack choice, we can offer a correction in the
                        license type but no refunds for the difference will be
                        given. However, if you encounter any technical issues,
                        please do not hesitate to reach out to us at
                        support@weltkern.com.
                        <br />
                        <br />
                        UPDATING YOUR LICENSE
                        <br />
                        For our “Single bill“ option, your license is unlimited
                        in time. Payment is due before delivery on a net 30
                        basis. Upgrades are possible with no notice required and
                        only the additional amount needed to cover the first
                        payment(s) will be charged (net 30). Downgrades and
                        terminations are not possible for this license option as
                        it is nonrefundable. All prices are exclusive of VAT
                        (8.1% VAT included in Switzerland) and include
                        administrative fees. For our “annual billing“ option,
                        your license will be automatically renewed each year.
                        The first payment is due before delivery and then
                        payments are due on a net 30 basis. The minimum contract
                        term is 12 months and there is no notice required for
                        upgrades, which will take effect on the desired date
                        with the additional value charged proportionally (net
                        30). To downgrade, a 3-month notice is required before
                        the next billing date. To terminate, a 3-month notice is
                        required before the next billing date. All prices are
                        exclusive of VAT (8.1% VAT included in Switzerland) and
                        include administrative fees.
                        <br />
                        <br />
                        THIRD PARTY SHARING
                        <br />
                        We offer the option «Right to share the font to a third
                        party» for licensees who need to share the font with
                        someone else. To use this option, the licensee must make
                        sure their license covers enough employees in total,
                        including both their own employees and any employees of
                        the third party. For example, if the licensee needs to
                        share the font with a third party that has 50 employees,
                        they may need to purchase a license for 100 employees in
                        total in order to activate the right to share the font.
                        <br />
                        <br />
                        BUYING THE FONT FILES ON BEHALF OF A CLIENT
                        <br />
                        You can buy the font files on behalf of your client as a
                        payment facilitator. Even though your client should
                        always be the license owner, this option can be more
                        convenient in some cases. By purchasing the font files,
                        you can ensure that your client is able to use the font
                        files in their projects and keep their business running
                        smoothly. This way, you can help your client save time
                        and effort and focus on their core business activities.
                        Keep in mind, though, that the responsibility of
                        adhering to the licensing terms and conditions still
                        lies with your client.
                        <br />
                        <br />
                        TRIAL/STUDENT LICENSE
                        <br />A trial license entitles users to test the font
                        softwares in order to create test documents, test
                        visuals, test web pages for the sole purpose of
                        evaluating, testing and presenting to clients or any
                        other interested parties. We at WELTKERN® understand the
                        importance of trying out a font to make the right design
                        decisions. That is why, since 2017, we offer a free and
                        one of a kind trial license to test our fonts. Our font
                        files contain absolutely all glyphs! It allow you to
                        pitch projects, test the final font, and save time and
                        effort. We also extend this trial license offer to
                        students who can use our fonts freely for their study
                        projects and personal projects. However, it is important
                        to note that all the conditions outlined in our
                        licensing terms must still be respected. Any further
                        external, commercial or public use is stricly prohibited
                        <br />
                        <br />
                        DISCOUNTS FOR SCHOOLS AND NON-PROFIT ORGANIZATION
                        <br />
                        Schools and Non-Profit Organization may receive a
                        discount or a special offer, on a case by case basis.
                        They can apply for a discount by contacting
                        support@weltkern.com and providing detailed information
                        about their business.
                        <br />
                        <br />
                        COPYRIGHT AND TRADEMARK
                        <br />
                        Our font software is owned by WELTKERN® and protected by
                        copyright law. The intellectual property of the design
                        contained in the font softwares is owned by WELTKERN®.
                        You have purchased a non-exclusive, non-refundable
                        license which grants you certain rights to use the font
                        softwares. It is not an agreement for sale of the font
                        software, of its design or any portion of it. Except for
                        your rights to use the font softwares granted by this
                        license, all other rights are owned and retained by
                        WELTKERN®
                        <br />
                        <br />
                        CREDIT REQUIREMENT
                        <br />
                        The font is a work of art and must be respected as such.
                        Please credit the font and its creator whenever possible
                        by including a statement such as: «© [TWK Name of Font]
                        designed by [Name of Creator], published by WELTKERN®.»
                        The trademark WELTKERN® is the property of WELTKERN Sàrl
                        but can be used for this application.
                        <br />
                        <br />
                        FONT IN USE
                        <br />
                        WELTKERN® reserves the right to showcase and share how
                        our fonts are being used, through means such as posting
                        on social media, displaying on our website, featuring in
                        books, magazines, lectures, case studies, and allowing
                        third parties such as design magazine publishers to do
                        so. This will only be done if the project in question is
                        already public and the images are freely accessible. The
                        authors of the project will always be credited whenever
                        possible. The authors of the projects can share the
                        results of the use by emailing us at
                        support@weltkern.com or using the hashtag #weltkern
                        #typeweltkern or the font-related hashtag, for example
                        #twklausanne. Exceptions to this clause can be requested
                        in writing to support@weltkern.com (the request must be
                        justified).
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 right pop--license-close__container">
                  <button className="popup--licence-close btn btn--close __btn-close">
                    <p>Close</p>
                  </button>
                </div>
              </div>
            </div>

            <div className="popup--download col-md-12 popup-licence-download">
              <div className="popup__content">
                <div className="col-md-8 center">
                  <div className="popup__text-content">
                    <div className="popup__title">
                      <h3 className="uppercase text text--xmedium">
                        You can find your licenses on ACCOUNT → INVENTORY → MY
                        LICENSES
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-12 right">
                    <button className="popup--download-close btn btn--close">
                      <p>Close</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="popup--price col-md-12">
              <div className="popup__content">
                <div className="col-md-8 center">
                  <div className="popup__text-content">
                    <div className="popup__title">
                      <h3 className="uppercase text text--xmedium">
                        HOW TO REDEEM YOUR POINTS
                      </h3>
                    </div>
                    <div className="popup__text popup-redeem-all">
                      <p className="mb-30">
                        There are many ways to spend your Status W Points. You
                        can redeem your precious collected points directly for
                        attractive products or benefits. For example, to get a
                        discount, your next favorite book as a gift or, in the
                        future, enjoy services from our selected partners.
                      </p>

                      <p className="mb-30">
                        A. You determine how many W points to spend on your
                        favorite product.
                        <br />
                        B. Enough points? Treat yourself and get the product of
                        your choice in full!
                        <br />
                        C. What’s more, in the future, you will also benefit
                        from our partners’ advantages.
                      </p>

                      <p>
                        Create an account and receive 500W deductible right away
                        or later.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12 right">
                    <button className="popup--price-close btn btn--close">
                      <p>Close</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popups>
        </div>
      </>
    );
  }
}

const LeftSide = styled.div`
  .options__title {
    float: left;
  }
  .options__details {
    float: right;
  }

  .select__title {
    margin-bottom: 20px;
  }
`;

const Overview = {
  Content: styled.div`
    font-family: TWKLausanne-600;
  `,

  Options: styled.div`
    .overview__details {
      margin-bottom: 240px;
    }
    .details__author {
      color: #d1d1d1;
      font-family: "TWKLausanne-600";
    }
  `,

  Licenses: styled.div`
    .licenses__title {
      dispay: flex;
      align-items: center;
      gap: 10px;
      float: left;
      line-height: 36px;
      display: flex;
    }
    .licenses__details {
      float: right;
    }
    .alert {
      p {
        color: red;
        font-family: "TWKLausanne-600";
      }
    }
    .popup-msg {
      display: inline-block;
    }
    .icon-msg {
      width: 37px;
      float: left;
      padding-top: 10px;
      img {
        width: 26px;
      }
    }
    .group-button {
      width: 80%;
      float: left;
      background: #efeff0;
      button {
        border-radius: 15px;
        background-color: #01ff29;
        border: none;
        font-family: "TWKLausanne-600";
        margin-bottom: 5px;
        padding: 10px 14px 10px;
        text-align: left;
        -webkit-text-decoration: none;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
        color: #00680b;
        &.popup-download {
          background-color: #a4ff9f;
        }
      }
      a {
        display: block;
        button {
          background-color: #d2e6d3;
          margin: 0;
          span {
            color: #00680b;
          }
        }
      }
      .mail-type {
        margin-bottom: 5px;
      }
    }
  `,

  Gift: styled.div`
    .options__title {
      float: left;
    }
    .license-third {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .ajout-license {
        &.checked {
          &:before {
            background: transparent;
            content: "";
            display: flex;
            justify-content: center;
            background-image: url("/images/checked.svg");
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
    .options__licenses {
      display: flex;
      flex-direction: column;
      float: right;
      span {
        width: 20px;
        height: 20px;
        border: 1px solid black;
        border-radius: 50px;

        &:checked {
          &:before {
            content: "";
            display: flex;
            align-self: center;
            height: 20px;
            width: 20px;
            top: 0;
            left: 0;
          }
        }
      }
    }
  `,

  Price: styled.div`
    .range {
      display: flex;
      padding-bottom: 25px;
    }
    .popup-price {
      font-family: "TWKLausanne-600";
      background: #ebebeb;
      display: flex !important;
      border-radius: 50px;
      height: 15px;
      width: 15px;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
      cursor: pointer;
      margin-top: 5px;
      z-index: 1;
    }
    .prices > *,
    .prices sup,
    .prices span,
    .prices label {
      color: #bbbbbb !important;
    }

    .price--chf {
      sup {
        padding-right: 3px;
        top: -0.8em;
      }
    }
    @media screen and (max-width: 480px) {
      .popup-price {
        margin-top: 5px;
      }
    }
  `,

  PriceDetails: styled.div`
    .active {
      background: black !important;
    }
    .right-side {
      float: right;
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;
      justify-content: flex-end;
    }

    .btn-heart {
      padding: 20px;
      /* background: #D8D8D8; */
      &.like {
        border: none;
      }
    }

    .panier-type {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 100%;
      gap: 10px;
      padding: 0 20px;
      border-radius: 50px;
      background: #d8d8d8;
      p {
        color: white;
      }
    }
    .img-cart {
      display: flex;
    }

    .products__number {
      border: 1px solid #ebebeb;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 50px;
      padding: 0 20px;

      .products--number {
        padding: 0 5px;
      }
      .products--incrementation,
      .products--decrementation {
        border: none;
        background: none;
        display: flex;
      }
      .qty-add-favori .qty {
        padding: 0 !important;
      }
    }
  `,
};

const License = {
  Form: styled.div`
    &.license__container {
      background: RGBA(235, 235, 235, 0.98);

      .title-big,
      .country {
        margin-bottom: 55px;
      }
    }
  `,
};

const Popups = styled("div")`
  .popup--download,
  .popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(235, 235, 235, 0.98);
    font-family: "TWKLausanne-600";

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }

    .popup__content {
      display: flex;
      justify-content: flex-start;
      height: auto;
      width: 100%;
      margin-top: 400px;
      padding-bottom: 100px;
    }
    .center {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .right {
      display: flex;
      justify-content: flex-end;
    }
    .popup__title {
      margin-bottom: 70px;
    }
    .mb-30 {
      margin-bottom: 30px;
    }
    .mb-40 {
      margin-bottom: 40px;
    }
    .underline {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 480px) {
    .popup--licence,
    .popup--download,
    .popup--price {
      .popup__title {
        margin-bottom: 30px;
      }
      &.open {
        padding: 0 20px;
      }
    }
    h3 {
      color: #000000;
    }
  }
`;

export default OverviewTypeFace;
