import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutUser } from '../../../../actions/authActions'
import $ from 'jquery';

class User extends Component {
	componentDidMount(){
		$(".btn-login, .account, .account-offine").on('click', function() {
			$("header.top-header").toggleClass("dark");
			$("header").toggleClass("dark-user");
			$(".page-login").toggleClass('open');
		  $('body.body').toggleClass("body-lock");
			$(".top-header").toggleClass("z-indexheader")
			$("header.top-header").toggleClass("dark")
			$(".logo").toggleClass("grise");
			$(".panier-content").removeClass("open");
			$("header").removeClass("panier-show");
			$(".cart").removeClass("active");
			$(".top-header-right").toggleClass("grise")
			$('.page-login .back').css('margin-top', '5px');
			$(".logo").toggleClass("logohide")
			return false;
	 });



	}

	render() {
		const { isAuthenticated, user, date } = this.props.auth
		let logout = ''
		logout = require('../../../../assets/images/btn-logout.svg')
		if (isAuthenticated) {
			const currentTime = Date.now();
			if ((date / 1000) + user.exp < currentTime / 1000) {
				this.props.logoutUser()
			}
		}

		return (
			<div className="account-user">
				{isAuthenticated ?
					<>
						<Link onClick={() => { 
										if(window.location.pathname == "/account"){
											
											if ($(".menu").hasClass("active") === true) {
												$("header").removeClass("menu-show");
												$("body.body").toggleClass("body-lock");
												$(".menu").removeClass("active");
											}
										}
									}} to={{ pathname: '/account', }} className="account-compte">
							<span>{user.user_display_name}</span>
						</Link>
					</> :
					<a onClick={this.login} className={isAuthenticated ? "account" : "account-offine"}>Account</a>
				}
			</div>
		)
	}
}
User.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth
})
export default connect(mapStateToprops, { logoutUser })(User)
