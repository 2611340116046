import $ from "jquery";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import outStock from "../../../assets/images/out-of-stock.svg";
import stockBackorderGreen from "../../../assets/images/stock-backorder-green.svg";
import stockBackorder from "../../../assets/images/stock-backorder.svg";
import stock from "../../../assets/images/stock.svg";
import WKch from "../../../assets/images/WK_ch.svg";
import WKeu from "../../../assets/images/WK_eu.svg";
import WKworld from "../../../assets/images/WK_world.svg";
import API, { subUrl } from "../../../utils/api";

class ProductPaginate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // products: this.props.products
      // isShowing: false,
      favorites: [],
    };
  }
  componentDidMount() {
    // API
    // 	.get(subUrl.getFavorit)
    // 	.then(res => {
    // 		this.setState({favorites : res.data})
    // 	}
    // 	)
    // 	.catch(error => console.log(error))

    const page = this.props.page;
    $(".click-product").on("click", function (e) {
      const url = this.href;

      e.preventDefault();

      myCustomEvent(function () {
        window.location = url;
      });
    });

    function myCustomEvent(fn) {
      setTimeout(function () {
        //console.log("Execute custom JS before firing navigation...");
        localStorage.setItem("pageNumber", page);
        localStorage.setItem("page", window.location.pathname);
        localStorage.setItem("scrollPosition", $(window).scrollTop());
        fn();
      }, 1000);
    }
    $(".layout-2").on("click", function () {
      $(".view-produits").removeClass("view-layout-4");
      $(".view-produits").addClass("view-layout-2");
      $(".layout-2").addClass("active");
      $(".layout-4").removeClass("active");

      return false;
    });

    $(".layout-4").on("click", function () {
      $(".view-produits").removeClass("view-layout-2");
      $(".view-produits").addClass("view-layout-4");
      $(".layout-2").removeClass("active");
      $(".layout-4").addClass("active");

      return false;
    });
  }
  estimation(date) {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + parseInt(date) + 1);
    return newDate;
  }

  now() {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + 1);

    return newDate;
  }
  _displayFavoriteImage(product) {
    var sourceImage = require("../../../assets/images/heart-like.svg");
    if (
      this.props.favorites &&
      this.props.favorites[0] &&
      this.props.favorites.findIndex((item) => item.id === product.id) !== -1
    ) {
      sourceImage = require("../../../assets/images/heart-dislike.svg");
    }

    return <img src={sourceImage} width="36" height="36" />;
  }
  toggleFavorite = (current) => {
    const id = {
      id: current.id,
    };
    API.post(subUrl.postFavorit, id)
      .then((res) => {
        this.props.getFavorite();
        this.forceUpdate();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const product = this.props.products;
    let id = this.props.id;

    let tags;
    if (product.options) {
      tags = product.options.rare ? (
        <div className="rare"></div>
      ) : product.options.new ? (
        <div className="new"></div>
      ) : product.options.limited ? (
        <div className="limited"></div>
      ) : product.options.weltclub_exclu ? (
        <div className="exclusive"></div>
      ) : product.options.made_by_weltanschauung ? (
        <div className="made"></div>
      ) : product.options.only_welt_point ? (
        <div className="only-welt"></div>
      ) : (
        ""
      );
    }
    return (
      <div
        className={
          "articles col-md-3 padding-l-r-4 " + product.categories[0].slug
        }
      >
        <a className="lien-produits"></a>
        <div className="label-pastille">
          {(product.quantity > 0 || product.backorder_check == "yes") &&
            product.multiplier != false && (
              <div className="multiplication">W×{product.multiplier}</div>
            )}
          {tags}
          {/* {
            !product.typeface &&
            <div className="welt">
              <img src={require('../../../assets/images/logo-pastille.svg')} alt="Welt" />
            </div>
          } */}
        </div>
        {product.choice_product != "Typeface" && (
          <div className="order-availability">
            <div
              className={`${
                product.quantity > 0 && product.backorder_check == "yes"
                  ? "availability-btn"
                  : product.quantity > 0 && product.backorder_check != "yes"
                  ? "availability-btn-3"
                  : product.quantity <= 0 && product.backorder_check == "yes"
                  ? "availability-btn-orange"
                  : "availability-red"
              } `}
            >
              {product.quantity > 0 && product.backorder_check == "yes" ? (
                <img src={stock} />
              ) : product.quantity > 0 && product.backorder_check != "yes" ? (
                <img src={stockBackorderGreen} />
              ) : product.quantity <= 0 && product.backorder_check == "yes" ? (
                <img src={stockBackorder} />
              ) : (
                <img src={outStock} />
              )}
            </div>
            <div className="availability-txt">
              {product.quantity > 0 && product.backorder_check == "yes" ? (
                <>
                  <p className="txt-gray">Availability</p>
                  <p className="title">In stock 🥰</p>
                  <p className="txt-ship">
                    Shipping from <span className="welt-txt">WELTKERN® </span>
                    Date of delivery approx.
                  </p>
                  {product.estimation_de_livraison &&
                  product.estimation_de_livraison.length > 0
                    ? product &&
                      product.estimation_de_livraison &&
                      product.estimation_de_livraison.map((pays) => (
                        <div className="delivery__national">
                          <div className="pays-icon">
                            <div class="icon">
                              {pays.pays === "Suisse" && <img src={WKch} />}
                              {pays.pays === "Other" && <img src={WKworld} />}
                              {pays.pays === "Europe" && <img src={WKeu} />}
                            </div>
                            <div className="date">
                              <Moment format="MMM DD">{this.now()}</Moment>-
                              <Moment format="MMM DD">
                                {this.estimation(pays.nombre_de_jours)}
                              </Moment>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </>
              ) : product.quantity > 0 && product.backorder_check != "yes" ? (
                <>
                  <p className="txt-gray">Availability</p>
                  <p className="title">Last available piece(s) 🤯</p>
                  <p className="txt-ship">
                    Shipping from <span className="welt-txt">WELTKERN® </span>
                    Date of delivery approx.
                  </p>
                  {product.estimation_de_livraison &&
                  product.estimation_de_livraison.length > 0
                    ? product &&
                      product.estimation_de_livraison &&
                      product.estimation_de_livraison.map((pays) => (
                        <div className="delivery__national">
                          <div className="pays-icon">
                            <div class="icon">
                              {pays.pays === "Suisse" && <img src={WKch} />}
                              {pays.pays === "Other" && <img src={WKworld} />}
                              {pays.pays === "Europe" && <img src={WKeu} />}
                            </div>
                            <div className="date">
                              <Moment format="MMM DD">{this.now()}</Moment>-
                              <Moment format="MMM DD">
                                {this.estimation(pays.nombre_de_jours)}
                              </Moment>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </>
              ) : product.quantity <= 0 && product.backorder_check == "yes" ? (
                <>
                  <p className="txt-gray">Availability</p>
                  <p className="title-orange">Item available on Backorder 👆🏻</p>
                  <p className="txt-ship">
                    Shipping from <span className="welt-txt">WELTKERN® </span>
                    Date of delivery approx.
                  </p>
                  {product.estimation_de_livraison &&
                  product.estimation_de_livraison.length > 0
                    ? product &&
                      product.estimation_de_livraison &&
                      product.estimation_de_livraison.map((pays) => (
                        <div className="delivery__national">
                          <div className="pays-icon">
                            <div class="icon">
                              {pays.pays === "Suisse" && <img src={WKch} />}
                              {pays.pays === "Other" && <img src={WKworld} />}
                              {pays.pays === "Europe" && <img src={WKeu} />}
                            </div>
                            {parseInt(pays.nombre_de_jours) > 0 ? (
                              <div className="date out-of">
                                <Moment format="MMM DD">{this.now()}</Moment>-
                                <Moment format="MMM DD">
                                  {this.estimation(pays.nombre_de_jours)}
                                </Moment>
                              </div>
                            ) : (
                              <div className="date out-of">Unavailable</div>
                            )}
                          </div>
                        </div>
                      ))
                    : ""}
                </>
              ) : (
                <>
                  <p className="txt-gray">Availability</p>
                  <p className="title-red">Out of Stock </p>
                  <p className="txt-ship">
                    <span className="welt-txt">
                      Stay tuned, we could maybe have this item later on! Follow
                      our{" "}
                      <a
                        target="_blank"
                        href="https://www.instagram.com/weltkern/"
                      >
                        Instagram
                      </a>{" "}
                      page to keep you updated.
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        )}

        <Link
          refresh="true"
          className="img-l-full click-product"
          to={{
            pathname:
              product.choice_product == "Typeface"
                ? `/shop/detail-typeface/${product.slug}`
                : `/shop/detail-product/${product.slug}`,
            product,
          }}
        >
          <img
            src={
              product.gallery_image.length == 0
                ? product.featured_image.url
                : product.gallery_image[0].url
            }
            alt=""
          />
        </Link>

        {/* img-l-full */}
        <div className="description">
          <div className="info-panier">
            <div className="info-prix">
              <div className="left-product">
                <div className="category-price">
                  <div
                    className="category"
                    dangerouslySetInnerHTML={{ __html: product.name }}
                  ></div>
                  <div
                    className="brands"
                    dangerouslySetInnerHTML={{
                      __html:
                        product.header != false &&
                        product.header[0] &&
                        product.header[0].header != null &&
                        product.header[0].header.author_information != null &&
                        product.header[0].header.author_information.author !=
                          null
                          ? product.header[0].header.author_information.author
                              .name
                          : "",
                    }}
                  ></div>
                </div>
              </div>
              <div className="right-product">
                <div className="category-price">
                  <div className="price">
                    CHF{" "}
                    {product.variant
                      ? parseFloat(product.variant[0].price)
                          .toFixed(2)
                          .replace(".", ",")
                      : product.price.toFixed(2).replace(".", ",")}{" "}
                    <br />
                    <span>or</span> {product.welt_price}W
                  </div>
                </div>
              </div>
            </div>
            <div className="info-paiment details-info">
              {(product.quantity > 0 || product.backorder_check == "yes") &&
              product.multiplier != false ? (
                <div className="prix">
                  {product.categorie_multiplier * product.points.prime}W×
                  {product.multiplier} ={" "}
                  <span>
                    {product.categorie_multiplier *
                      product.points.prime *
                      product.multiplier}
                    W
                  </span>
                </div>
              ) : (
                (product.quantity > 0 || product.backorder_check == "yes") && (
                  <div className="prix-chf">
                    +{product.categorie_multiplier * product.points.prime}W
                  </div>
                )
              )}
              {product.choice_product == "Typeface" ? (
                ""
              ) : product.quantity > 0 ||
                (product.quantity <= 0 && product.backorder_check == "yes") ? (
                <button
                  className="btn-panier"
                  onClick={() =>
                    // this.openModalHandler

                    {
                      this.props.getCurentProduct(product);
                      this.props.openModal();
                    }
                  }
                ></button>
              ) : (
                <button className="out-of-stock">
                  <p>Out of stock</p>
                </button>
              )}
              <button
                className="btn-heart like"
                onClick={() => {
                  this.toggleFavorite(product);
                }}
              >
                {this._displayFavoriteImage(product)}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductPaginate;
