import React, { Component } from 'react';
import HeaderIndex from "../_includes/header/Index";
import Account from "../_includes/menu/account";
import Detail from "../_includes/menu/detail";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert';
import { DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { getAdresses, newAdress, deleteAdress, editAdress, changeAddress } from '../../actions/adressesActions'
import Spinner from '../utils/Spinner'
import { updateUser } from '../../actions/authActions';
import Cart from "../_includes/lastProduits/Cart";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import { getCustomer } from '../../actions/userActions'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import API, { subUrl } from '../../utils/api'
import Swal from 'sweetalert2'
import Map from '../map/map';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import { changePassword } from '../../actions/authActions';
import isEmpty from '../../validation/is-empty';


const useStyles = ({
	formControl: {
		margin: 1,
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: 2,
	},
});

class Informations extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isSelected: false,
			show: false,
			pays: [],
			adresseName: '',
			firstName: '',
			civilite: '',
			prefix: '',
			user_phone: '',
			titre: '',
			civilite: '',
			prefix: '',
			user_phone: '',
			titre: '',
			user_company: '',
			user_date_day: '',
			user_date_month: '',
			user_date_years: '',
			user_email: '',
			user_firstname: '',
			user_lastname: '',
			user_url: '',
			user_photo: '',
			complement: '',
			livraison: false,
			facturation: false,
			user_id: this.props.auth.user.user_id,
			lastName: '',
			company: '',
			country: '',
			address: '',
			postcode: '',
			city: '',
			state: '',
			phone: '',
			email: '',
			user: '',
			id: '',
			type: '',
			erreur: '',
			erreur1: '',
			erreur2: '',
			checked: false,
			index: null,
			idCheck: null,
			updatedAdress: null,
			submit: 'Ajouter',
			showModal: false,
			showModalPoint: false,
			currentAdresse: null,
			currentUser: null,
			checkedAddress: null,
			oNrefresh: true,
			password: '',
			repeatpassword: '',
		}

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.onSubmitUpdatePassword = this.onSubmitUpdatePassword.bind(this);
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });

	}

	onChangeChoice = (e) => {
		if (e.target.name == "livraison") {
			this.setState({ livraison: e.target.checked });
		}
		if (e.target.name == "facturation") {
			this.setState({ facturation: e.target.checked });
		}
	}
	handleOpenModal(address, i) {
		this.setState({ currentAdresse: address, showModal: true, idCheck: i })
	}

	handleCloseModal() {
		this.setState({ showModal: false });
	}
	handleCloseModalPoint = () => {
		this.setState({ showModalPoint: false });
	}
	_validateEmail(email) {
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(String(email).toLowerCase());
	}
	isValid() {
		if (this.state.adresseName === "" || this.state.firstName === "" || this.state.lastName === "" || this.state.company === "" || this.state.country === "" ||
			this.state.address === "" || this.state.postcode === "" || this.state.city === "" || this.state.phone === "" || this.state.email === "") {
			this.setState({ erreur: 'Please enter the required fields', erreur1: '', erreur2: '' });
			return false
		}
		else if (isNaN(parseInt(this.state.postcode)) || isNaN(parseInt(this.state.phone))) {
			this.setState({ erreur1: 'Please enter a valid fields', erreur: '', erreur2: '' });
			return false
		}
		else if (!this._validateEmail(this.state.email)) {
			this.setState({ erreur2: 'Please enter a valid email address', erreur: '', erreur1: '' });
			return false
		}
		else {
			this.setState({ erreur: '', erreur1: '', erreur2: '' });
			return true
		}
	}

	addAddress = (e) => {
		e.preventDefault();
		//if (this.isValid()) {
		let newAdresse = null;
		//if (this.state.livraison === true)
		newAdresse = {
			address_name: this.state.adresseName,
			first_name: this.state.firstName,
			last_name: this.state.lastName,
			company: this.state.company,
			country: this.state.country,
			address_1: this.state.address,
			address_2: this.state.address,
			postcode: this.state.postcode,
			city: this.state.city,
			state: this.state.state,
			phone: this.state.phone,
			email: this.state.email,
			type: 'shipping'
		}
		let newAdresseFacturation = null;
		//if (this.state.facturation === true)
		newAdresseFacturation = {
			address_name: this.state.adresseName,
			first_name: this.state.firstName,
			last_name: this.state.lastName,
			company: this.state.company,
			country: this.state.country,
			address_1: this.state.address,
			address_2: this.state.address,
			postcode: this.state.postcode,
			city: this.state.city,
			state: this.state.state,
			phone: this.state.phone,
			email: this.state.email,
			type: 'billing'
		}

		// confirmAlert({
		// 	title: "L'ajout",
		// 	message: 'Voulez-vous ajouter cette adresse ?',
		// 	buttons: [
		// 		{
		// 			label: 'Oui',
		// 			onClick: () => {
		// 				//if (this.state.livraison === true)
		// 				this.props.newAdress(newAdresse)
		// 				//if (this.state.facturation === true)
		// 				this.props.newAdress(newAdresseFacturation)
		// 				this.setState({ oNrefresh: false, firstName: '', lastName: '', adresseName: '', address: '', postcode: '' })
		// 				$('.form-start').hide();
		// 			}
		// 		},
		// 		{
		// 			label: 'Non',
		// 		}
		// 	],
		// 	willUnmount: () => {
		// 		setTimeout(() => {
		// 			this.setState({ oNrefresh: true, user: this.props.users.user })
		// 			this.props.getAdresses()
		// 			this.props.getCustomer()
		// 		}, 2000);
		// 	},
		// });

		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to add this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					'Deleted!',
					'Your file has been deleted.',
					'success'
				)
					//if (this.state.livraison === true)
					this.props.newAdress(newAdresse)
					//if (this.state.facturation === true)
					this.props.newAdress(newAdresseFacturation)
					this.setState({ oNrefresh: false, firstName: '', lastName: '', adresseName: '', address: '', postcode: '' })
					$('.form-start').hide();
			}
			// willUnmount: () => {

			// }
			setTimeout(() => {
				this.setState({ oNrefresh: true, user: this.props.users.user })
				this.props.getAdresses()
				this.props.getCustomer()
			}, 2000);

		})
	}

	updateAddress = (e) => {
		e.preventDefault();
		let newAdresse = null;
		newAdresse = {
			address_name: this.state.adresseName,
			first_name: this.state.firstName,
			last_name: this.state.lastName,
			company: this.state.company,
			country: this.state.country,
			address_1: this.state.adresseName,
			address_2: this.state.complement,
			zipcode: this.state.postcode,
			city: this.state.city,
			state: this.state.state,
			phone: this.state.phone,
			email: this.state.email,
			type: 'shipping',
			id: this.state.id
		}





		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to update this!",
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Update it!'
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					'info!',
					'Your address has been updated.',
					'success'
				)
				this.props.editAdress(newAdresse)
				this.setState({ oNrefresh: false })

			}
			// willUnmount: () => {

			// }
			setTimeout(() => {
				this.setState({ oNrefresh: true, user: this.props.users.user })
				this.props.getAdresses()
				this.props.getCustomer()
			}, 2000);

		})

		// confirmAlert({
		// 	title: "L'ajout",
		// 	message: 'Voulez-vous ajouter cette adresse ?',

		// 	buttons: [
		// 		{
		// 			label: 'Oui',
		// 			onClick: () => {
		// 				this.props.editAdress(newAdresse)
		// 				this.setState({ oNrefresh: false })
		// 			}
		// 		},
		// 		{
		// 			label: 'Non',
		// 		}
		// 	],
		// 	willUnmount: () => {
		// 		setTimeout(() => {
		// 			this.setState({ oNrefresh: true, user: this.props.users.user })
		// 			this.props.getAdresses()
		// 			this.props.getCustomer()
		// 		}, 2000);
		// 	},
		// });
		// //}
	}

	onCheck = (address) => {
		this.setState({
			checked: true,
			index: address.id,
			checkedAddress: address
		})
		this.props.changeAddress(address)
	}

	getAdresse = (adress) => {
		this.setState({
			adresseName: adress.address_1,
			firstName: adress.first_name,
			lastName: adress.last_name,
			company: adress.company,
			country: adress.country,
			complement: adress.address_2,
			postcode: adress.zipcode,
			city: adress.city,
			state: adress.state,
			phone: adress.phone,
			email: adress.email,
			show: true,
			id: adress.id,
		})
	}

	changePayment = (e) => {
		this.setState({ payment: e.target.value })
	}

	onChangeForm = () => {
		if ($('.form-start').is(":hidden")) {
			$('.form-start').show()
		}
		else
			$('.form-start').hide()
		//$('.form-start').toggleClass('hide');
		this.scrollTo()

	}

	deleteAdresse = (adresse) => {

		confirmAlert({
			title: 'La suppression',
			message: 'Voulez-vous supprimer cette adresse ?',
			buttons: [
				{
					label: 'Oui',
					onClick: () => this.props.deleteAdress(adresse.id, adresse)
				},
				{
					label: 'Non',
				}
			]
		})

	}

	onSubmitUpdatePassword = (e) => {
		e.preventDefault();
		const newUser = {
			id: this.props.auth.user.user_id,
			username: this.state.user_email,
			password: this.state.password,
			repeatpassword: this.state.repeatpassword
		}
		if(isEmpty(this.state.password))
		console.log(newUser)


		this.props.changePassword(newUser)

		this.props.history.push('/account/informations');
	}

	onSubmit = (e) => {
		e.preventDefault();
		const newUser = {
			id: this.props.auth.user.user_id,
			gender: this.state.civilite,
			username: this.state.user_firstname,
			lastName: this.state.user_lastname,
			day: this.state.user_date_day,
			month: this.state.user_date_month,
			year: this.state.user_date_years,
			phone: this.state.user_phone,
			prefix: this.state.prefix,
			email: this.state.user_email,
			company: this.state.user_company,
			currentPosition: this.state.titre,
			website: this.state.user_url,
		}
		console.log(newUser)
		this.props.updateUser(newUser)
		setTimeout(() => {
			this.props.history.push('/account/informations');
			this.initData();
			}, 3000);

	}
	componentDidMount = () => {
		this.initData();
	}

	initData() {
		API
			.get(subUrl.adresses)
			.then(res => {
				this.scrollToWithContainer(res.data[0])
				this.setState({ default: res.data[0].country, country: res.data[0].country, facturationAdressesData: res.data[1] })
				this.onCheck(res.data[0])
				//this.scrollToWithContainer(res.data[1])
				console.log("resutssss", res.data)

			}
			)
			.catch(error => console.log(error))
		console.log("user", this.props.user)
		$('html,body').animate({
			scrollTop: 0
		}, 'slow');
		$('li a.Menu2').addClass("activeMenu")
		$('.form-start').show();
		this.props.getAdresses()
		this.props.getCustomer()
		API
			.get(subUrl.zones)
			.then(res => {

				this.setState({ pays: res.data })
			}
			)
			.catch(error => console.log(error))
	}

	componentDidUpdate(prev, next) {
		if (prev.auth.isAuthenticated != this.props.auth.isAuthenticated) {
			this.props.getAdresses()
			this.props.getCustomer()
		}
		if (prev.users.user != this.props.users.user) {
			this.state.user_firstname = this.props.users.user.user_firstname
			this.state.user_lastname = this.props.users.user.user_lastname
			this.state.civilite = this.props.users.user.civilite
			this.state.user_date_day = this.props.users.user.user_date_day
			this.state.user_date_month = this.props.users.user.user_date_month
			this.state.user_date_years = this.props.users.user.user_date_years
			this.state.user_phone = this.props.users.user.user_phone
			this.state.prefix = this.props.users.user.prefix
			this.state.user_email = this.props.users.user.user_email
			this.state.user_company = this.props.users.user.user_company
			this.state.titre = this.props.users.user.titre
			this.state.user_url = this.props.users.user.user_url
			this.state.photo = this.props.users.user.photo
		}
	}


	scrollTo() {
		scroller.scrollTo('form-adress-livraison', {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart'
		})
	}
	scrollToWithContainer(adresse) {
		let goToContainer = new Promise((resolve, reject) => {
			//this.scrollTo()
			this.setState({ id: adresse.id, submit: 'Modifier', erreur: '' })
			this.getAdresse(adresse)
			$('.form-start').show();
		});

		goToContainer.then(() => {
			scroller.scrollTo('scroll-container-second-element', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart',
				containerId: 'form-adress'
			})
		}
		);
	}

	render() {
		const { isAuthenticated, user } = this.props.auth;
		const { adresses, users } = this.props;
		const addresss = "";

		let displayedAdresses, currentAdresse, formUser, formAddress;
		if (adresses.adresses === null || adresses.loading || !this.state.oNrefresh && !isAuthenticated) {
			displayedAdresses = <Spinner />;
		} else {
			formAddress = (
				<div className="col-md-12 form1 form-adresse ">
					<form onSubmit={this.state.submit == "Modifier" ? this.updateAddress : this.addAddress} id="form-adress" >

						<div className="col-md-12 ">
							<div className="form-text">
								<label>First name</label>
								<input type="text" name="firstName" value={this.state.firstName} onChange={this.onChange} />
							</div>
							<div className="form-text right">
								<label>Last name</label>
								<input type="text" name="lastName" value={this.state.lastName} onChange={this.onChange} />
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-text full">
								<label>Adress</label>
								<input type="text" value={this.state.address} name="address" onChange={this.onChange} />
							</div>

						</div>
						<div className="col-md-12">
							<div className="form-text full">
								<label>Adress complement</label>
								<input type="text" value={this.state.address2} name="address2" onChange={this.onChange} />
							</div>
						</div>
						<div className="col-md-12 ">

							<div className="form-text num">
								<label>Zip code</label>
								<input type="text" name="postcode" value={this.state.zipcode} onChange={this.onChange} />
							</div>
							<div className="form-text rue">
								<label>City</label>
								<input type="text" name="city" value={this.state.city} onChange={this.onChange} />
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-text full">
								<label>State, Area code, Other</label>
								<input type="text" name="state" value={this.state.state} onChange={this.onChange} />
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-text">
								<label>Pays</label>
								<select name="country" defaultValue={{ label: "Maroc", value: "Maroc" }} onChange={this.onChange}>
									<option value={this.state.default} dangerouslySetInnerHTML={{__html: this.state.default}}></option>
									{this.state && this.state.pays != null ? this.state && this.state.pays[0] && this.state.pays.map((pays) =>
										pays.zone.sort(function(a, b) {  if(a.pays.toLowerCase() < b.pays.toLowerCase()) return -1; if(a.pays.toLowerCase() > b.pays.toLowerCase()) return 1;
											return 0;
											}).map((location) => {
											if (this.state.default != location.pays)
												return (
													<option value={location.pays} dangerouslySetInnerHTML={{__html:location.pays}}></option>
												)
										})
									) : ''}
								</select>
							</div>
						</div>
					</form>
				</div>
			)
			displayedAdresses = adresses && adresses.adresses.map((address, i) => {
				if (address.type === "shipping") {
					return (
						<>
						<Helmet>
								<title>Informations – WK®</title>
								<meta name="Support-FAQ" content="Informations – WK®" />
							</Helmet>
							<div className="item" key={i}>
								<input type="radio" name="modele" id={i} checked={this.state.index == i} />
								<label for={i}>
									<span className="name infs">{address.first_name}, {address.last_name} </span>
									<span>{address.address_name} {address.state}</span>
									<span>{address.city}</span>
									<span>{address.country}</span>
									{/* layer */}
								</label>
								{/* <div className="adresse-layer">
								<div className="action">
									<a onClick={() => {
										this.handleOpenModal(address, address.id)
										// this.getAdresse(address)
									}
									}
									>View</a>
								<a className="default-adresse" onClick={() => this.onCheck(address)}>Use as default delivery address</a>
									<a id="update-adresse" onClick={() => {
										this.scrollToWithContainer(address)
										// this.getAdresse(address)
									}
									}

									>Update</a>
									<a onClick={() => {
										this.deleteAdresse(address)
									}}
									>Delete</a>
								</div>
							</div> */}
								{/* {this.state.index == address.id && this.state.checked ?
						  <img className="check-icon" src={require('../../assets/images/check-markwhit-white.png')} /> :
						  <img className="check-icon" src={require('../../assets/images/check-mark.png')} />
						} */}
							</div>
							{/* <div className="form-wrapper radio">
							<div className="half form-radio">
								<input type="radio" name="livraison" id={address.id} defaultChecked={this.state.livraison} onChange={this.onChangeChoice} />
								<label for={address.id}>Utiliser en tant qu'adresse de livraison par défault</label>
							</div>
						</div> */}
						</>
					)
				}
			})

			if (this.state.currentAdresse == null) {
				currentAdresse = <Spinner />;
			} else {
				currentAdresse = <div>
					<table className="table-detail">
						<tbody>
							<tr>
								<th>First name</th>
								<td>{this.state.currentAdresse.first_name}</td>
							</tr>
							<tr>
								<th>Last Name</th>
								<td>{this.state.currentAdresse.last_name}</td>
							</tr>
							<tr>
								<th>Address Name</th>
								<td>{this.state.currentAdresse.address_name}</td>
							</tr>
							<tr>
								<th>Address 1</th>
								<td>{this.state.currentAdresse.address_1}</td>
							</tr>
							<tr>
								<th>City</th>
								<td>{this.state.currentAdresse.city}</td>
							</tr>
							<tr>
								<th>State</th>
								<td>{this.state.currentAdresse.state}</td>
							</tr>
							<tr>
								<th>Phone</th>
								<td>{this.state.currentAdresse.phone}</td>
							</tr>
							<tr>
								<th>Country</th>
								<td>{this.state.currentAdresse.country}</td>
							</tr>
							<tr>
								<th>Postcode</th>
								<td>{this.state.currentAdresse.postcode}</td>
							</tr>

						</tbody>
					</table>
					{
						this.state.currentAdresse.id == this.state.idCheck && this.state.currentAdresse.id == this.state.index ?
							<img className="checked-icon" onClick={() => this.onCheck(this.state.currentAdresse)} /> :
							<img className="checked-icon" onClick={() => this.onCheck(this.state.currentAdresse)} />
					}
				</div>
			}

		}
		const insideHeader = (
			<div className="my-account col-md-12 relative padding-t-50 clearfix">
				{isAuthenticated ?
					<>
						<Detail />
						<Account />
					</>
					: this.props.history.push('/')
				}
			</div>
		);

		if (users.user === null) {
			formUser = <Spinner />;
		}
		else {
			formUser = (
				<div className="col-md-10 container-center overflow-h personnal-data">
					<div className="col-md-5 float-left">
						<p className="">Personal data</p>
					</div>
					<div className="col-md-6 float-left">
						<div className="form-mes-infos">
							<form variant="outlined" id="form-adress" onSubmit={this.onSubmit}>
								<div className="form-wrapper">
									<div className="form-file">
										<div className="img">
											{this.props && this.props.auth && this.props.auth.user.photo ?
												<img id="profilImg" src={`${process.env.REACT_APP_BaseURL}/${this.props.auth.user.photo}`} />
												:
												<div class="wrapper1">
													<input type="file" class="my_file" onChange={this.fileSelectedHandlerChange} />
												</div>
											}
										</div>
									</div>
								</div>

								<div className="form-wrapper">
									<div className="half">
										<TextField name="user_firstname" label="First name *" className="form-text bgwhite" value={this.state.user_firstname} onChange={this.onChange} />
									</div>

									<div className="half">
										<TextField label="Last name" name="user_lastname" className="form-text bgwhite" value={this.state.user_lastname} onChange={this.onChange} />
									</div>
								</div>

								<div className="form-wrapper">

									<div className="full">
										<TextField label="Phone Number" name="user_phone" className="form-text bgwhite" value={this.state.user_phone} onChange={this.onChange} />
									</div>
								</div>
								<div className="form-wrapper">
									<div className="full">
										<TextField disabled label="E-mail adress" type="email" name="user_email" className="form-text emailInput bgwhite" value={this.state.user_email} onChange={this.onChange} />
									</div>
								</div>
								<div className="form-wrapper">
									<div className="full">
										<button type="submit" className="btnForm1">Save</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			)
		}

		return (

			<>
				<div className="headerInformartion">
					<HeaderIndex insideHeader={insideHeader} />
				</div>

				<div className="col-md-12 informationSection">
					<div className="col-md-12 list-account" id="overview">
						<div className="top-list col-md-10 container-center">
							{formUser}
							{/* Ajouter adresse */}
							<form className="personnal-data" onSubmit={this.updateAddress}>
								<div className="col-md-10 container-center overflow-h formRight">
									<div className="col-md-5 float-left">
										<p className="labelForm">Delivery address</p>
									</div>
									<div className="col-md-7 float-left">
										<div className="form-mes-infos infs">

											<div className="form-wrapper infs">
												<div className="half">
													{/* <label>Liste d'adresse</label>
													{displayedAdresses} */}
												</div>
											</div>
											<div className="form-wrapper form-start" id="form-adress-livraison">
												<div className="half">
													<TextField label="First Name" name="firstName" className="form-text" value={this.state.firstName} onChange={this.onChange} />
												</div>
												<div className="half">
													<TextField label="Last name" name="lastName" className="form-text" value={this.state.lastName} onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper form-start">
												<div className="full">
													{/* 	<TextField label="Complément" name="adresseName" id className="form-text" value={this.state.addressName} onChange={this.onChange} /> */}
													<TextField label="Address" name="adresseName" id className="form-text" value={this.state.adresseName} onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper form-start">
												<div className="full">
													{/* 	<TextField label="Complément" name="adresseName" id className="form-text" value={this.state.addressName} onChange={this.onChange} /> */}
													<TextField label="Address complement" name="complement" id className="form-text" value={this.state.complement} onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper form-start">
												<div className="quart">
													{/* 	<TextField label="Complément" name="adresseName" id className="form-text" value={this.state.addressName} onChange={this.onChange} /> */}
													<TextField label="Zip code" name="postcode" id className="form-text" value={this.state.postcode} onChange={this.onChange} />
												</div>
												<div className="third">
													{/* 	<TextField label="Complément" name="adresseName" id className="form-text" value={this.state.addressName} onChange={this.onChange} /> */}
													<TextField label="City" name="city" id className="form-text" value={this.state.city} onChange={this.onChange} />
												</div>
											</div>
											<div className="form-wrapper form-start">
												<div className="full">
													<TextField label="State, Area code, Other" name="state" value={this.state.state} onChange={this.onChange} className="form-text" />
												</div>
											</div>
											<div className="form-wrapper form-start">
												<div className="form-select half">
													<InputLabel id="">Country</InputLabel>
													<select name="country" defaultValue={{ label: "Maroc", value: "Maroc" }} onChange={this.onChange}>
														<option value={this.state.country} dangerouslySetInnerHTML={{__html: this.state.country}}></option>
														
														{this.state && this.state.pays !== null ? this.state && this.state.pays[0] && this.state.pays.map((pays) =>
															<optgroup className="group-list" label={pays.zone_name}>
															{pays.zone.sort(function(a, b) {  if(a.pays.toLowerCase() < b.pays.toLowerCase()) return -1; if(a.pays.toLowerCase() > b.pays.toLowerCase()) return 1;
															return 0;
															}).map((location) => {
																if (this.state.country !== location.pays)
																	return (
																		<option value={location.pays} dangerouslySetInnerHTML={{__html:location.pays}}></option>
																	)
															})}
															</optgroup>
														) : ''}
													</select>


												</div>
												<div className="half">
													{/* <label>City</label>
												<input type="text" name="city" value={this.state.city} onChange={this.onChange} id className="form-text" placeholder="Ville" /> */}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-10 container-center overflow-h formRight form-start">
									{/* <div className="col-md-5 float-left">
										<p className="labelForm">Adresse de Facturation</p>
									</div> */}
									<div className="col-md-7 float-right">
										<div className="form-mes-infos">
											{/* <div className="form-wrapper">
												<div className="half form-radio">
													<input type="checkbox" name="facturation" id="facturation" defaultChecked={this.state.facturation} onChange={this.onChangeChoice} />
													<label for="facturation">Utiliser en tant qu'adresse de facturation par défault</label>
												</div>
											</div> */}
											<div className="form-wrapper">
												<div className="full">
													<button type="submit" name="" id="" className="btnForm1">Save</button>
												</div>
												{/* <div className="full">
													<button name="" id="" className="btnForm dell">Supprimmer l'adresse</button>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</form>
							<div className="col-md-10 container-center overflow-h formRight personnal-data">
								<div className="col-md-5 float-left">
									<p className="labelForm">Update</p>
								</div>
								<div className="col-md-7 float-left">
									<div className="form-mes-infos">
										<form onSubmit={this.onSubmitUpdatePassword} id="form-adress">
											{/* <div className="form-wrapper">
												<div className="third">
													<TextField label="Adresse email" type="email" name="user_email" id className="form-text" onChange={this.onChange} />
												</div>
												<div className="quart">
												</div>
											</div> */}
											<div className="form-wrapper">
												<div className="third">
													<TextField label="Password" type="password" name="password" className="form-text bgwhite" onChange={this.onChange} />
												</div>
												<div className="quart">
												</div>
											</div>
											<div className="form-wrapper">
												<div className="third">
													<TextField label="Retype password" type="password" name="repeatpassword" id className="form-text bgwhite" onChange={this.onChange} />
												</div>
												<div className="quart">
												</div>
											</div>

											<div className="form-wrapper">
												<div className="full">
													<button type="submit" name="" id="" className="btnForm1 btn2">Update password</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Cart />
				<FooterIndex />
			</>)
	}
}

Informations.propTypes = {
	auth: PropTypes.object.isRequired,
	updateUser: PropTypes.func.isRequired,
	changePassword: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth,
	adresses: state.adresses,
	users: state.users,
})

const mapDispatchToProps = (dispatch) => {
	return {
		getAdresses: () => dispatch(getAdresses()),
		getCustomer: () => dispatch(getCustomer()),
		updateUser: (userData) => dispatch(updateUser(userData)),
		changeAddress: (address) => dispatch(changeAddress(address)),
		changePassword: (newUser) => dispatch(changePassword(newUser)),
		newAdress: (adressData) => newAdress(adressData),
		editAdress: (adressData) => editAdress(adressData),
		deleteAdress: (index, adress) => dispatch(deleteAdress(index, adress))
	}
}

export default connect(mapStateToprops, mapDispatchToProps)(Informations)
