import { GET_MEDIAS, MEDIA_LOADING } from "./types";
import API, { subUrl } from "../utils/api";
// get pages Media
export const getMedias = () => (dispatch) => {
  dispatch(setMediasLoading());
  API.get(subUrl.socialmedias)
    .then((res) => {
      dispatch({
        type: GET_MEDIAS,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
// medias loading
export const setMediasLoading = () => {
  return {
    type: MEDIA_LOADING,
  };
};
