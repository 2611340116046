import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { resetIdCounter, Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getCategories } from '../../../actions/categorieAction';
import { getFavorite } from '../../../actions/favoriteAction';
import isEmpty from '../../../validation/is-empty';
import Spinner from '../../utils/Spinner';
import Modal from './modal/Modal';
import OverviewProduct from './OverviewProduct';
import Supply from './Supply';

class ProductsListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			books: [],
			furnitures: [],
			isShowing: false,
			value: 10,
			loadModal: false,
			giftChecked: false,
			favorites: [],
			productPerPage: 9,
			currentIndex: 0
		};
	}

	componentDidMount() {
		const { currencies, currentLange } = this.props;
		const { currencyChecked } = currencies;
		const { language } = currentLange;
		// this.props.getProducts(language, currencyChecked.key);
		this.props.getCategories(); 
		//this.props.getFavorite();
		//this.setState({favorites : this.props.getFavorite()})
	}


	openModalHandler = () => {
		document.body.classList.add("no-sroll")
		this.setState({
			isShowing: true, loadModal: true
		});

		setTimeout(() => {
			this.setState({ loadModal: false })
		}, 1000);
	}

	closeModalHandler = () => {
		document.body.classList.remove("no-sroll")

		this.setState({
			isShowing: false
		});
	}

	_displayFavoriteImage(product) {
		var sourceImage = require('../../../assets/images/heart-like.svg')
		if (this.props.favorites.favorites.findIndex(item => item.id === product.id) !== -1) {
			sourceImage = require('../../../assets/images/heart-dislike.svg')
		}
		return (
			<img
				src={sourceImage}
				width="36"
				height="36"
			/>
		)
	}

	render() {
		let firstproductsLength = 0, secondProductsLength = 0, thirdProductsLength = 0, fourthProductsLength = 0, lastProductsLength = 0
		const settings = {
			dots: false,
			slidesToShow: 3,
			infinite: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						infinite: true,
						dots: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						dots: false,
						slidesToShow: 2,
						infinite: true,
					}
				},
				{
					breakpoint: 480,
					settings: {
						dots: false,
						slidesToShow: 1,
						infinite: true,
					}
				}
			]
		};

		const settingsType = {
			dots: false,
			slidesToShow: 2,
			infinite: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						infinite: true,
						dots: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						dots: false,
						slidesToShow: 2,
						infinite: true,
					}
				},
				{
					breakpoint: 480,
					settings: {
						dots: false,
						slidesToShow: 1,
						infinite: true,
					}
				}
			]
		};

		const settingsInfini = {
			dots: false,
			slidesToShow: 3,
			infinite: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						infinite: false,
						dots: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						dots: false,
						slidesToShow: 2,
						infinite: false,
					}
				},
				{
					breakpoint: 480,
					settings: {
						dots: false,
						slidesToShow: 1,
						infinite: false,
					}
				}
			]
		};

		const settingsTypeInfini = {
			dots: false,
			slidesToShow: 2,
			infinite: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						infinite: false,
						dots: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						dots: false,
						slidesToShow: 2,
						infinite: false,
					}
				},
				{
					breakpoint: 480,
					settings: {
						dots: false,
						slidesToShow: 1,
						infinite: false,
					}
				}
			]
		};

		const { products, loading, categories, currentProduct } = this.props;
		let firstCat, secondCat, thirdCat, fourthCat, lastCat;

		let displayedCategories;

		if (categories.categories === null || categories.loadingCategories) {
			displayedCategories = <Spinner />;
		} else {
			const categoriesFounded = categories && categories.categories && categories.categories[0] && categories.categories.filter(categorie => categorie.id == true);
			displayedCategories = categories && categories.categories && categories.categories.map((categorie, i) => {
				return (
					<Tab>{categorie.name}, </Tab>
				)
			})

			var maxCount = categories && categories.categories && categories.categories[0] && categories.categories.sort(function (a, b) {
				return b.count - a.count;
			});
			maxCount = maxCount.map((item) => {
				if (item.count >= 1) {
					return item
				}
			})

			maxCount = maxCount.slice(0, 5);
			firstCat = maxCount[0]
			secondCat = maxCount[1]
			thirdCat = maxCount[2]
			fourthCat = maxCount[3]
			lastCat = maxCount[4]

		}

		let firstproductsItems = [], secondProductsItems = [], thirdProductsItems = [], fourthProductsItems = [], lastProductsItems = [];

		
		if (products.products === null || categories.loadingCategories) {
			// firstproductsItems  = <Spinner/>;
			// secondProductsItems = <Spinner/>;
			// thirdProductsItems  = <Spinner/>;
			// fourthProductsItems = <Spinner/>;
			// lastProductsItems = <Spinner/>;
		} else {
			//let favorits = this.props.this.props.getFavorite
			if (products.products.length > 0) {
				products && products.products && products.products.map((product, i) => {
					if (product.categories !== null && product.categories !== undefined) {
						for (let i = 0; i < product.categories.length; i++) {
							if (firstCat != null) {
								switch(product.categories[i].name) {

									case "Books":
										firstproductsLength++;
										firstproductsItems.push(
											<Supply
											key              = {i}
											products         = {product}
											addToCart        = {this.props.addToCart}
											removeFromCart   = {this.props.removeFromCart}
											getFavorite      = {this.props.getFavorite}
											favorites        = {this.props.favorites.favorites}
											getCurentProduct = {this.props.getCurentProduct}
											openModal        = {this.openModalHandler}
											cartItem         = {this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
											currencyChecked  = {this.props.currencies.currencyChecked}
										/>
										)
									break;

									case "Typefaces":
										secondProductsLength++;
										secondProductsItems.push(
											<Supply
											key              = {i}
											products         = {product}
											addToCart        = {this.props.addToCart}
											removeFromCart   = {this.props.removeFromCart}
											getFavorite      = {this.props.getFavorite}
											favorites        = {this.props.favorites.favorites}
											getCurentProduct = {this.props.getCurentProduct}
											openModal        = {this.openModalHandler}
											cartItem         = {this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
											currencyChecked  = {this.props.currencies.currencyChecked}
										/>
										)
									break;

									case "Supply":
										thirdProductsLength++;
										thirdProductsItems.push(
											<Supply
											key              = {i}
											products         = {product}
											addToCart        = {this.props.addToCart}
											removeFromCart   = {this.props.removeFromCart}
											getFavorite      = {this.props.getFavorite}
											favorites        = {this.props.favorites.favorites}
											getCurentProduct = {this.props.getCurentProduct}
											openModal        = {this.openModalHandler}
											cartItem         = {this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
											currencyChecked  = {this.props.currencies.currencyChecked}
										/>
										)
									break;
									case "Stationery":
										fourthProductsLength++;
										fourthProductsItems.push(
											<Supply
											key              = {i}
											products         = {product}
											addToCart        = {this.props.addToCart}
											removeFromCart   = {this.props.removeFromCart}
											getFavorite      = {this.props.getFavorite}
											favorites        = {this.props.favorites.favorites}
											getCurentProduct = {this.props.getCurentProduct}
											openModal        = {this.openModalHandler}
											cartItem         = {this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
											currencyChecked  = {this.props.currencies.currencyChecked}
										/>
										)
									break;
									case "Rare":
										lastProductsLength++;
										lastProductsItems.push(
											<Supply
											key              = {i}
											products         = {product}
											addToCart        = {this.props.addToCart}
											removeFromCart   = {this.props.removeFromCart}
											getFavorite      = {this.props.getFavorite}
											favorites        = {this.props.favorites.favorites}
											getCurentProduct = {this.props.getCurentProduct}
											openModal        = {this.openModalHandler}
											cartItem         = {this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
											currencyChecked  = {this.props.currencies.currencyChecked}
										/>
										)
									break;
								}
							}
						}
					}
				})

			}

		}
		resetIdCounter();
		return (<>

			{/*!-- Derniers produits ajoutés -->*/}
			<div className="last-addition-shop slide-slick-items mon-last" id="last-addition-shop">
				<Tabs>
					<TabList>
						<div className="menu-addition home">
							<p>Last addition to the shop</p>
							<ul>
								<Tab onClick={e => {
									this.props.getProductsOnPage(this.state.productPerPage, 1, "all", 'books')
								}}>Books</Tab>
								<Tab onClick={e => {
									this.props.getProductsOnPage(this.state.productPerPage, 1, "all", 'typefaces')
								}}>Typefaces</Tab>
								<Tab onClick={e => {
									this.props.getProductsOnPage(this.state.productPerPage, 1, "all", 'supply')
								}}>Supply</Tab>
								<Tab onClick={e => {
									this.props.getProductsOnPage(this.state.productPerPage, 1, "all", 'stationery')
								}}>Stationery</Tab>
								<Tab onClick={e => {
									this.props.getProductsOnPage(this.state.productPerPage, 1, "all", 'rare')
								}}>Rare</Tab>
							</ul>
						</div>
					</TabList>
					<TabPanel default={true}>
					{firstproductsLength > 4 ?
						<div className="items-last-add">

							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'books'); 
								}
							}} {...settings}>
								{firstproductsItems}
							</Slider>
						</div>
						:
						<div className="items-last-add" id="books">


								{firstproductsItems}

						</div>
					}
					</TabPanel>
					<TabPanel>
					{secondProductsLength >= 3 ?
						<div className="items-last-add items-typefaces">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'typefaces'); 
								}
							}} {...settingsType}>
								{secondProductsItems}
							</Slider>
						</div>
						:
						<div className="items-last-add items-typefaces" id="">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'typefaces'); 
								}
							}} {...settingsTypeInfini}>
								{secondProductsItems}
							</Slider>
						</div>
					}
					</TabPanel>
					<TabPanel>
						{thirdProductsLength > 4 ?
						<div className="items-last-add items-supply">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'supply'); 
								}
							}} {...settings}>
								{thirdProductsItems}
							</Slider>
						</div>
						:
						<div className="items-last-add items-supply" id="">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'supply'); 
								}
							}} {...settingsInfini}>
								{thirdProductsItems}
							</Slider>
						</div>
						}
					</TabPanel>
					<TabPanel>
						{fourthProductsLength > 4 ?
						<div className="items-last-add items-stationery" id="">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'rare'); 
								}
							}} {...settings}>
								{fourthProductsItems}
							</Slider>
						</div>
						:
						<div className="items-last-add items-stationery" id="">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'rare'); 
								}
							}} {...settingsInfini}>
								{fourthProductsItems}
								</Slider>
						</div>
						}
					</TabPanel>
					<TabPanel>
						{lastProductsLength > 4 ?
						<div className="items-last-add items-rare" id="">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'rare'); 
								}
							}} {...settings}>
								{lastProductsItems}
							</Slider>
						</div>
						:
						<div className="items-last-add items-rare" id="">
							<Slider beforeChange={(oldIndex, newIndex) => {
								if (oldIndex < newIndex && this.props.products.products.length < this.props.products.total - 1) {
									this.props.getOneMoreProduct(this.props.products.products, 1, this.state.productPerPage + newIndex, "all", 'rare'); 
								}
							}} {...settingsInfini}>
								{lastProductsItems}
								</Slider>
						</div>
						}
					</TabPanel>
				</Tabs>
			</div>
			{/*!-- End Derniers produits ajoutés -->*/}
			<Modal
				className="modal"
				show={this.state.isShowing}
				close={this.closeModalHandler}>

				<div className="modal-body clearfix quick-purchase">
					<span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
					{/* characteristics Poduct */}
					{(!isEmpty(currentProduct.currentProduct) && !this.state.loadModal) ?
						<OverviewProduct
							show={this.state.isShowing}
							currentProduct={currentProduct.currentProduct}
							addToCart={this.props.addToCart}
							addRangeValueToReducer={this.props.addRangeValueToReducer}
							cart={this.props.cart}
							toggleFavorite={this.props.toggleFavorite}
							getFavorite      = {this.props.getFavorite}
							favorites={this.props.favorites.favorites}
							removeFromCart={this.props.removeFromCart}
							cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.currentProduct.id)[0]}
							currencyChecked={this.props.currencies.currencyChecked}
						/>
						: <Spinner />
					}
				</div>
			</Modal>
			{/*!-- Btn shop now -->*/}
			<div className="btn-shop-now">
				<a href="/shop">Shop now</a>
			</div>
			{/*!-- End Btn shop now -->*/}

		</>)
	}
}
const mapStateToProps = (state) => {
	return ({
		cart: state.cart,
		products: state.products,
		favorites: state.favorites,
		categories: state.categorie,
		currentProduct: state.currentProduct,
		currencies: state.currencies,
		currentLange: state.translation
	})
}
const mapDispatchToProps = (dispatch) => {
	return {
		addToCart: (item) => {
			dispatch({ type: 'ADD', payload: item })
		},
		addRangeValueToReducer: (item) => {
			dispatch({ type: 'GET_RANGE_VALUE', payload: item })
		},
		removeFromCart: (item) => {
			dispatch({ type: 'REMOVE', payload: item })
		},
		getCurentProduct: (item) => {
			dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
		},
		// getProducts: (language, currencyChecked) => dispatch(getProducts(language, currencyChecked)),
		getCategories: () => dispatch(getCategories()),
		getFavorite: () => dispatch(getFavorite()),


	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsListing)
