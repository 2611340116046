import $ from 'jquery';
import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from "react-router-dom";
import outStock from '../../../assets/images/out-of-stock.svg';
import stockBackorderGreen from '../../../assets/images/stock-backorder-green.svg';
import stockBackorder from '../../../assets/images/stock-backorder.svg';
import stock from '../../../assets/images/stock.svg';
import WKch from '../../../assets/images/WK_ch.svg';
import WKeu from '../../../assets/images/WK_eu.svg';
import WKworld from '../../../assets/images/WK_world.svg';
import API, { subUrl } from '../../../utils/api';

class Supply extends Component {
	constructor(props) {
		super(props)
		this.state = {
		favorites : [],
		}

	}

	componentDidMount() {
		$(".price-virgule").text(function () {
			return $(this).text().replace(".", ",");
		});
		//this.props.getFavorite()
	}
	estimation(date) {
		const newDate = new Date();
		const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
		newDate.setDate(newDate.getDate() + parseInt(date) + 1);
		return newDate;
	}

	now() {
		const newDate = new Date();
		const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
		newDate.setDate(newDate.getDate() + 1);

		return newDate
	}

	_displayFavoriteImage(product) {
		var sourceImage = require('../../../assets/images/heart-like.svg')
		 if ( this.props.favorites && this.props.favorites.findIndex(item => item.id === product.id) !== -1) {
			sourceImage = require('../../../assets/images/heart-dislike.svg')
		}
		return (
			<img
				src={sourceImage}
				width="36"
				height="36"
			/>
		)
	}


	toggleFavorite = (current) => {

		const id = {
			id : current.id
		}
		API
        .post(subUrl.postFavorit, id)
        .then(res =>
            {
				this.props.getFavorite();
				this.forceUpdate()
        }
        )


	}

	render() {
		const product = this.props.products
		const currency = this.props.currencyChecked
		let linkAcrtion = product.choice_product == "Typeface" ? '/shop/detail-typeface/' : '/shop/detail-product/'
		return (

			<div className={"items float-left padding-l-r-4 " +product.categories[0].slug}>
				<div className="label-pastille">
					{
						product.quantity >0 && product.multiplier != false &&
						<div className="multiplication">W×{product.multiplier}</div>

					}
					{/* <div className="welt"><img src="../../images/logo-pastille.svg" alt="Welt" /></div> */}
				</div>
				{product.choice_product != "Typeface" &&
        <div className="order-availability">
          <div className={`${product.quantity>0 && product.backorder_check == "yes" ? 'availability-btn' : product.quantity>0 && product.backorder_check != "yes" ? 'availability-btn-3' : product.quantity <= 0 && product.backorder_check == "yes" ? 'availability-btn-orange' : 'availability-red'} `}>
          {product.quantity>0 && product.backorder_check == "yes" ? <img src={stock} /> : product.quantity>0 && product.backorder_check != "yes" ? <img src={stockBackorderGreen} /> : product.quantity <= 0 && product.backorder_check == "yes" ? <img src={stockBackorder} /> : <img src={outStock} />}
          </div>
          <div className="availability-txt">
            {product.quantity>0 && product.backorder_check == "yes" ? 
            <>
            <p className="txt-gray">Availability</p>
            <p className="title">In stock 🥰</p>
            <p className="txt-ship">Shipping from <span className="welt-txt">WELTKERN® </span>Date of delivery approx.
            </p>
            { product.estimation_de_livraison && product.estimation_de_livraison.length > 0 ?
            product && product.estimation_de_livraison && product.estimation_de_livraison.map((pays) =>
              <div className="delivery__national">
                <div className="pays-icon">
                  <div class="icon">
                    {pays.pays === "Suisse" && <img src={WKch} />}
                    {pays.pays === "Other" && <img src={WKworld} />}
                    {pays.pays === "Europe" && <img src={WKeu} />}
                  </div>
                  <div className="date">
                    <Moment format="MMM DD">{this.now()}</Moment>
                      -
                    <Moment format="MMM DD">{this.estimation(pays.nombre_de_jours)}</Moment>
                  </div>
                </div>
              </div>
              )
              :
              ''
            }</> : product.quantity>0 && product.backorder_check != "yes" ? 
            <>
            <p className="txt-gray">Availability</p>
            <p className="title">Last available piece(s) 🤯</p>
            <p className="txt-ship">Shipping from <span className="welt-txt">WELTKERN® </span>Date of delivery approx.
            </p>
            { product.estimation_de_livraison && product.estimation_de_livraison.length > 0 ?
            product && product.estimation_de_livraison && product.estimation_de_livraison.map((pays) =>
              <div className="delivery__national">
                <div className="pays-icon">
                  <div class="icon">
                    {pays.pays === "Suisse" && <img src={WKch} />}
                    {pays.pays === "Other" && <img src={WKworld} />}
                    {pays.pays === "Europe" && <img src={WKeu} />}
                  </div>
                  <div className="date">
                    <Moment format="MMM DD">{this.now()}</Moment>
                      -
                    <Moment format="MMM DD">{this.estimation(pays.nombre_de_jours)}</Moment>
                  </div>
                </div>
              </div>
              )
              :
              ''
            }</> : product.quantity <= 0 && product.backorder_check == "yes" ? 
            <>
            <p className="txt-gray">Availability</p>
            <p className="title-orange">Item available on Backorder 👆🏻</p>
            <p className="txt-ship">Shipping from <span className="welt-txt">WELTKERN® </span>Date of delivery approx.
            </p>
            { product.estimation_de_livraison && product.estimation_de_livraison.length > 0 ?
            product && product.estimation_de_livraison && product.estimation_de_livraison.map((pays) =>
              <div className="delivery__national">
                <div className="pays-icon">
                  <div class="icon">
                    {pays.pays === "Suisse" && <img src={WKch} />}
                    {pays.pays === "Other" && <img src={WKworld} />}
                    {pays.pays === "Europe" && <img src={WKeu} />}
                  </div>
                  { parseInt(pays.nombre_de_jours) > 0 ? 
                  <div className="date out-of">
                    <Moment format="MMM DD">{this.now()}</Moment>
                      -
                    <Moment format="MMM DD">{this.estimation(pays.nombre_de_jours)}</Moment>
                  </div>
                  : 
                  <div className="date out-of">
                    Unavailable
                  </div>
                  }
                </div>
              </div>
              )
              :
              ''
            }</> : 
            <>
            <p className="txt-gray">Availability</p>
            <p className="title-red">Out of Stock </p>
            <p className="txt-ship">
              <span className="welt-txt">
                Stay tuned, we could maybe have this item later on!
                Follow our <a target="_blank" href="https://www.instagram.com/weltkern/">Instagram</a> page
                to keep you updated.
              </span>
            </p>
            </>
            }
            
          </div>
        </div>
        }
				<figure className="img-h-full d-flex-column-c">
					<Link to={{
						// pathname: `/shop/detail-product/${product.id}`,
						pathname: `${linkAcrtion}${product.slug}`,
						product
					}}

					>
						<img src={product.gallery_image.length == 0 ? product.featured_image.url : product.gallery_image[0].url} alt="" />
					</Link>
				</figure>
				<div className="description">
					<div className="info-panier">
						<div className="info-prix">
							<div className="left-product">
                <div className="category-price">
                  <div className="category" dangerouslySetInnerHTML={{__html : product.name}}></div>
                  <div className="brands" dangerouslySetInnerHTML={{ __html : product.header != false && product.header[0] && product.header[0].header != null && product.header[0].header.author_information != null && product.header[0].header.author_information.author != null  ? product.header[0].header.author_information.author.name :''}}></div>
                </div>
              </div>
              <div className="right-product">
                <div className="category-price">
                  <div className="price">CHF <p className="price-virgule">{product.variant? parseFloat(product.variant[0].price).toFixed(2) : product.price.toFixed(2)}</p> <br/><span>ou</span> {product.welt_price}W</div>
                </div>
              </div>
						</div>
						<div className="info-paiment details-info">
							{
								(product.quantity > 0 || product.quantity <= 0 && product.backorder_check == "yes") && product.multiplier != false ?
								<div className="prix">{product.categorie_multiplier * product.points.prime}W×{product.multiplier} = <span>{(product.categorie_multiplier * product.points.prime) * product.multiplier}W</span></div>
								:
								(product.quantity > 0 || product.quantity <= 0 && product.backorder_check == "yes") && <div className="prix-chf">+{product.categorie_multiplier * product.points.prime}W</div>
							}
							{product.choice_product == "Typeface" ? '' :
								product.quantity > 0 || product.quantity <= 0 && product.backorder_check == "yes" ?
								<button className="btn-panier"
									onClick={() =>                                        // this.openModalHandler

									{

										this.props.getCurentProduct(product);
										this.props.openModal()
									}
									}
								>
								</button>
								:
                                <button className="out-of-stock"><p>Out of stock</p></button>
							}

							<button className="btn-heart like"
								onClick={() => {
									this.toggleFavorite(product)
								}

								}
							>
								{this._displayFavoriteImage(product)}
							</button>
						</div>
					</div>
				</div>
			</div>

		)
	}
}



export default Supply
