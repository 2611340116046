import {MEDIA_LOADING, GET_MEDIAS} from '../actions/types'

const initialState = { 
    medias: null,
    loading: false
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case MEDIA_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_MEDIAS:
            return {
                ...state,
                medias: action.payload,
                loading: false
            };
        default: 
           return state
    }

}