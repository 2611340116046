import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import WKch from "../../assets/images/WK_ch.svg";
import WKeu from "../../assets/images/WK_eu.svg";
import WKworld from "../../assets/images/WK_world.svg";

const CartProductItemMobile = (props) => {
  const {
    item,
    currencyChecked,
    unitPrice,
    unitPriceWelt,
    onBack,
    sui,
    eur,
    word,
  } = props;

  const estimation = (date) => {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + parseInt(date) + 1);
    return newDate;
  };

  const now = () => {
    const newDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    newDate.setDate(newDate.getDate() + 1);

    return newDate;
  };

  return (
    <>
      <Products.Articles className="articles product">
        <div class="cart-product row pic-container">
          <div className="images product__img col-md-4">
            <img
              src={
                item.gallery_image.length == 0
                  ? item.featured_image.url
                  : item.gallery_image[0].url
              }
              alt="Welt"
            />
          </div>
          <div className="cart-product product__name col-md-4">
            <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
            <p className="product__pack text text--xsmall license-select">
              {item.family != "not-selected"
                ? item.family
                : item.faceOption.map(
                    (font) => font.isChecked && <span>{font.value.name}</span>
                  )}
            </p>
            <div className="rightside col-md-6">
              <div className="product__infos informations col-md-6">
                <div className="availability-txt">
                  {item?.quantite > 0 && item?.backorder_check == "yes" ? (
                    <>
                      <p className="txt-gray">Availability</p>
                      <p className="title">In stock 🥰</p>
                      <p className="txt-ship">
                        Shipping from{" "}
                        <span className="welt-txt">WELTKERN® </span>Date of
                        delivery approx.
                      </p>
                    </>
                  ) : item?.quantite > 0 && item?.backorder_check != "yes" ? (
                    <>
                      <p className="txt-gray">Availability</p>
                      <p className="title">Last available piece(s) 🤯</p>
                      <p className="txt-ship">
                        Shipping from{" "}
                        <span className="welt-txt">WELTKERN® </span>Date of
                        delivery approx.
                      </p>
                    </>
                  ) : (
                    item?.quantite <= 0 &&
                    item?.backorder_check == "yes" && (
                      <>
                        <p className="txt-gray">Availability</p>
                        <p className="title-orange">
                          Item available on Backorder 👆🏻
                        </p>
                        <p className="txt-ship">
                          Shipping from{" "}
                          <span className="welt-txt">WELTKERN® </span>Date of
                          delivery approx.
                        </p>
                      </>
                    )
                  )}
                </div>
                {item.choice_product != "Typeface" &&
                item.estimation_de_livraison &&
                item.estimation_de_livraison.length > 0 ? (
                  item.estimation_de_livraison.map((pays) => {
                    return (
                      <div className="col-md-12 delivery">
                        {pays.pays === "Suisse" ? (
                          <div className="delivery__national">
                            <div className="pays-icon">
                              <div className="icon">
                                <img src={WKch} />
                              </div>
                              {parseInt(pays.nombre_de_jours) > 0 ? (
                                <div
                                  className={`date ${
                                    onBack == true && "out-of"
                                  }`}
                                >
                                  <Moment format="MMM DD">{now()}</Moment>-
                                  <Moment format="MMM DD">
                                    {estimation(
                                      onBack ? sui : pays.nombre_de_jours
                                    )}
                                  </Moment>
                                </div>
                              ) : (
                                <div className="date out-of">Unavailable</div>
                              )}
                            </div>
                          </div>
                        ) : pays.pays === "Europe" ? (
                          <div className="delivery__international">
                            <div className="pays-icon">
                              <div className="icon">
                                <img src={WKeu} />
                              </div>
                              {parseInt(pays.nombre_de_jours) > 0 ? (
                                <div
                                  className={`date ${
                                    onBack == true && "out-of"
                                  }`}
                                >
                                  <Moment format="MMM DD">{now()}</Moment>-
                                  <Moment format="MMM DD">
                                    {estimation(
                                      onBack ? eur : pays.nombre_de_jours
                                    )}
                                  </Moment>
                                </div>
                              ) : (
                                <div className="date out-of">Unavailable</div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="delivery__international">
                            <div className="pays-icon">
                              <div className="icon">
                                <img src={WKworld} />
                              </div>
                              {parseInt(pays.nombre_de_jours) > 0 ? (
                                <div className={`date ${onBack && "out-of"}`}>
                                  <Moment format="MMM DD">{now()}</Moment>-
                                  <Moment format="MMM DD">
                                    {estimation(
                                      onBack ? word : pays.nombre_de_jours
                                    )}
                                  </Moment>
                                </div>
                              ) : (
                                <div className="date out-of">Unavailable</div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  item.choice_product == "Typeface" &&
                  <div className="informations__typeface">
                    <p className="text text--xsmall license__choice">
                      {item.licenceChecked}
                    </p>
                    <ul className="text text--xsmall">
                      {item.licence != null ? (
                        <>
                          <li>Licence owner</li>
                          <li>
                            {item.licence.firstName} {item.licence.lastName}
                          </li>
                          <li className="text--grey">{item.licence.email}</li>
                          <li className="text--grey">{item.licence.address}</li>
                          <li className="text--grey">
                            {item.licence.complememt}
                          </li>
                          <li className="text--grey">
                            {item.licence.city} {item.licence.numero}
                          </li>
                          <li className="text--grey">{item.licence.state}</li>
                          <li className="text--grey">{item.licence.paye}</li>
                          <li className="text--grey">{item.licence.Company}</li>
                          <li className="text--grey">{item.licence.Website}</li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Products.ArticleRight className="col-md-10">
          <div className="leftside col-md-6">
            <div className="product__details nom-prix-statut col-md-12 bottom--left">
              {/* {item.featureProduct.color != '' ||
														item.featureProduct.size != '' ||
														item.featureProduct.modele != '' ||
														item.featureProduct.edition != '' ?
													<div className="feature-info">
														{item.featureProduct.color != '' &&
															<p className="features" style={{ backgroundColor: `${item.featureProduct.color}` }}></p>
														}
														{item.featureProduct.size != '' &&
															<p className="features-size">{item.featureProduct.size}</p>
														}
														{item.featureProduct.modele != '' &&
															<p className="features-model">{item.featureProduct.model}</p>
														}
														{item.featureProduct.edition != '' &&
															<p className="features-edition">{item.featureProduct.edition}</p>
														}
													</div> : <p>test</p>} */}
              <div className="prix-statut">
                <div className="reward col-md-3">
                  <p className="text text--xsmall">Reward</p>
                </div>
                <div className="statut col-md-6">
                  <div className="col-md-12">
                    <span>Prime</span>
                    {item.multiplier != false ? (
                      <span className="money">
                        <span>
                          {Math.floor(item.newWeltPrice / 100) * item.quantity}
                          W×{item.multiplier}{" "}
                        </span>
                        <span className="total">
                          +
                          {Math.floor(item.newWeltPrice / 100) *
                            item.multiplier *
                            item.quantity}
                          W
                        </span>
                      </span>
                    ) : (
                      <span className="money">
                        +{Math.floor(item.newWeltPrice / 100) * item.quantity}W
                      </span>
                    )}
                  </div>
                  <div className="col-md-12 colorStatut">
                    <span>Status</span>
                    <span className="money">
                      +
                      {Math.floor(
                        item.newWeltPrice / 100 / item.categorie_multiplier
                      ) * item.quantity}
                      W
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Products.ArticleRight>
        <div className="cart-product product__price col-md-12 bottom--right">
          <div className="product__qty-price-balance col-md-6">
            {
              <div className="product__qty qty">
                {item.choice_product != "Typeface" ? (
                  <div className="add-qty">
                    <div className="products__number">
                      <button
                        className="decrement"
                        onClick={() => {
                          props.removeFromCart(item);
                        }}
                      >
                        <img src="/images/decrementation.svg" />
                      </button>
                      <p className="text text--xsmall">{item.quantity}</p>

                      <button
                        className="increment"
                        onClick={() => {
                          if (item.quantity < item.quantite)
                            props.addToCart(item);
                        }}
                      >
                        <img src="/images/incrementation.svg" />
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
          </div>
          <div className="col-half">
            <div className="cart-product price prix col-md-6">
              {item.choice_product === "Typeface" &&
              item.licenceChecked == "Trial version" ? (
                <p className="text text--xsmall license__choice">FREE</p>
              ) : (
                <>
                  <sup className="text text--small">{currencyChecked.key}</sup>
                  <p className="price-virgule">
                    {Math.round(
                      (unitPrice * currencyChecked.value -
                        (item.choice_product !== "Typeface" &&
                          item.pointPrice.value * 0.01)) *
                        100
                    ) /
                      100 <
                    0
                      ? 0
                      : (
                          Math.round(
                            (unitPrice * currencyChecked.value -
                              (item.choice_product !== "Typeface" &&
                                item.pointPrice.value * 0.01)) *
                              100
                          ) / 100
                        ).toFixed(2)}
                  </p>
                </>
              )}
            </div>
            <div className="price--welt prix-total col-md-6">
              {item && item.options && item.options.only_welt_point ? (
                unitPriceWelt * item.quantity
              ) : item.pointPrice.value > 0 ? (
                <span className="price-welt-active">
                  -{item.pointPrice.value}W
                </span>
              ) : (
                <span>-{item.pointPrice.value}W</span>
              )}
            </div>
          </div>
        </div>
      </Products.Articles>
    </>
  );
};

const Panier = {
  Content: styled.div`
    font-family: "TWKLausanne-600";
    overflow: scroll;
  `,
  Header: styled.div`
    .rightside {
      float: right;
      padding: 0 8px;
    }
    .products {
      float: left;
    }
    .informations {
      float: left;
    }
    .qty {
      float: right;
    }
  `,

  Products: styled.div`
    .delete {
      z-index: 2;
    }
    .content {
      margin: 4px 0;
    }
    .empty-basket {
      padding: 105px 8px;
      background: white;
      color: #bbbbbb;
      margin-bottom: 250px;
    }
  `,

  Extras: styled.div`
    .lists {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .list {
        background: white;
        display: flex;
        padding: 12px 8px;
        .flex {
          display: flex;
        }
      }
    }
  `,
  Paiement: styled.div`
    padding: 8px;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    .paiement {
      width: 100%;
    }
    .bloc-paiement {
      .bloc-paiement__title {
        width: 100%;
      }
      &.flex {
        display: flex;
      }
      .content {
        display: flex;
        flex-direction: column;
      }
      .surprise-locked {
        .first,
        .last {
          color: #bbbbbb !important;
        }
      }
    }
  `,
};

const Products = {
  Articles: styled.div`
    min-height: 230px;
    background: white;
    .product__infos {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .availability-txt {
        background-color: #ffffff;
        margin-left: 0px;
        padding: 0px;
        min-width: 181px;
        width: inherit;
        height: inherit;
        font-size: 12px;
        display: block;
      }
      .delivery {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
      .delivery__national,
      .delivery__international {
        display: flex;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 0.9em;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .date {
        background: #ebebeb;
        padding: 4px 14px;
        width: max-content;
        border-radius: 50px;
      }
      .date time {
        color: #000000;
      }
    }
    .product__img {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .product__img,
    .product__name {
      float: left;
    }
  `,

  ArticleLeft: styled.div``,
  ArticleRight: styled.div`
    padding: 8px;

    .product__name,
    .product__infos,
    .product__qty {
      float: left;
    }
    .product__price {
      float: right;
      .license__choice {
        padding: 13px 15px;
        background: black;
        color: white !important;
        border-radius: 50px;
        width: max-content;
        min-width: 50px;
        display: flex;
        justify-content: center;
      }
    }
    .informations__typeface {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      float: left;
      .license__choice {
        padding: 13px 15px;
        background: black;
        color: white !important;
        border-radius: 50px;
        width: max-content;
        min-width: 50px;
        display: flex;
        justify-content: center;
      }

      ul {
        .text--grey {
          color: #d2d2d2 !important;
        }
        li:first-child {
          margin-bottom: 20px;
        }
      }
    }

    .leftside {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      float: left;
      .prix-statut {
        display: flex;
        .statut {
          float: right;
          .flex {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .money__content {
              display: flex;
              align-items: baseline;
            }
          }
        }
      }
    }

    .rightside {
      display: flex;
      height: 100%;
      float: right;

      .product__qty-price-balance {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product__qty {
          .products__number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 10px;
          }
          .increment,
          .decrement {
            position: relative;
            border: 0;
            background-color: transparent;
            display: flex;
            align-content: center;
            justify-content: center;
            width: 20px;
          }
        }

        .product__price {
          display: flex;
          align-items: flex-end;
          .price {
            display: flex;
            float: left;
          }
          .price--welt {
            float: right;
            text-align: right;
            span {
              color: #d2d2d2 !important;
            }
          }
        }
      }
    }
  `,
};

const Total = {
  Price: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
    .btn-paiement-panier {
      float: right;
      padding-top: 70px;
      display: flex;
      gap: 5px;
      .checkout {
        background: black;
        color: white;
      }
    }
    .item-paiement {
      &.item-grey {
        div,
        span {
          color: #bbbbbb;
        }
      }
      .flex {
        display: flex;
        flex-direction: column;
      }
      .item-label {
        float: left;
      }
      .item-prix {
        display: flex;
        .item__devise {
          float: left;
        }
        .item__price {
          float: right;
          text-align: right;
          word-break: break-all;
        }
      }
      .item-prix {
        .padding {
          padding-top: 5px;
        }
      }
    }

    .total {
      .padding {
        padding-top: 5px;
      }
      .item-label {
         float: left;
      }
      .item-prix {
        float: left;
        word-break: break-all;
      }
      .points {
        float: right;
        word-break: break-all;
        text-align: right;
      }
    }
  `,
  Bonus: styled.div`
    display: flex;
    float: right;
  `,
};

export default CartProductItemMobile;
