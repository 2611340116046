import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { logoutUser, parrainageUser } from '../../actions/authActions';
import { getOrders } from '../../actions/checkoutsActions';
import { changeCurrency, getCurrencyData } from '../../actions/currencyAction';
import { getMedias } from '../../actions/socialMediaAction';
import { getCustomer } from '../../actions/userActions';
import Checked from '../../assets/images/checked.svg';
import Unchecked from '../../assets/images/unchecked.svg';
import API, { subUrl } from '../../utils/api';
import Spinner from '../utils/Spinner';
import ListInventaire from '../_includes/account/ListInventaire';
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import HeaderIndex from "../_includes/header/Index";
import Cart from "../_includes/lastProduits/Cart";
import User from "../_includes/login/Login";
import Account from "../_includes/menu/account";
import Detail from "../_includes/menu/detail";

class Index extends Component {
	constructor() {
		super()
		this.state = {
			status: [],
			level: 0,
			overviewClicked: true,
			getPointsClicked: false,
			avantageClicked: false,
			historiqueClicked: false,
			parrainageClicked: false,
			status_point: 0,
			prime: 0
		}
	}

	componentDidMount() {
		$('html,body').animate({
			scrollTop: 0
		}, 'slow');
		this.props.getCurrencyData()
		this.props.getMedias()
		this.props.getCustomer()
		$("body").removeClass("body-lock")

		$(".account, .account-offine").on("click", function () {
			$(".top-header").addClass("dark")
		})
		$(".headerAccount .top-header").addClass("dark")
		API
			.get(subUrl.status)
			.then(res => {

				console.log(res.data)
				this.setState({ status: res.data })
			}
			)
			.catch(error => console.log(error))
		API
			.get(subUrl.customerPoint)
			.then(res => {
				//this.setState({ status: res.data })
				this.setState({ level: res.data.level, status_point: res.data.point.status, prime: res.data.point.prime })
			}
			)
			.catch(error => console.log(error))
		/* this.state.status.map((item)=>{
			if(this.state.status_point > item.points )
			this.setState(({level : this.state.level + 1}))
			console.log("user",this.state.level)
		}) */
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
        jQuery(function () {
        $(".menu-my-account").idTabs({});
      });
    `;
		document.body.appendChild(s);



	}
	changeCurrency = (e) => {
		const currencies = this.props.currencies.currency
		const currentCurrency = currencies.filter(item => item.key === e.target.value)[0]
		this.props.changeCurrency(currentCurrency)
		setTimeout(() => {
			// window.location.reload()
		}, 500);
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	overviewClicked = () => {
		this.setState({
			overviewClicked: true,
			getPointsClicked: false,
			avantageClicked: false,
			historiqueClicked: false,
			parrainageClicked: false
		});
	}

	getPointsClicked = () => {
		this.setState({
			overviewClicked: false,
			getPointsClicked: true,
			avantageClicked: false,
			historiqueClicked: false,
			parrainageClicked: false
		});
	}

	avantageClicked = () => {
		this.setState({
			overviewClicked: false,
			getPointsClicked: false,
			avantageClicked: true,
			historiqueClicked: false,
			parrainageClicked: false
		});
	}

	historiqueClicked = () => {
		this.setState({
			overviewClicked: false,
			getPointsClicked: false,
			avantageClicked: false,
			historiqueClicked: true,
			parrainageClicked: false
		});
	}

	parrainageClicked = () => {
		this.setState({
			overviewClicked: false,
			getPointsClicked: false,
			avantageClicked: false,
			historiqueClicked: false,
			parrainageClicked: true
		});
	}

	onSubmit = (e) => {
		e.preventDefault();
		const newUser = {
			username: this.state.email,
			invite: `${process.env.REACT_APP_InviteMail}`,
		}
		this.props.parrainageUser(newUser)
		this.props.history.push('/');
	}

	render() {
		const simplePost = [];
		let productOrder = [];

		const { isAuthenticated } = this.props.auth
		const { currencies, medias, users, checkouts, pages } = this.props;
		const { orders } = checkouts;


		if (orders === undefined || orders === null || checkouts.loading) {
			productOrder = <Spinner />
		}
		else {
			productOrder = orders

		}
		let displayCurrencies, displaySocialMedia, displaySocialIcon, point, overview, userdetail;
		const insideHeader = (
			<div className="my-account col-md-12 relative padding-t-50 clearfix">
				{isAuthenticated ?
					<>
						<Detail />
						<Account />
					</>
					: this.props.history.push('/')
				}
			</div>
		);
		if (currencies.currency == null || currencies.loading) {
			displayCurrencies = <Spinner />
		}
		else {
			displayCurrencies = currencies && currencies.currency > 0 && currencies.currency.map((currency, i) => {
				return (
					<span key={i}>
						<input type="radio" name="currency" id={i} value={currency.key} onChange={this.changeCurrency} checked={this.props.currencyChecked.key === currency.key} />
						{this.props.currencyChecked.key === currency.key ?
							<label for={i} style={{ background: '#EBEBEB', color: 'black' }} >{currency.key}</label> :
							<label for={i} style={{ background: 'black', color: '#EBEBEB', border: '1px solid #EBEBEB' }} >{currency.key}</label>
						}
					</span>
				)
			})
		}
		if (medias.medias == null || medias.loading) {
			displaySocialMedia = <Spinner />
			displaySocialIcon = <Spinner />
		}
		else {
			displaySocialMedia = medias && medias.medias[0] && medias.medias.map((media, i) => {
				if (media.slug == 'instagram') {
					return (<span key={i}>
						<a href={media.url} >{media.slug}</a><br />
					</span>)
				}
			})
			displaySocialIcon = medias && medias.medias[0] && medias.medias.map((media, i) => {
				if (media.slug != 'instagram') {
					return (
						<a key={i} className={media.slug} href={media.url}>{media.slug}</a>
					)
				}
			})
		}
		if (users.user == null) {
			point = <Spinner />
			userdetail = <Spinner />
			overview = <Spinner />
		}
		else {


			point = (
				<div className="col-md-8 float-left prime-status">
					<div className="col-md-12">
						<p className="no-padd">Prime</p>
						<p className="no-padd">{this.state.prime && this.state.prime} W</p>
					</div>
					<div className="col-md-12 status">
						<p className="no-padd">Status</p>
						<p className="no-padd">{this.state.status_point && this.state.status_point} W</p>
					</div>
				</div>
			)
			userdetail = (users.user.user_firstname + " " + users.user.user_lastname)

			overview = (

				<div className="top-list col-md-10 container-center container-overview">
					<div className="col-md-12">
						<div className="col-md-4 float-left">
							<p className="text text--xsmall">Your W points</p>
						</div>
						{point}
					</div>
					<div className="col-md-12 padding-t-50">
						<div className="col-md-4 float-left">
							<p className="text text--xsmall">Your status</p>
						</div>
						<div className="col-md-8 float-left prime-status">
							<div className="status-count">
								{this.state.status && this.state.status[0] && this.state.status_point && this.state.level < 4 ?

									<div className="status-level" style={{ width: (this.state.status_point * 100) / this.state.status[this.state.level].points + '%' }}>
										<span>{(this.state.status_point * 100) / this.state.status[this.state.level].points}%</span>
									</div>
									:
									<div className="status-level" style={{ width: '100%' }}>
										<span>100%</span>
									</div>
								}


							</div>
							<div className="col-md-12 status padding-t-50">

								{this.state.status && this.state.status[0] &&

									<>
										<div className={'col-md-12 all-status silver-status ' + (this.state.level == 1 && 'active-status')}>
											<p className="no-padd">{this.state.status[0].title}</p>
											<p className="no-padd">
												<span style={{ backgroundColor: this.state.status[0].color }}>{this.state.status[0].title}</span>
												<span>from {this.state.status[0].points}W</span>
											</p>
										</div>
										<div className={'col-md-12 all-status silver-status ' + (this.state.level == 2 && 'active-status')}>
											<p className="no-padd">{this.state.status[1].title}</p>
											<p className="no-padd">
												<span style={{ backgroundColor: this.state.status[1].color }}>{this.state.status[1].title}</span>
												<span>from {this.state.status[1].points}W</span>
											</p>
										</div>
										<div className={'col-md-12 all-status silver-status ' + (this.state.level == 3 && 'active-status')}>
											<p className="no-padd">{this.state.status[2].title}</p>
											<p className="no-padd">
												<span style={{ backgroundColor: this.state.status[2].color }}>{this.state.status[2].title}</span>
												<span>from {this.state.status[2].points}W</span>
											</p>
										</div>
										<div className={'col-md-12 all-status silver-status ' + (this.state.level == 4 && 'active-status')}>
											<p className="no-padd">{this.state.status[3].title}</p>
											<p className="no-padd">
												<span style={{ backgroundColor: this.state.status[3].color }}>{this.state.status[3].title}</span>
												<span>from {this.state.status[3].points}W</span>
											</p>
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<>
				<Helmet>
					<title>Account – WK®</title>
					<meta name="Account" content="Page Account de welt." />
				</Helmet>
				<div className="headerAccount">
					<HeaderIndex insideHeader={insideHeader} />
				</div>

				{isAuthenticated ?
					<div className="col-md-12">
						<div className="col-md-10 container-center tab-list">
							<div className="menu-account">
								<ul className="d-flex-row menu-my-account">
									<li><a href="#overview" onClick={this.overviewClicked} className="no-padd">Overview</a></li>
									<li><a href="#obtenir_des_points" onClick={this.getPointsClicked} className="no-padd">Status advantages</a></li>
									{/* <li><a href="#avantages_status" onClick={this.avantageClicked} className="no-padd">Avantages status</a></li>
									<li><a href="#historique_des_transactions" onClick={this.historiqueClicked} className="no-padd">Historique des transactions</a></li>
									<li><a href="#parrainage" onClick={this.parrainageClicked} >Parrainage</a></li>  */}
								</ul>
							</div>
						</div>
						{/* Overview */}
						<div className="col-md-12 list-account selected" id="overview" style={{ display: this.state.overviewClicked ? 'block' : 'none' }}>
							<div className="flexTop top-list col-md-10 container-center">
								<div className="top-list col-md-10">Overview</div>
							</div>
							<div className="col-md-10 container-center grids haut">
								{overview}
							</div>
							<div className="col-md-12 container-center grids">
								<ListInventaire />
							</div>

						</div>

						<div className="col-md-12 list-account" id="obtenir_des_points" style={{ display: this.state.getPointsClicked ? 'block' : 'none' }}>
							<div className="top-list col-md-10 container-center">Overview of your Status benefits</div>
							<div className="weltclub">
								<div className="privileges-overview">

									<div className="col-md-10 container-center grids">
										<div className="grid">
											<p className="col-1 text text--medium-grey text--xsmall uppercase">Weltkern®</p>
											<p className="col-2 text text--xmedium uppercase">Regular</p>
											<p className="col-3 text text--xmedium uppercase">Medium</p>
											<p className="col-4 text text--xmedium uppercase">Bold</p>
											<p className="col-5 text text--xmedium uppercase">Heavy</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">
												Collect W points <br />
											(Prime and Status)
										</p>
											<img className="col-2" src={Checked} alt="" />
											<img className="col-3" src={Checked} alt="" />
											<img className="col-4" src={Checked} alt="" />
											<img className="col-5" src={Checked} alt="" />
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall"> Additional W points</p>
											<img className="col-2" src={Unchecked} alt="" />
											<p className="col-3 text text--xsmall">
												+10% W Prime <br />
											+10% W Status
										</p>
											<p className="col-4 text text--xsmall">
												+20% W Prime <br />
											+10% W Status
										</p>
											<p className="col-5 text text--xsmall">
												+30% W Prime <br />
											+10% W Status
										</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Direct chat</p>
											<img className="col-2" src={Checked} alt="" />
											<img className="col-3" src={Checked} alt="" />
											<img className="col-4" src={Checked} alt="" />
											<img className="col-5" src={Checked} alt="" />
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Birthday gift</p>
											<p className="col-2 text text--xsmall">+250W</p>
											<p className="col-3 text text--xsmall">+500W</p>
											<p className="col-4 text text--xsmall">+1'000W</p>
											<p className="col-5 text text--xsmall">+2'500W</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Free delivery in Switzerland</p>
											<p className="col-2 text text--xsmall">from CHF 120</p>
											<img className="col-3" src={Checked} alt="" />
											<img className="col-4" src={Checked} alt="" />
											<img className="col-5" src={Checked} alt="" />
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Free delivery in Europe</p>
											<p className="col-2 text text--xsmall">from CHF 300</p>
											<p className="col-3 text text--xsmall">from CHF 240</p>
											<p className="col-4 text text--xsmall">from CHF 120</p>
											<img className="col-5" src={Checked} alt="" />
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Free delivery in the rest of the world</p>
											<p className="col-2 text text--xsmall">from CHF 600</p>
											<p className="col-3 text text--xsmall">from CHF 450</p>
											<p className="col-4 text text--xsmall">from CHF 250</p>
											<img className="col-5" src={Checked} alt="" />
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Free return</p>
											<img className="col-2" src={Unchecked} alt="" />
											<p className="col-3 text text--xsmall">14 days</p>
											<p className="col-4 text text--xsmall">30 days</p>
											<p className="col-5 text text--xsmall">56 days</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--medium-grey text--xsmall uppercase">Type.Weltkern®</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Font discount</p>
											<img className="col-2" src={Checked} alt="" />
											<p className="col-3 text text--xsmall">-2%</p>
											<p className="col-4 text text--xsmall">-5%</p>
											<p className="col-5 text text--xsmall">-10%</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Additional W points on TYPE.WELTKERN® products</p>
											<img className="col-2" src={Unchecked} alt="" />
											<p className="col-3 text text--xsmall">
												+10% W Prime <br />
											+10% W Status
										</p>
											<p className="col-4 text text--xsmall">
												+20% W Prime <br />
											+10% W Status
										</p>
											<p className="col-5 text text--xsmall">
												+30% W Prime <br />
											+10% W Status
										</p>
										</div>
										<div className="grid grid--padding">
											<p className="col-1 text text--xsmall">Dedicated client service</p>
											<img className="col-2" src={Checked} alt="" />
											<img className="col-3" src={Checked} alt="" />
											<img className="col-4" src={Checked} alt="" />
											<img className="col-5" src={Checked} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* 	<div className="col-md-12 list-account" id="avantages_status" style={{ display: this.state.avantageClicked ? 'block' : 'none' }}>
							<div className="top-list col-md-10 container-center">
								Avantages status et conditions</div>
							<div className="content-list col-md-12 tab">
								<div className="col-md-12 container-center">
									<Tableau />
								</div>
							</div>
						</div>

						<div className="col-md-12 list-account" id="historique_des_transactions" style={{ display: this.state.historiqueClicked ? 'block' : 'none' }}>
							<div className="top-list col-md-10 container-center">
								Historique des transactions</div>
								<OrdersComponent order={productOrder} />

						</div>*/}
					</div>
					:
					this.props.history.push('/')
				}
				<FooterIndex />
				<Cart />
				<User />

			</>
		)
	}
}
Index.propTypes = {
	auth: PropTypes.object.isRequired,
	getCurrencyData: PropTypes.func.isRequired,
	changeCurrency: PropTypes.func.isRequired,
	getMedias: PropTypes.func.isRequired,
	logoutUser: PropTypes.func.isRequired,
	getCustomer: PropTypes.func.isRequired,
	parrainageUser: PropTypes.func.isRequired,
	checkouts: PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	auth: state.auth,
	currencies: state.currencies,
	currencyChecked: state.currencies.currencyChecked,
	medias: state.medias,
	users: state.users,
	checkouts: state.checkouts,
})

export default connect(mapStateToprops, { getOrders, getCurrencyData, changeCurrency, getMedias, logoutUser, getCustomer, parrainageUser })(Index)
