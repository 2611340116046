
import React, { Component } from 'react';
import { connect } from 'react-redux'
import HeaderFavorites from "../header/Favorites";
import FooterIndex from "../footer/FooterIndex";
import { getPages } from '../../../actions/pagesAction'
import Spinner from '../../utils/Spinner'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Modal from '../lastProduits/modal/Modal';
import LoginIndex from "../login/Login";
import Cart from "../lastProduits/Cart"
import { Helmet } from 'react-helmet';
import Recherche from '../popup/recherche'
import isEmpty from '../../../validation/is-empty';
import OverviewProduct from '../lastProduits/OverviewProduct'
import API, { subUrl } from '../../../utils/api'
import { getFavorite } from '../../../actions/favoriteAction'

import $ from 'jquery'

class FavoritesList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activePage: '',
			isShowing : false,
			value     : 10,
			loadModal : false,
			favorites : [],
		};

	}
	componentDidMount() {

		this.props.getFavorite();
		console.log('favvvvv',this.props.favorites.favorites)
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
		this.props.getPages()
		$('li a.Menu4').addClass("activeMenu")

		API
			.get(subUrl.getFavorit)
			.then(res => {
				this.setState({favorites : res.data})
			}
			)
			.catch(error => console.log(error))
	}
	_displayFavoriteImage(product) {
		var sourceImage = require('../../../assets/images/heart-dislike.svg')
		if (this.props.favorites && this.props.favorites.favorites.findIndex(item => item.id === product.id) !== -1) {
			sourceImage = require('../../../assets/images/heart-dislike.svg')
		}
		return (
			<img src={sourceImage} width="36" height="36" />
		)
	}
	openModalHandler = () => {

		this.setState({
			isShowing: true, loadModal: true
		});

		setTimeout(() => {
			this.setState({ loadModal: false })
		}, 1000);
	}

	closeModalHandler = () => {
		this.setState({
			isShowing: false
		});
	}

	toggleFavorite = (current) => {
		const id = {
		  id : current.id
		}
		API
			.post(subUrl.postFavorit, id)
			.then(res =>
				{
					this.props.getFavorite();
					this.forceUpdate()
			}
			)
			.catch(error => console.log(error))

	  }

	render() {
		const { currentProduct, pages } = this.props;
		let displayFooter;
		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayFooter = <Spinner />
		} else {
			let foundFooter = false;
			displayFooter = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("index") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex key={i} footer={page} />
						)
					}
				}
			})

			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}

		}
		const insideHeaderFavorites = (
			<div className="container-center title-favorites">
				<h1>Favorites</h1>
			</div>
		);

		return (<>
			<Helmet>
					<title>Favorites – WK®</title>
					<meta name="Support-FAQ" content="Favorites – WK®" />
				</Helmet>
			<HeaderFavorites insideHeaderFavorites={insideHeaderFavorites} />

			<div className="container favorites">
				<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center bg-corps clearfix last-addition-shop">
					<div className="items-last-add">
			   		{
							this.props.favorites && this.props.favorites.favorites && this.props.favorites.favorites.length > 0 ?
							this.props.favorites && this.props.favorites.favorites && this.props.favorites.favorites.map(product => {
									return (
										product.categories && product.categories &&
										<div className={"items padding-l-r-4 "+product.categories[0].slug} key={product.id}>
											<div className="label-pastille">
												{
													product.quantity >0 && product.multiplier != false &&
													<div className="multiplication">W{product.multiplier}</div>
												}
											</div>
											<figure className="img-h-full d-flex-column-c">
												<Link to={{ pathname: product.typeface ? `/shop/typeface/${product.slug}` : `/shop/detail-product/${product.slug}`, product }} >
													<img src={product.gallery_image.length ==0 ? product.featured_image.url : product.gallery_image[0].url} alt={product.alt} />
												</Link>
											</figure>
											<div className="description">
												<div className="info-panier">
													<div className="info-prix">
														<div className="left-product">
							                <div className="category-price">
							                  <div className="category" dangerouslySetInnerHTML={{__html : product.name}}></div>
							                  <div className="brands" dangerouslySetInnerHTML={{__html : product.header != false && product.header[0] && product.header[0].header != null && product.header[0].header.author_information != null && product.header[0].header.author_information.author != null  ? product.header[0].header.author_information.author.name :''}}></div>
							                </div>
							              </div>
							              <div className="right-product">
							                <div className="category-price">
							                  <div className="price">CHF <p className="price-virgule">{product.variant? parseFloat(product.variant[0].price).toFixed(2) : product.price.toFixed(2)}</p> <br/><span>or</span> {product.welt_price}W</div>
							                </div>
							              </div>
													</div>
													<div className="info-paiment details-info">
													
														{
															product.quantity > 0 && product.multiplier != false ?
															<div className="prix">{product.categorie_multiplier * product.points.prime}W×{product.multiplier} = <span>{(product.categorie_multiplier * product.points.prime) * product.multiplier}W</span></div>
															:
															product.quantity > 0 && <div className="prix-chf">+{product.categorie_multiplier * product.points.prime}W</div>
														}
														{product.choice_product == "Typeface" ? '' :
																			product.quantity > 0 ?
																			<button className="btn-panier"
																			onClick={() =>                                        // this.openModalHandler

																			{

																				this.props.getCurentProduct(product);
																				this.props.openModal()
																			}
																			}
																			>
																			</button>
																			:
																			<button className="out-of-stock"><p>Out of stock</p></button>
														}
														<button className="btn-heart like"
															onClick={() => {
															this.toggleFavorite(product)
															}
															}
														>
															{this._displayFavoriteImage(product)}
														</button>
													</div>
													
												</div>
											</div>
										</div>
									)
								}) : <div><p>You didn't like any product</p></div>
						}
					</div>
				</div>

			</div>
			<Modal
				className="modal"
				show={this.state.isShowing}
				close={this.closeModalHandler}>
				<div className="modal-body clearfix quick-purchase">
					<span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
					{(!isEmpty(currentProduct.currentProduct) && !this.state.loadModal) ?
						<OverviewProduct
							show={this.state.isShowing}
							currentProduct={currentProduct.currentProduct}
							addToCart={this.props.addToCart}
							addRangeValueToReducer={this.props.addRangeValueToReducer}
							cart={this.props.cart}
							toggleFavorite={this.props.toggleFavorite}
							getFavorite={this.props.getFavorite}
							favorites={this.props.favorites.favorites}
							removeFromCart={this.props.removeFromCart}
							cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.currentProduct.id)[0]}
							currencyChecked={this.props.currencies.currencyChecked}
						/>
						: <Spinner />
					}
				</div>
			</Modal>
			{displayFooter}
			<LoginIndex />
			<Cart />
			<Recherche />
		</>
		)
	}
}

const mapStateToProps = (state) => {
	return ({
		favorites: state.favorites,
		currentProduct: state.currentProduct,
		cart: state.cart,
		footerData: state.footerData,
		pages: state.pages,
		currencies: state.currencies,
	})
}
const mapDispatchToProps = (dispatch) => {
	return {
		addToCart: (item) => {
			dispatch({ type: 'ADD', payload: item })
		},
		removeFromCart: (item) => {
			dispatch({ type: 'REMOVE', payload: item })
		},
		addRangeValueToReducer: (item) => {
			dispatch({ type: 'GET_RANGE_VALUE', payload: item })
		},
		getCurentProduct: (item) => {
			dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
		},
		toggleFavorite: (item) => {
			dispatch({ type: 'TOGGLE_FAVORITE', payload: item })
		},
		getPages: () => dispatch(getPages()),
		getFavorite: () => dispatch(getFavorite()),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(FavoritesList)
