import $ from 'jquery';
import React, { Component } from 'react';
import InputRange from "react-input-range";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getCategories } from '../../../actions/categorieAction';
import { getFavorite } from '../../../actions/favoriteAction';
import { getProducts, getProductsOnPage } from '../../../actions/productsActions';
import tagExcluWelt from '../../../assets/images/tag-exclusive.svg';
import tagLimited from '../../../assets/images/tag-limited.svg';
import tagMadeWelt from '../../../assets/images/tag-madeby.svg';
import tagMultiplication from '../../../assets/images/tag-multiplicator.svg';
import tagNew from '../../../assets/images/tag-new.svg';
import tagRare from '../../../assets/images/tag-rare.svg';
import API, { subUrl } from '../../../utils/api';
import isEmpty from '../../../validation/is-empty';
import Spinner from '../../utils/Spinner';
import HeaderRelative from "../../_includes/header/Relative";
import LoginIndex from "../login/Login";
import Cart from "./Cart";
import Modal from './modal/Modal';
import OverviewProduct from './OverviewProduct';
import ProductPaginate from './productPaginate';
class ProductShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      show: 0,
      onfilter: false,
      isShowing: false,
      value: 10,
      value1: 10,
      value2: 10,
      value3: 10,
      scrollPage:0,
      brand:null,
      people:null,
      productPerPage: 28,
      page : 1,
      allBrand:[],
      allPeople:[],
      allTags:[],
      tag: [],
      totalProduct: 0,
      specials: [],
      value5: {
        min: 0,
        max: 100,
      },
      value6: {
        min: 0,
        max: 10000,
      },
      loadModal: false,
      filtre: '',
      cat: 'Product',
      keySearch: '',
      css: "active"
    };
  }

  

  componentDidMount() {
    
    console.log("page",localStorage.getItem("page"))
    API
			.get(subUrl.getProductLength)
			.then(res => {
				this.setState({totalProduct : res.data})
        console.log("total",res.data)
			}
			)
			.catch(error => console.log(error))
      if(localStorage.getItem("page") == window.location.pathname && parseInt(localStorage.getItem("pageNumber")) != 0 ){
      this.setState({page :localStorage.getItem("pageNumber"), scrollPage :parseInt(localStorage.getItem("scrollPosition")) })
      this.props.getProductsOnPage(this.state.productPerPage*localStorage.getItem("pageNumber"), 1, "all", this.props.cat)
      console.log("positions",$(window).scrollTop())
    } 
    else 
    this.props.getProductsOnPage(this.state.productPerPage, 1, "all", this.props.cat)
    
    this.props.getCategories();
    this.props.getFavorite();
    
    console.log("cate",this.props.cat)
    console.log("proooooo",this.props.products)
    var scrollPage = parseInt(localStorage.getItem("scrollPosition"))
    $('.top-header').addClass('to-top');
    $(window).scroll(function() {    
      var scroll = $(window).scrollTop();
      console.log("allPositions", scrollPage)
      if(scrollPage != 0 && scroll == scrollPage)
      {
        localStorage.setItem("scrollPosition", 0)
        localStorage.setItem("pageNumber", 0)
      }
      if (scroll > 0) {
        $('.top-header').addClass('topUp');
        $('.menu-page-details').addClass('topUp');
        $('.back').addClass('topUp');
      } else {
        $('.top-header').removeClass('topUp');
        $('.menu-page-details').removeClass('topUp');
        $('.back').removeClass('topUp');
      }
    });

    $("#display-filter").on("click", function () {
      $(".popup-shop-filtre").show();
      $("body").addClass('lock-bod');
    });

    $("#btn-valider").on("click", function () {
      $(".popup-shop-filtre").hide();
      $("body").removeClass('lock-bod');
    });

    $("#btn-annuler").on("click", function () {
      $(".popup-shop-filtre").hide();
      $("body").removeClass('lock-bod');
    });

    $("ul.pagination li:last-child")
      .on("click", function () {
        $("ul.pagination li a:last-child").addClass("last1");
      });


    $(".info2").on('click', function (e) {
      e.preventDefault()
      $(".popup--licence").addClass('open');
      $('body').addClass('overflow-hidden');
      $('.hUdZDX .popup--licence.open, .popup--price.open').addClass('firstOn');

    });

    $(".info3").on('click', function (e) {
      e.preventDefault()
      $(".popup--price").addClass('open');
      $('body').addClass('overflow-hidden');
      $('.hUdZDX .popup--licence.open, .popup--price.open').addClass('firstOn');

    });
    $(".popup--price-close").on('click', function (e) {
			$(".popup--price").removeClass('open');
			$(".scrollTop").removeClass('not-showing');
			$('body').removeClass('overflow-hidden');
			e.preventDefault()
		});

    $(".typeface").removeClass("active");

    $(".popup--licence-close").on('click', function (e) {
      $(".popup--licence").removeClass('open');
      $('body').removeClass('overflow-hidden');
      e.preventDefault()
    });

    API
			.get(subUrl.brands)
			.then(res => {
				this.setState({ allBrand: res.data })
        console.log("brand",res.data)
			}
			)
			.catch(error => console.log(error))
      API
			.get(subUrl.tags)
			.then(res => {
				this.setState({ allTags: res.data })
        console.log("tags",res.data)
			}
			)
			.catch(error => console.log(error))
      API
			.get(subUrl.peoples)
			.then(res => {
				this.setState({ allPeople : res.data })
        console.log("people",res.data)
			}
			)
			.catch(error => console.log(error))

  }
  selected = (event) => {
    let currentChecked = event.target.value
    let order = "desc"
    console.log(currentChecked)
    if(currentChecked == 'ascendingprice'){
      currentChecked = "price"
      order = "asc"
    }
    if(currentChecked == 'decreasingprice'){
      currentChecked = "price"
      order="desc"
    }
    if(currentChecked == 'date'){
      currentChecked = "all"
    }

    this.setState(({
      fitre: '3'
    }))
    this.props.getProductsOnPage(this.state.productPerPage, 1, currentChecked, this.props.cat, undefined , order )
  }

  selectedBrand = (event) => {
    this.setState({brand : event.target.value})
  }

  onChangePriceMax = (event) => {
    this.setState({value5 : {max:event.target.value > this.state.value5.min ? event.target.value : this.state.value5.min + 1  , min:this.state.value5.min}}) 
  }

  onChangePriceMin = (event) => {    
    this.setState({value5 : {min:event.target.value < this.state.value5.max ? event.target.value : this.state.value5.max - 1  , max:this.state.value5.max}}) 
  }

  onChangeWeltMin = (event) => {
    this.setState({value6 : {min:event.target.value < this.state.value6.max ? event.target.value : this.state.value6.max - 1 , max:this.state.value6.max}}) 
  }

  onChangeWeltMax = (event) => {
    this.setState({value6 : {max:event.target.value > this.state.value6.min ? event.target.value : this.state.value6.min + 1, min:this.state.value6.min}}) 
  }

  selectedPeople = (event) => {
    this.setState({people : event.target.value})
  }

  selectedTags = (event) => {
    
    if(!event.target.checked){
      const index = this.state.tag.indexOf(event.target.name);
    if (index > -1) {
      this.state.tag.splice(index, 1);
    }
    }
    else
    this.state.tag.push(event.target.name)
    
    console.log(this.state.tag)
  }
  resetSpecials = () => {
    this.setState({specials : []})
    $(".specials").each(function(){
      $(this).prop("checked",false);
    });
  }
  resetTags = () => {
    this.setState({tag : []})
    $(".tags").each(function(){
      $(this).prop("checked",false);
    });
  }

 

  selectedSpecials = (event) => {
    const element = event.target.name == "new2" ? "new" : event.target.name
    if(!event.target.checked){
      const index = this.state.specials.indexOf(element);
    if (index > -1) {
      this.state.specials.splice(index, 1);
    }
    }
    else
    this.state.specials.push(element)
    console.log(event.target.name, event.target.checked)
    console.log(this.state.specials)
  }

  onClick(item) {
    this.setState({
      cat: item,
      css: ""
    })
    this.props.getProductsOnPage(this.state.productPerPage, 1, 'all', this.props.cat)
  }

  inputsaerch = (event) => {
    console.log("element input", event.target.value)
    this.setState(({
      keySearch: event.target.value
    }))
  }

  search = () => {
    console.log(this.state.keySearch);
    this.props.getProductsOnPage(this.state.productPerPage, 1, 'all', this.props.cat, this.state.keySearch)
  }

  searchFilter = () => {
    const price = this.state.value5;
    const welt = this.state.value6;
    const brand = this.state.brand && this.state.brand.length ? this.state.brand : "undefined";
    const people = this.state.people && this.state.people.length ? this.state.people : "undefined";
    const tag = this.state.tag && this.state.tag.length ? this.state.tag : "undefined";
    const special = this.state.specials && this.state.specials.length ? this.state.specials : "undefined" ;
    this.setState({onfilter : true,
      activePage: 1})
    this.props.getProductsOnPage(1000, 1, 'all', this.props.cat, this.state.keySearch, undefined, brand, people, price, welt, tag, special)
  }

  searchFilterCancel = () => {
    this.setState(({
       activePage: 1 ,
       onfilter : false,
       show : 0
    }))
    
    this.props.getProductsOnPage(this.state.productPerPage, 1, "all", this.props.cat)
  }

  reset = () => {
    this.props.getProductsOnPage(this.state.productPerPage, 1, 'all', this.props.cat)
    this.setState(({
      keySearch: '',
       activePage: 1 
    }))
    $('input.categoryShop').val('');
  }

  openModalHandler = () => {
    document.body.classList.add("no-sroll")

    this.setState({
      isShowing: true, loadModal: true
    });

    setTimeout(() => {
      this.setState({ loadModal: false })
    }, 1000);
  }

  closeModalHandler = () => {

    this.setState({
      isShowing: false
    });
    document.body.classList.remove("no-sroll")

  }
  _displayFavoriteImage(product) {
    var sourceImage = require('../../../assets/images/heart-like.svg')
    if (this.props.favorites.favorites.findIndex(item => item.id === product.id) !== -1) {
      sourceImage = require('../../../assets/images/heart-dislike.svg')
    }
    return (
      <img
        src={sourceImage}
        width="36"
        height="36" alt="welt"
      />
    )
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber })
    if(!this.state.onfilter){
    this.props.getProductsOnPage(this.state.productPerPage, pageNumber, this.state.filtre, this.props.cat)
    console.log("filtre")
    }
    else{
      this.setState({ show: (pageNumber - 1) * this.state.productPerPage })
      console.log("pas de filtre")
    }
    
    $('html,body').animate({
      scrollTop: 0
    }, 'slow');
    //this.props.getProductsOnPage(this.state.productPerPage, pageNumber, this.state.filtre, this.props.cat);
    
  }

  loadMore = () => {
    this.setState({page : parseInt(this.state.page) + 1})
    console.log("load more", this.state.page)
    if(!this.state.onfilter){
      this.props.getOneMoreProduct(this.props.products.products,this.state.productPerPage, parseInt(this.state.page)+ 1, this.state.filtre, this.props.cat)
      console.log("filtre")
      }
      else{
        this.setState({ show: (parseInt(this.state.page) - 1) * this.state.productPerPage })
        console.log("pas de filtre")
      }
    
  }
  componentDidUpdate(props, state) {
    if (state.filtre) {
      this.props.getProductsOnPage(this.state.productPerPage, this.state.productPerPage)
    }
    if(this.props.products != props.products){
      if(localStorage.getItem("page") == window.location.pathname){
        console.log("scrollPage",this.state.scrollPage, state.scrollPage)
        if(parseInt(localStorage.getItem("scrollPosition")) != 0){
          $('html, body').animate({scrollTop:localStorage.getItem("scrollPosition")}, 'slow');
          
        }
        
      }
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.search();
    }
  }

  render() {
    const { products, currentProduct, categories } = this.props;
    let fullProduct, total;
    
    if (products.someProducts !== null) {
      total = products.total;
      fullProduct = products && products.someProducts && products.someProducts[0] && products.someProducts.map((product, i) => {
        return (
          <ProductPaginate
            key={i}
            id={i}
            page={parseInt(this.state.page)}
            products={product}
            addToCart={this.props.addToCart}
            removeFromCart={this.props.removeFromCart}
            getFavorite      = {this.props.getFavorite}
            favorites={this.props.favorites.favorites}
            getCurentProduct={this.props.getCurentProduct}
            openModal={this.openModalHandler}
            cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === product.id)[0]}
            currencyChecked={this.props.currencies.currencyChecked}
          />
        )
      })
    }
    const insideHeaderRelative = (
      <div>
        <div className={`shop-top ${this.props.myClass && this.props.myClass } col-md-12 relative clearfix align-center`}>
          <ul className="title-big">
            {/* {categories != null && categories.loadingCategories === false && categories.categories && categories.categories.map((item) =>
              item.count != 0 ?
                <li><a onClick={() => this.onClick(item.slug)} className={item.slug == this.state.cat ? "active " +item.slug : item.slug != "typeface23" ? item.slug +" " + this.state.css : item.slug } >{item.name}</a>,</li>
                :
                ''
            )} */}
            <li><a href="/shop" class="all">All</a>,</li>
            <li><a href="/shop/books" class="book">Books</a>,</li>
            <li><a href="/shop/typefaces" class="typeface">Typefaces</a>,</li>
            <li><a href="/shop/supplies" class="furnitures">Supply</a>,</li>
            <li><a href="/shop/stationery" class="goodies">Stationery</a>,</li>
            <li><a href="/shop/rare" class="rare">Rare</a>,</li>
            <li><a href="/shop/other" class="other">Others</a>,</li>
          </ul>
        </div>
        <div className="bottom-header col-md-12">
          <div className="scroll-bottom">
            <a>We've got <span className="notranslate">{total} {this.props.cat == undefined ? "Product" : categories != null && categories.loadingCategories === false && categories.categories && categories.categories.filter((cat)=> cat.slug == this.props.cat)[0] && categories.categories.filter((cat)=> cat.slug == this.props.cat)[0].name }(s)</span> for you</a>
          </div>
        </div>
      </div>
    );
    return (<>
    <div class="boxes">
				<div id="div1" class="message-delivery box1"> FREE DELIVERY IN SWITZERLAND <span className="espace"></span> FREE DELIVERY IN EUROPE FROM CHF 300 AND FROM CHF 600 WORLDWIDE</div>
				<div id="div2" class="message-collect box2"> COLLECT, REDEEM AND ENJOY YOUR W POINTS ON EVERY PURCHASE !</div>
				<div id="div3" class="message-register box3"> AS SOON AS YOU REGISTER, YOU WILL RECEIVE 500W<span className="espace"></span> ENJOY THEM!</div>
			</div>
      <div>
        <HeaderRelative insideHeaderRelative={insideHeaderRelative} />
        <div className="col-md-12 filtre-produits scroll-top-div">
          <div className="col-md-6  float-left researchBox">
            <div className="rech big">
              <label className="shopLabel">Search</label>
              <input type="text" className="categoryShop" placeholder="Type here" onChange={this.inputsaerch} onKeyPress={this.handleKeyPress} />
            </div>

            <div className="boximgchearch">
              <div className="btn-group-search">
                <a className="schearchIcon-find" onClick={this.search}>
                  </a>
                <a className="schearchIcon-reset" onClick={this.reset}>
                  </a>
              </div>

            </div>
          </div>
          <div className="col-md-6 order flexeTop">
            
            {/* btn filtre */}
            <div className="col-md-6 btnSubmit">
              <button id="display-filter" className="filterbtn"><p>Filter and category</p></button>
            </div> 

            <div className="col-md-6 select-order selectfilter">
              <select name="filtre" onChange={this.selected} >
                <option value="ascendingprice">Ascending price</option>
                <option value="decreasingprice">Decreasing price</option>
                <option value="all" selected>Latest</option>
                {/* <option value="price">Price</option>
                <option value="date">Newest</option> */}
                
              </select>
            </div>
            <div className="layout-grid">
              <a className="layout-2 active" />
              <a className="layout-4" />
            </div>
            
          </div>
        </div>
      </div>

      <div className="col-md-12 clearfix">
        <div className="col-md-12 bg-corps padding-t-b-l-r-8 overflow-h align-center view-produits clearfix all-products" >
          {total && total > 0 ? fullProduct : <div className="not-found-product"><p>Nothing found</p></div>  }
        </div>

        <div className="pagination-produits">

          {/* <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.productPerPage}
            totalItemsCount={total}
            pageRangeDisplayed={total % this.state.productPerPage == 0 ? total/this.state.productPerPage : (total/this.state.productPerPage)+1  }
            onChange={this.handlePageChange}
            prevPageText="← Previous"
            nextPageText="Next →"
            lastPageText="Last"
            firstPageText="First"
            hideDisabled={true}
            itemClassPrev="previous-page"
            itemClassNext="next-page"
            hideFirstLastPages={true}
            linkClass = "notranslate"
          /> */}
          {total>20 && total>(parseInt(this.state.page)*20) &&
          <a name="" id="load-more" class="btn btn-primary" onClick={this.loadMore} role="button">Load More </a>
        }
        </div>
      </div>
      <LoginIndex />

      <Cart />

      {/* <Recherche /> */}
      <Modal
        className="modal"
        show={this.state.isShowing}
        close={this.closeModalHandler}>
        {/* <div className="modal-header">
                    <h3>title</h3>
                </div> */}
        <div className="modal-body clearfix quick-purchase">
          <span className="close-modal-btn" onClick={this.closeModalHandler}>x</span>
          {/* characteristics Poduct */}
          {(!isEmpty(currentProduct.currentProduct) && !this.state.loadModal) ?
            <OverviewProduct
              currentProduct={currentProduct.currentProduct}
              addToCart={this.props.addToCart}
              cart={this.props.cart}
              addRangeValueToReducer={this.props.addRangeValueToReducer}
              getFavorite={this.props.getFavorite}
              favorites={this.props.favorites.favorites}
              removeFromCart={this.props.removeFromCart}
              cartItem={this.props.cart.cartItems.filter(cartItem => cartItem.id === currentProduct.currentProduct.id)[0]}
              currencyChecked={this.props.currencies.currencyChecked}
            />
            : <Spinner />
          }

        </div>
      </Modal>

      <div className="popup-shop-filtre">
        <div className="col-md-10 container-center">
          <div className="bloc-categorie bloc-elts">
            <div className="titre-filtr">Tags</div>
            <div className="remove-filtr" onClick={this.resetTags}></div>
            <div className="elts-filtr">
              {this.state.allTags && this.state.allTags[0] && this.state.allTags.map((item)=>
                  <div className="elts-checkbox">
                    <input onChange={this.selectedTags} className="tags" type="checkbox" name={item.slug} id={item.slug} />
                    <label for={item.slug}>{item.name}</label>
                  </div>
              )}
            </div>
          </div>

          <div className="bloc-tags bloc-elts">
            <div className="titre-filtr">Specials</div>
            <a onClick={this.resetSpecials}>
            <div className="remove-filtr"></div>
            </a>
            <div className="elts-filtr">
              <div className="elts-checkbox">
                <input type="checkbox" className="specials" name="new2" id="new2" onChange={this.selectedSpecials} />
                <label for="new2" className="no-bg"><img src={tagNew} alt="New" /></label>
              </div>
              <div className="elts-checkbox">
                <input type="checkbox" className="specials" name="limited" id="limited" onChange={this.selectedSpecials} />
                <label for="limited" className="no-bg"><img src={tagLimited} alt="New" /></label>
              </div>
              <div className="elts-checkbox">
                <input type="checkbox" className="specials" name="rare" id="rare" onChange={this.selectedSpecials} />
                <label for="rare" className="no-bg"><img src={tagRare} alt="New" /></label>
              </div>
              <div className="elts-checkbox">
                <input type="checkbox" className="specials" name="weltclub_exclu" id="weltclub_exclu" onChange={this.selectedSpecials} />
                <label for="weltclub_exclu" className="no-bg"><img src={tagExcluWelt} alt="New" /></label>
              </div>
              <div className="elts-checkbox">
                <input type="checkbox" className="specials" name="multiplication" id="multiplication" onChange={this.selectedSpecials} />
                <label for="multiplication" className="no-bg"><img src={tagMultiplication} alt="New" /></label>
              </div>
              <div className="elts-checkbox">
                <input type="checkbox" className="specials" name="made_by_weltanschauung" id="made_by_weltanschauung" onChange={this.selectedSpecials} />
                <label for="made_by_weltanschauung" className="no-bg"><img src={tagMadeWelt} alt="New" /></label>
              </div>
            </div>
          </div>

          <div className="bloc-middle-filtr">
            <div className="bloc-select">
              
              <div className="elts-select">
                <div className="titre-filtr">People/Design</div>
                <div className="select">
                  <select name="people" className= {`${this.state.people ? '' : 'nagative'}`} onChange={this.selectedPeople}>
                    <option value="">People</option>
                    {this.state.allPeople && this.state.allPeople[0] && this.state.allPeople.map((item)=>
                      <option value={item.slug} dangerouslySetInnerHTML={{ __html: item.name }}></option>
                    )}
                  </select>
                </div>
              </div>
              <div className="elts-select">
                <div className="titre-filtr">Brand/Publisher</div>
                <div className="select">
                  <select className= {`${this.state.brand ? '' : 'nagative'}`} name="brand" onChange={this.selectedBrand}>
                    <option value="">Brand</option>
                    {this.state.allBrand && this.state.allBrand[0] && this.state.allBrand.map((item)=>
                      <option value={item.slug} dangerouslySetInnerHTML={{ __html: item.name }}></option>
                    )}
                    
                  </select>
                </div>
              </div>
            </div>
            <div className="bloc-price">
              <div className="elts-price">
                <div className="titre-filtr">CHF filter</div>
                <div className="range-price">
                  <div className="elts-price">
                    <div className="min-price"><sup>CHF</sup> <input onChange={this.onChangePriceMin} min="0" max="999" name="min" type="number" value={this.state.value5.min} /></div>
                    <div className="max-price"><sup>CHF</sup> <span> <input onChange={this.onChangePriceMax} min="0" max="1000" name="max" type="number" value={this.state.value5.max} /></span></div>
                    <InputRange
                      draggableTrack
                      maxValue={1000}
                      minValue={0}
                      step="1"
                      value={this.state.value5}
                      onChange={value => this.setState({ value5 : value })}
                      onChangeComplete={value => console.log(value)} />
                    <div className="info-range info2">?</div>
                  </div>
                </div>
              </div>
              <div className="elts-price">
                <div className="titre-filtr">W filter</div>
                <div className="range-price">
                  <div className="elts-price">
                    <div className="min-price"><span><input onChange={this.onChangeWeltMin} min="0" max="9999" name="minwelt" type="number" value={this.state.value6.min} /></span>W</div>
                    <div className="max-price"><span><input onChange={this.onChangeWeltMax} min="0" max="10000" name="maxwelt" type="number" value={this.state.value6.max} /></span>W</div>
                    <InputRange
                      draggableTrack
                      maxValue={100000}
                      minValue={0}
                      step="1"
                      value={this.state.value6}
                      onChange={value => this.setState({ value6 : value })}
                      onChangeComplete={value => console.log(value)} />
                    <div className="info-range info3">?</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bloc-button-filtre">
            <button id="btn-valider" onClick={this.searchFilter} className="btn-confirmer">Confirm</button>
            <div className="elts-monnaie">
            <button id="btn-annuler" onClick={this.searchFilterCancel} className="btn-confirmer">Cancel</button>
              {/* <div className="chf">CHF</div> */}
            </div>
          </div>
        </div>
      </div>

      <Popups>
        <div className="popup--licence col-md-12">
          <div className="popup__content">
            <div className="col-md-8 center">
              <div className="popup__text-content">
                <div className="popup__title">
                  <h3 className="uppercase text text--xmedium">You can set the price range in CHF.</h3>
                </div>
                
              </div>
              <div className="col-md-12 right">
                <button className="popup--licence-close btn btn--close">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div className="popup--price col-md-12">
          <div className="popup__content">
            <div className="col-md-8 center">
              <div className="popup__text-content">
                <div className="popup__title">
                  <h3 className="uppercase text text--xmedium">You can set the price range in W points.</h3>
                </div>
                {/* <div className="popup__text">
                  <p className="mb-30">
                    Vous cumuler des points W à chaque achat sur WELTKERN®. De nombreuses
                    manières s’offrent à vous. Bla bla. Vous cumuler des points W à chaque
                    achat sur WELTKERN®. De nombreuses manières s’offrent à vous. Bla bla.
                    Vous cumuler des points W à chaque achat sur WELTKERN®. De nombreuses
                    anières s’offrent à vous. Bla bla.
                                        </p>
                  <p className="underline">
                    Discover and join the club here<br />
                                            to enjoy your first 500W points !
                                        </p>
                </div> */}
              </div>
              <div className="col-md-12 right">
                <button className="popup--price-close btn btn--close">Close</button>
              </div>
            </div>
          </div>
        </div>
      </Popups>
    </>)
  }
}
const mapStateToProps = (state) => {
  return ({
    cart: state.cart,
    countproducts: state.products,
    favorites: state.favorites,
    products: state.products,
    currentProduct: state.currentProduct,
    currencies: state.currencies,
    categories: state.categorie,
  })
}
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch({ type: 'ADD', payload: item })
    },
    addRangeValueToReducer: (item) => {
      dispatch({ type: 'GET_RANGE_VALUE', payload: item })
    },
    removeFromCart: (item) => {
      dispatch({ type: 'REMOVE', payload: item })
    },
    getCurentProduct: (item) => {
      dispatch({ type: 'GET_CURRENT_PRODUCT', payload: item })
    },
    getFavorite: () => dispatch(getFavorite()),
    getProducts: () => dispatch(getProducts()),
    getProductsOnPage: (perPage, paramater, order, typeface, search, orderb, brand, people, price, weltprice, categorie, special) => dispatch(getProductsOnPage(perPage, paramater, order, typeface, search, orderb, brand, people, price, weltprice, categorie, special)),
    getCategories: () => dispatch(getCategories()),
  }
}



const LeftSide = styled.div`
.options__title { float: left; }
.options__details { float: right;}

.select__title { margin-bottom: 20px; }
`;

const Overview = {
  Content: styled.div`
    font-family: Lausanne600;
    `,

  Options: styled.div`
    .overview__details {
        margin-bottom: 240px;
    }
    .details__author { color: #D1D1D1; }
    `,

  Licenses: styled.div`
    .popup-licence {
        font-family: 'Lausanne400';
        background: #EBEBEB;
        display: flex !important;
        border-radius: 50px;
        height: 15px;
        width: 15px;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        cursor: pointer;
    }
    .licenses__title {
        dispay: flex;
        align-items: center;
        gap: 10px;
        float: left;
        line-height: 36px;
        display: flex;

    }
    .licenses__details { float: right; }
    .alert {
        p {
            color: red;
            font-family: 'Plain-Medium';
        }
    }
    `,

  Gift: styled.div`
    .options__title { float: left; }
    .license-third {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .ajout-license {
            &.checked {
                &:before {
                    background: transparent;
                    content: "";
                    display: flex;
                    justify-content: center;
                    background-image: url("/images/checked.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }
    .options__licenses {
        display: flex;
        flex-direction: column;
        float: right;
        span {
            width: 20px;
            height: 20px;
            border: 1px solid black;
            border-radius: 50px;

            &:checked {
                &:before {
                    content: "";
                    display: flex;
                    align-self: center;
                    height: 20px;
                    width: 20px;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    `,

  Price: styled.div`
    .range {
        display: flex;
		padding-bottom: 25px;

    }
    .popup-price {
        font-family: 'Lausanne400';
        background: #EBEBEB;
        display: flex !important;
        border-radius: 50px;
        height: 15px;
        width: 15px;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        cursor: pointer;
        z-index: 1;
    }
    .prices > *,
    .prices sup,
    .prices span,
    .prices label {
        color: #BBBBBB !important;
    }

    .price--chf {
        sup {
            padding-right: 3px;
            top: -0.8em;
        }
    }
    `,

  PriceDetails: styled.div`
    .active { background: black !important; }
    .right-side {
        float: right;
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        justify-content: flex-end;
    }

    .btn-heart{
        padding: 20px;
        background: #D8D8D8;
        &.like { border: none; }
    }

    .panier-type {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 100%;
        gap: 10px;
        padding: 0 20px;
        border-radius: 50px;
		background: #D8D8D8;
        p { color: white; }
    }
    .img-cart { display: flex; }

    .products__number {
        border: 1px solid #EBEBEB;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 50px;
        padding: 0 20px;

        .products--number { padding: 0 5px; }
        .products--incrementation,
        .products--decrementation {
            border: none;
            background: none;
            display: flex;
				}
				 .qty-add-favori .qty {
					padding: 0 !important;
			}
    }
    `,
}

const License = {
  Form: styled.div`
    &.license__container {
        background: RGBA(235,235,235,0.98);

        .title-big, .country{ margin-bottom: 55px; }
    }
    `,
}

const Popups = styled('div')`
.popup--licence,
.popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(235,235,235,0.98);
    font-family: 'TWKLausanne-600';

    &.open {
        opacity: 1;
        visibility: visible;
        z-index: 9999999999;
    }

    .popup__content {
        display: flex;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin-top: 400px;
        padding-bottom: 100px;
    }
    .center {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .right {
        display: flex;
        justify-content: flex-end;
    }
    .popup__title { margin-bottom: 70px; }
    .mb-30 { margin-bottom: 30px; }
    .mb-40 { margin-bottom: 40px; }
    .underline { text-decoration: underline; }
}
@media screen and (max-width: 480px){
	.popup__content{
    margin-left: 14px;
    margin-right: 14px;
  }

}
`;
export default connect(mapStateToProps, mapDispatchToProps)(ProductShop)
