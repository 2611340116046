import {GET_CURRENCIES, CURRENCY_LOADING ,CHANGE_CURRENCY} from '../actions/types'

const initialState = { 
    currency: null,
    currencyChecked :{name: "Swiss franc", key: "CHF", value: "1"},
    loading: false,
}

export default function (state= initialState, action)  {
    switch (action.type) {
        case CURRENCY_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_CURRENCIES:
            return {
                ...state,
                currency: action.payload,
                loading: false
            };
        case CHANGE_CURRENCY :
            return {
                ...state,
                currencyChecked: action.payload,
            }
        default: 
           return state
    }

}