import React, { Component } from 'react';
import HeaderIndex from "../../components/support/header";
import FooterIndex from "../_includes/footer/FooterIndex";
import Spinner from '../utils/Spinner'
import Cart from "../_includes/lastProduits/Cart"
import User from "../_includes/login/Login"
import { getPages } from '../../actions/pagesAction'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import FooterSanaHeader from '../_includes/footer/FooterSanaHeader';


class Calendar extends Component {
	componentDidMount() {

		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
		this.props.getPages()
		$(".cart, .account, .account-offine, .menu").on('click', function () {
			$(".top-header").toggleClass("dark")
			return false
		})

		$('li a.Menu4').addClass("activeMenu")
	}
	render() {

		const { pages } = this.props;
		let displayFooter;
		let displayContent, content, title;

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayContent = <Spinner />
		} else {
			let foundBanners = false;
			let foundFooter = false;
			let foundContent = false;

			displayFooter = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-calendar") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<FooterIndex
								key={i}
								footer={page}
							/>
						)
					}
				}
			})
			displayContent = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("support-calendar") > -1)
					if (founded) {
						let description = page.content_calendar;
						content = description.content.map((item)=>{
							return(
								<>
								<h4>{item.date_time}<br />
									{item.place}</h4>

								<p dangerouslySetInnerHTML={{__html: item.text}} className="no-padd"></p>
								</>
							)
						})
						title = (<p dangerouslySetInnerHTML={{__html: page.content_calendar.title}}></p>)
					}
				}
			})

			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}
		}

		return (
			<>
				<Helmet>
					<title>Updates – WK®</title>
					<meta name="Support-Calendar" content="Page Support-Calendar de welt." />
				</Helmet>
				<HeaderIndex/>
				<div className="col-md-12 clearfix scroll-top-div">
					<div className="col-md-10 container-center bg-blanc padding-t-10 padd-t12">
						<div className="col-md-10 container-center">
							<div className="col-md-5 float-left">
								{title}
							</div>
							<div className="col-md-7 float-left">
								<div className="contact-mails support-style">
									{content}
								</div>
							</div>
						</div>
					</div>
				</div>

				<FooterSanaHeader />
				<Cart />
				<User />
			</>)
	}
}

Calendar.propTypes = {
	getPages: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,

})

export default connect(mapStateToprops, { getPages })(Calendar)
