import { GET_ORDERS,  ORDERS_LOADING, SET_CURRENT_ORDER, SET_STATUS, PAYED_LOADING, INIALIZE_CART, INIALIZE_CHECKOUT } from './types'
import API, {subUrl} from '../utils/api'
export const getOrders = () => dispatch => {
    dispatch(setOrdersLoading());
    API
        .get(subUrl.orders)
        .then(res =>
            {
                
                dispatch({
                type: GET_ORDERS,
                payload: res.data
            })
        }
        )
        .catch(error => console.log(error))
}
export const newCheckout = (checkoutData) => dispatch => {
    console.log(checkoutData)
    API
    .post(subUrl.orders, checkoutData)
        .then(res =>
            {
                dispatch({
                    type: SET_CURRENT_ORDER,
                    payload: res.data
                })
        })
        .catch(error => console.log(error))
}


 export const setCurrentOrder = (checkoutData) =>  dispatch => {
    console.log(checkoutData)
    API
    .post(subUrl.updateOrders, checkoutData)
        .then(res =>
            {
                console.log(res.data)
                
        })
        .catch(error => console.log(error))
 }
export const setOrdersLoading = () => {
    return {
        type:  ORDERS_LOADING
    };
};

export const payOrder = (orderData) => dispatch => {
    dispatch(setPayedOrdersLoading());
    API
        .post(subUrl.payOrder, orderData)
        .then(res => {
            console.log(res.status)
            if (res.status === 200) {
                dispatch({
                    type: SET_STATUS,
                    payload: res.status
                })
                dispatch(InitialiazeCart());
            }
            console.log(res)
        })
        .catch(error => console.log(error))
    
}

export const setPayedOrdersLoading = () => {
    return {
        type:  PAYED_LOADING
    };
};
export const InitialiazeCart = () => {
    return {
        type:  INIALIZE_CART
    };
};

export const InitialiazeCheckout = () => {
    return {
        type:  INIALIZE_CHECKOUT
    };
};


