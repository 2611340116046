import React, { Component } from 'react';
import HeaderIndex from "../../components/support/header";
import FooterIndex from "../_includes/footer/FooterSanaHeader";
import MenuSupport from "../_includes/menu/support";
import { Helmet } from 'react-helmet';
import Cart from '../_includes/lastProduits/Cart';
import User from '../_includes/login/Login';
import $ from 'jquery';
import { getPages } from '../../actions/pagesAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../utils/Spinner'
import Item from 'antd/lib/list/Item';

class Download extends Component {
	componentDidMount() {
		$('html,body').animate({
      scrollTop: 0
    }, 'slow');
		this.props.getPages()
		$(".cart, .account, .account-offine, .menu").on('click', function () {
			$(".top-header").toggleClass("dark")
			return false
		})
		$('li a.Menu3').addClass("activeMenu")
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = `
        jQuery(function () {
        $(".menu-my-account").idTabs({});
      });
    `;
		document.body.appendChild(s);
	}

	
	render() {
		const { pages } = this.props;
		let re = /(?:\.([^.]+))?$/;
		let displayDocument, displayCorporate, displayOther ;
		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayDocument = <Spinner />
			displayCorporate = <Spinner />
			displayOther = <Spinner />

		} else {
			pages.pageData.map((faq, i) => {
				if (faq.slug !== null) {
					let founded = (faq.slug.indexOf("support-download") > -1)
					if (founded) {
						displayDocument = faq.documents && faq.documents != null && faq.documents.map((item) => {
							return (
								<div className="articles col-md-3 padding-l-r-4 float-left">
									<div className="img-l-full">
										{/* <img src="/images/fiche-technique-vitra.png" alt="Welt" /> */}
										<img src={item.image} alt="Welt" />
									</div>
									<div className="description">
										<div className="category-link">
											<div className="category uppercase">{re.exec(item.pdf.url)[1]} </div>
											<a href={item.pdf.url} target="blank">Download <span>&#x21E4;</span></a>
										</div>
										<h2 className="title-medium" dangerouslySetInnerHTML={{__html: item.title.replace("<p>","").replace("</p>","")}}></h2>
									</div>
								</div>
							)
						})
						displayCorporate = faq.corporate && faq.corporate != null && faq.corporate.map((item) => {
							return (
								<div className="articles col-md-3 padding-l-r-4 float-left">
									<div className="img-l-full">
										{/* <img src="/images/fiche-technique-vitra.png" alt="Welt" /> */}
										<img src={item.image} alt="Welt" />
									</div>
									<div className="description">
										<div className="category-link">
											<div className="category uppercase">{re.exec(item.pdf.url)[1]} </div>
											<a href={item.pdf.url} target="blank">Download <span>&#x21E4;</span></a>
										</div>
										<h2 className="title-medium" dangerouslySetInnerHTML={{__html: item.title.replace("<p>","").replace("</p>","")}}></h2>
									</div>
								</div>
							)
						})
						displayOther = faq.other && faq.other != null && faq.other.map((item) => {
							return (
								<div className="articles col-md-3 padding-l-r-4 float-left">
									<div className="img-l-full">
										{/* <img src="/images/fiche-technique-vitra.png" alt="Welt" /> */}
										<img src={item.image} alt="Welt" />
									</div>
									<div className="description">
										<div className="category-link">
											<div className="category uppercase">{re.exec(item.pdf.url)[1]} </div>
											<a href={item.pdf.url} target="blank">Download <span>&#x21E4;</span></a>
										</div>
										<h2 className="title-medium" dangerouslySetInnerHTML={{__html: item.title.replace("<p>","").replace("</p>","")}}></h2>
									</div>
								</div>
							)
						})
					}
				}
			})
		}

		const insideHeader = (
			<MenuSupport />
		);
		return (
			<>
				<Helmet>
					<title>Download – WK®</title>
					<meta name="Support-Download" content="Download – WK®" />
				</Helmet>
				<HeaderIndex />
				<div className="col-md-12 clearfix scroll-top-div">
					<div className="col-md-10 container-center tab-list">
						<div className="menu-account">
							<ul className="d-flex-row menu-my-account">
								<li><a href="#documents">Documents</a></li>
								<li><a href="#corporate">Corporate</a></li>
								<li><a href="#other">Other</a></li>
							</ul>
						</div>
					</div>

					{/* Documents */}
					<div className="col-md-12 list-account" id="documents">
						<div className="top-list col-md-10 container-center bg-blanc">
							Download
    				</div>

						<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center">
							{/* Produits items */}
							{displayDocument}
							{/* End Produits items */}
						</div>
					</div>
					{/* Documents */}

					{/* Corporate */}
					<div className="col-md-12 list-account" id="corporate">
						<div className="top-list col-md-10 container-center bg-blanc">
							Download
    				</div>

						<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center">
							{/* Produits items */}
							{displayCorporate}
							{/* End Produits items */}
							{/* End Produits items */}
						</div>
					</div>
					{/* Corporate */}
					{/* Other */}
					<div className="col-md-12 list-account" id="other">
						<div className="top-list col-md-10 container-center bg-blanc">
							Download
    				</div>
						<div className="col-md-12 padding-t-b-l-r-8 overflow-h align-center">
							{/* Produits items */}
							{displayOther}
							{/* End Produits items */}
						</div>
					</div>
					{/* Other */}
				</div>
				<Cart />
				<User />
				<FooterIndex />
			</>)
	}
}

Download.propTypes = {
	getPages: PropTypes.func.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,

})

export default connect(mapStateToprops, { getPages })(Download)
