import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class MenuIndex extends Component {
	render() {

		return (
			<>
				<p className="menu-home-new">MENU</p>
				<div className="bloc-type-page scroll-top-div">

					<ul className="element-black">
						<li><a href="/shop" className={this.props.currentPage.includes("shop") ? "active" : ""}>Shop</a>, </li>
						<li><Link to="/typeface" className={this.props.currentPage.includes("typeface") ? "active" : ""}>Typefaces</Link></li>
						{/*<li><Link to="/journal" className={this.props.currentPage.includes("journal") ? "active" : ""}>Journal</Link></li>*/}
					</ul>
					<ul className="element-gray">
					{/*	<li><Link to="/font" >Font in use</Link>, </li>*/}
						<li><Link to="/club" >Club</Link>, </li>
						<li><Link to="/support" >Support </Link></li>
					</ul>
				</div>
			</>
		)
	}
}
