import { GET_PAYMENT,PAYMENT_LOADING} from '../actions/types'

const initialState = {
    payment : null,
    loading: true
}
const paymentReducer =( state = initialState, action)=> {
    switch(action.type) {
        case PAYMENT_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_PAYMENT:
            return {
                ...state,
                payment: action.payload,
                loading: false
            }; 
        default :
            return state
    }
}
export default paymentReducer