import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getPages } from '../actions/pagesAction';
import { getProducts } from '../actions/productsActions';
import Checked from '../assets/images/checked.svg';
import Collect1 from '../assets/images/collect-1.png';
import Collect2 from '../assets/images/collect-2.png';
import Collect3 from '../assets/images/collect-3.png';
import Collect4 from '../assets/images/collect-4.png';
import Collect5 from '../assets/images/collect-5.png';
import Redeem1 from '../assets/images/redeem-1.png';
import Redeem2 from '../assets/images/redeem-2.png';
import Redeem3 from '../assets/images/redeem-3.png';
import Unchecked from '../assets/images/unchecked.svg';
import Spinner from './utils/Spinner';
import FooterIndex from "./_includes/footer/FooterIndex";
import HeaderClub from "./_includes/header/Club";
import HeaderPage from "./_includes/headersPage/HeaderPage";
import Cart from './_includes/lastProduits/Cart';
import User from './_includes/login/Login';


class Weltclub extends Component {

	constructor() {
		super()
		this.state = {
			color: 'red',
		}
	}
	componentDidMount() {
		$('html,body').animate({
			scrollTop: 0
		}, 'slow');

		if ($('.scroll-top-div').length) {

			var fixmeTop = $('.scroll-top-div').offset().top ? $('.scroll-top-div').offset().top + 800 : 800;

			$(window).scroll(function () {
				var currentScroll = $(window).scrollTop();
				if (currentScroll >= fixmeTop) {
					$('.menu-page-details').addClass('dark');
					$('.top-header').addClass('dark');
				} else {
					$('.menu-page-details').removeClass('dark');
					$('.top-header').removeClass('dark');
				}
			});

		}

		this.props.getPages();
		const { currentLange, currencies } = this.props;
		const { currencyChecked } = currencies;
		const { language }     = currentLange;
		this.props.getProducts(language, currencyChecked.key);

		$("body.body").removeClass("body-lock");


/* 		$(".account, .account-offine").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		});
		$(".menu").on('click', function () {
			$(".top-header").toggleClass("dark");
			return false;
		}); */
	}
	render() {
		const { pages, products } = this.props;
		let displayedHeaders;
		let displayFooter;
		let displayContent;
		let displayPriv;
		let _produit;
		console.log("product", products)

		if (pages.pageData === undefined || pages.pageData === null || pages.loading) {
			displayedHeaders = <Spinner />
			displayFooter = <Spinner />
			displayContent = <Spinner />
			displayPriv = <Spinner />
		} else {
			let foundHeader = false;
			let foundFooter = false;
			let foundContent = false;
			const founded = pages.pageData.find(page => page.slug.indexOf("welt-club") > -1);
			if (founded) {
				if(founded.header && founded.header.link && founded.header.link.interne && founded.header.link.interne.ID){
					if(founded.header.link.interne.post_type === "post"){
						_produit = null;
					}
					else {
						_produit = products && products.products ? products.products.find(produit => produit.id === founded.header.link.interne.ID) : null;
					}
				}
				foundHeader = true;
				foundFooter = true;
				this.state.color = founded.colorBackground
				displayedHeaders = (
					<HeaderPage
						header={founded}
						scroll={founded.colorBackground}
					/>
				)
				displayFooter = (
					<FooterIndex
						footer={founded}
					/>
				)
			}

			displayPriv = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("welt-club") > -1);
					if (founded) {
						foundFooter = true;
						return (
							<>
								<div className="col-md-10 container-center content">
									<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.privilege_preview.content.replace("<p>", "").replace("</p>", "") }}>
									</p>
									<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.privilege_preview.title.replace("<p>", "").replace("</p>", "") }}>
									</p>
								</div>
							</>
						)
					}
				}
			})


			displayContent = pages.pageData.map((page, i) => {
				if (pages.slug !== null) {
					let founded = (page.slug.indexOf("welt-club") > -1);
					if (founded) {
						foundContent = true;
						return (
							<>
								<div className="col-md-12 list-account">
									<div className="top-list col-md-10 container-center bg--white">
										<div className="weltclub__intro">
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.welcom.title.replace("<p>", "").replace("</p>", "") }}>

											</p>
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.welcom.content.replace("<p>", "").replace("</p>", "") }}>

											</p>
										</div>
									</div>
								</div>
								<div className="col-md-12 tickets">
									<p className="text-text--xmedium uppercase">Collect points</p>
									<p className="text-text--xmedium uppercase">Redeem your points</p>
									<p className="text-text--xmedium uppercase">Enjoy your statut</p>
								</div>
								<div className="col-md-12 list-account">
									<div className="top-list col-md-10 container-center bg--white">
										<div className="weltclub__how">
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.work.title.replace("<p>", "").replace("</p>", "") }} >

											</p>
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.work.content.replace("<p>", "").replace("</p>", "") }} >
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-12 banner">
									<p className="text text--xmedium uppercase" dangerouslySetInnerHTML={{ __html: page.collect_points.title.replace("<p>", "").replace("</p>", "") }}></p>
								</div>


								<div className="col-md-12 list-account weltclub__collect">
									<div className="top-list col-md-10 container-center bg--white">
										<div className="collect__desc">
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.collect_points.content.replace("<p>", "").replace("</p>", "") }}>
											</p>
										</div>
									</div>

									<div className="col-md-10 container-center collect__img">
										<div className="content">
											<div className="collect-1">
												<img src={Collect1} alt="" />
												<p className="text text--xsmall">You collect W points on each product. The number of points you can collect is displayed alongside the item.</p>
											</div>
											<div className="collect-2">
												<img src={Collect2} alt="" />
												<p className="text text--xsmall">Multiply the number of W points you can earn on specific products. This way you will get even more W points!</p>
											</div>
											<div className="collect-3">
												<img src={Collect3} alt="" />
												<p className="text text--xsmall">Unlock bonus W points per step in the shopping cart.</p>
											</div>
											<div className="collect-4">
												<img src={Collect4} alt="" />
												<p className="text text--xsmall">You will receive 500W as a welcome gift at your registration!</p>
											</div>
											<div className="collect-5">
												<img src={Collect5} alt="" />
												<p className="text text--xsmall">
													Enjoy the many other available and upcoming ways to earn precious W points.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12 banner">
									<div className="image">
										<p className="text text--xmedium uppercase" dangerouslySetInnerHTML={{ __html: page.redeem_points.title.replace("<p>", "").replace("</p>", "") }}></p>
									</div>
								</div>
								<div className="col-md-12 list-account weltclub__redeem">
									<div className="top-list col-md-10 container-center bg--white">
										<div className="redeem__desc">
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.redeem_points.content.replace("<p>", "").replace("</p>", "") }}>
											</p>
										</div>
									</div>
									<div className="col-md-10 container-center redeem__img">
										<div className="content">
											<div className="redeem-1">
												<img src={Redeem1} alt="" />
												<p className="text text--xsmall">You determine how many W points to spend on your favorite product.</p>
											</div>
											<div className="redeem-2">
												<img src={Redeem2} alt="" />
												<p className="text text--xsmall">Enough points? Treat yourself and get the product of your choice in full!</p>
											</div>
											<div className="redeem-3">
												<img src={Redeem3} alt="" />
												<p className="text text--xsmall">What’s more, in the future, you will also benefit from our partners’ advantages.</p>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-12 banner">
									<p className="text text--xmedium uppercase" dangerouslySetInnerHTML={{ __html: page.enjoy_statut.title.replace("<p>", "").replace("</p>", "") }}></p>
								</div>
								<div className="col-md-12 list-account weltclub__enjoy">
									<div className="top-list col-md-10 container-center bg--white">
										<div className="enjoy__desc">
											<p className="text text--xmedium" dangerouslySetInnerHTML={{ __html: page.enjoy_statut.content.replace("<p>", "").replace("</p>", "") }}>
											</p>
										</div>
									</div>
									<div className="col-md-10 container-center enjoy__img">
										<div className="content">
											<div className="regular">
												<p className="enjoy__name regular__name text text--medium uppercase">Regular</p>
												<p className="enjoy__tag regular__tag text text--xsmall">from 1W</p>
											</div>
											<div className="medium">
												<p className="enjoy__name medium__name text text--medium uppercase">Medium</p>
												<p className="enjoy__tag medium__tag text text--xsmall">from 2'000W</p>
											</div>
											<div className="bold">
												<p className="enjoy__name bold__name text text--medium uppercase">Bold</p>
												<p className="enjoy__tag bold__tag text text--xsmall">from 5'000W</p>
											</div>
											<div className="heavy">
												<p className="enjoy__name heavy__name text text--medium uppercase">Heavy</p>
												<p className="enjoy__tag heavy__tag text text--xsmall">from 10'000W</p>
											</div>
										</div>
									</div>
								</div>
							</>
						)
					}
				}
			})
			if (!foundHeader) {
				displayedHeaders = <HeaderPage />
			}
			if (!foundFooter) {
				displayFooter = <FooterIndex />
			}

		}
		return (
			<div className="weltclub">
				<Helmet>
					<title>CLUB.WELTKERN®</title>
					<meta name="Welt Club" content="CLUB.WELTKERN®" />
				</Helmet>
				{displayedHeaders}
				<HeaderClub col={this.state.color} />
				<div className="col-md-12 clearfix scroll-top-div">
					{displayContent}

					<div className="privileges-overview">
						{displayPriv}
						<div className="col-md-10 container-center grids">
							<div className="grid">
								<p className="col-1 text text--medium-grey text--xsmall uppercase">Weltkern®</p>
								<p className="col-2 text text--xmedium uppercase">Regular</p>
								<p className="col-3 text text--xmedium uppercase">Medium</p>
								<p className="col-4 text text--xmedium uppercase">Bold</p>
								<p className="col-5 text text--xmedium uppercase">Heavy</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">
									Collect W points <br />
								(Prime and Status)
							</p>
								<img className="col-2" src={Checked} alt="" />
								<img className="col-3" src={Checked} alt="" />
								<img className="col-4" src={Checked} alt="" />
								<img className="col-5" src={Checked} alt="" />
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall"> Additional W points</p>
								<img className="col-2" src={Unchecked} alt="" />
								<p className="col-3 text text--xsmall">
									+10% W Prime <br />
								+10% W Status
							</p>
								<p className="col-4 text text--xsmall">
									+20% W Prime <br />
								+10% W Status
							</p>
								<p className="col-5 text text--xsmall">
									+30% W Prime <br />
								+10% W Status
							</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Direct chat</p>
								<img className="col-2" src={Checked} alt="" />
								<img className="col-3" src={Checked} alt="" />
								<img className="col-4" src={Checked} alt="" />
								<img className="col-5" src={Checked} alt="" />
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Birthday gift</p>
								<p className="col-2 text text--xsmall">+250W</p>
								<p className="col-3 text text--xsmall">+500W</p>
								<p className="col-4 text text--xsmall">+1'000W</p>
								<p className="col-5 text text--xsmall">+2'500W</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Free delivery in Switzerland</p>
								<img className="col-2" src={Checked} alt="" />
								<img className="col-3" src={Checked} alt="" />
								<img className="col-4" src={Checked} alt="" />
								<img className="col-5" src={Checked} alt="" />
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Free delivery in Europe</p>
								<p className="col-2 text text--xsmall">from CHF 300</p>
								<p className="col-3 text text--xsmall">from CHF 240</p>
								<p className="col-4 text text--xsmall">from CHF 120</p>
								<img className="col-5" src={Checked} alt="" />
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Free delivery in the rest of the world</p>
								<p className="col-2 text text--xsmall">from CHF 600</p>
								<p className="col-3 text text--xsmall">from CHF 450</p>
								<p className="col-4 text text--xsmall">from CHF 250</p>
								<img className="col-5" src={Checked} alt="" />
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Free return</p>
								<img className="col-2" src={Unchecked} alt="" />
								<p className="col-3 text text--xsmall">14 days</p>
								<p className="col-4 text text--xsmall">30 days</p>
								<p className="col-5 text text--xsmall">56 days</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--medium-grey text--xsmall uppercase">Type.Weltkern®</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Font discount</p>
								<img className="col-2" src={Checked} alt="" />
								<p className="col-3 text text--xsmall">-2%</p>
								<p className="col-4 text text--xsmall">-5%</p>
								<p className="col-5 text text--xsmall">-10%</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Additional W points on TYPE.WELTKERN® products</p>
								<img className="col-2" src={Unchecked} alt="" />
								<p className="col-3 text text--xsmall">
									+10% W Prime <br />
								+10% W Status
							</p>
								<p className="col-4 text text--xsmall">
									+20% W Prime <br />
								+10% W Status
							</p>
								<p className="col-5 text text--xsmall">
									+30% W Prime <br />
								+10% W Status
							</p>
							</div>
							<div className="grid grid--padding">
								<p className="col-1 text text--xsmall">Dedicated client service</p>
								<img className="col-2" src={Checked} alt="" />
								<img className="col-3" src={Checked} alt="" />
								<img className="col-4" src={Checked} alt="" />
								<img className="col-5" src={Checked} alt="" />
							</div>
						</div>
					</div>
				</div>
				{displayFooter}
				<Cart />
				<User />
			</div>
		)

	}
}
Weltclub.propTypes = {
	getPages: PropTypes.func.isRequired,
	getProducts : PropTypes.func.isRequired,
	products    : PropTypes.object.isRequired,
	currentLange: PropTypes.object.isRequired,
	currencies  : PropTypes.object.isRequired,
}
const mapStateToprops = (state) => ({
	pages: state.pages,
	products    : state.products,
	currentLange: state.translation,
	currencies: state.currencies
})
export default connect(mapStateToprops, { getPages, getProducts })(Weltclub)
