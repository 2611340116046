import isEmpty from '../validation/is-empty';


const initialState = {
    isloaded: false,
    currentProduct :{},
    typeFaceOption: null,
    familyPrice: 0,
    font: {}
}
export default function( state = initialState, action) {
    switch(action.type) {
        case  'GET_CURRENT_PRODUCT' :
            return {
                ...state,
                isloaded: !isEmpty(action.payload),
                currentProduct: action.payload,
            }
            case  'GET_CURRENT_OPTION' :
                return {
                    ...state,
                    typeFaceOption: action.payload,
                }
            case  'GET_CURRENT_FAMILY' :
                return {
                    ...state,
                    familyPrice: action.payload,
                }
        case  'GET_CURRENT_FONT' :
                return {
                    ...state,
                    font: action.payload,
                }
        default :
            return state
    }
}