import axios from "axios";
import $ from "jquery";
import { PropTypes } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { loginUser } from "../../../actions/authActions";
import API, { subUrl } from "../../../utils/api";
import createBrowserHistory from "../../../utils/history";


class LoginIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      redirect: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitLogin() {
    console.log(this.state.username);
    return;
    const user = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.loginUser(user);
    if (this.props.auth.isAuthenticated)
      setTimeout(function () {
        createBrowserHistory.push("/home");
      }, 3000);
  }

  onSubmit(e) {
    const user = {
      username: this.state.username,
      password: this.state.password,
    };
    console.log("Url : ", subUrl.login, user);
    API.post(subUrl.login, user)
      .then((res) => {
        console.log(res);
        const { active } = res.data;
        const { first_connection } = res.data ? 1 : 0;
        const { user_id } = res.data;
        if (active != 0) {
          if (first_connection != 0) {
            this.props.loginUser(user);
            $(".popup--login").addClass("open");
            $(".scrollTop").addClass("not-showing");
            $("body").addClass("overflow-hidden");
            setTimeout(function () {
              $("body").removeClass("overflow-hidden");
              if(localStorage.getItem("carte-showing"))
              {
                createBrowserHistory.push("/checkout")
                localStorage.removeItem("carte-showing",true)
              }
              window.location.reload();
            }, 3000);
          } else {
            const newUser = {
              userid: user_id,
            };
            const { data } = axios.post(
              `${process.env.REACT_APP_BaseURL}/wp-json/custom-routes/v1/first/connection`,
              newUser
            );
            $(".popup--welcom").addClass("open");
            $(".scrollTop").addClass("not-showing");
            $("body").addClass("overflow-hidden");
          }
        } else {
          $(".popup--download").addClass("open");
          $(".scrollTop").addClass("not-showing");
          $("body").addClass("overflow-hidden");
        }
      })
      .catch((error) => {
        $(".popup--error").addClass("open");
        $(".scrollTop").addClass("not-showing");
        $("body").addClass("overflow-hidden");
        console.log("error")
      });
    e.preventDefault();
    return;
  }

  componentDidMount() {
    $("a.back_").on("click", function () {
      $(".page-login.ContentLogin").removeClass("open");
      $("header.top-header").toggleClass("dark");
      $("header").toggleClass("dark-user");
      $("body.body").removeClass("body-lock");
      $("header.top-header").toggleClass("dark");
      $(".logo").removeClass("grise");
      $(".top-header-right").removeClass("grise");
      $(".logo").removeClass("logohide");
      return false;
    });

    $(".popup--welcom-close").on("click", function (e) {
      $("#Log").click();
      $(".popup--welcom").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });

    $(".popup--download-close").on("click", function (e) {
      $(".popup--download").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });

    $(".popup--error-close").on("click", function (e) {
      $(".popup--error").removeClass("open");
      $(".scrollTop").removeClass("not-showing");
      $("body").removeClass("overflow-hidden");
      e.preventDefault();
    });
  }

  render() {
    return (
      <Fragment>
        <Login.Content className="page-login ContentLogin">
          <div
            className={`back ${
              window.location.pathname == "/shop" ? "shop to-top" : ""
            }`}
          >
            <a className="back_">
              <p>Back</p>
            </a>
          </div>
          <Login.Form>
            <Login.LeftSide className="leftside col-md-6 padding-t-50 padding-b-50">
              <div className="top">
                <h2 className="text text--medium-grey">Already a member?</h2>
                <h3>Login!</h3>
              </div>
              <div className="bottom">
                <div className="formulaire-login">
                  <div className="col-md-12 float-left">
                    <form onSubmit={this.onSubmit}>
                      <div className="form-text">
                        <input
                          type="text"
                          name="username"
                          value={this.state.username}
                          onChange={this.onChange}
                          placeholder="E-mail"
                        />
                      </div>
                      <div className="form-text">
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          className="password"
                          onChange={this.onChange}
                          placeholder="Password"
                        />
                        
                      </div>
                      <div className="form-submit col-md-12">
                        <div className="col-md-6 text-left"><input
                          type="submit"
                          name="Log"
                          id="Log"
                          value="Login"
                        /></div>
                        <div className="col-md-6">
                        <a
                          href="/passwort-reset"
                          className="password-forget text text--medium-grey"
                        >
                          Forgot password ?
                        </a>
                        </div>
                        
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Login.LeftSide>
            <Login.RightSide className="rightside joint-club col-md-6 padding-t-50 padding-b-50">
              <div className="top">
                <h3 className="text text--medium-grey">Not a member yet?</h3>
                <h3>Join the Club!</h3>
              </div>
              <div className="bottom">
                <Link className="login-link" to="/register">
                  Register
                </Link>
              </div>
            </Login.RightSide>
          </Login.Form>
        </Login.Content>
        <Popups>
          <div className="popup--welcom col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
              <div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30">Welcome to the Club!</p>
										<p className="mb-30">
                    Enjoy 500W that you can redeem directly on each product
                    by using the cursor next to the price!</p>
									</div>
								</div>
								<div className="col-md-12 right">
									<button className="popup--welcom-close btn btn--close"><p>Close</p></button>
								</div>
							</div>
						</div>
					</div>
          <div className="popup--login col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
              <div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30"></p>
										<p className="mb-30">Login...</p>
									</div>
								</div>
							</div>
						</div>
					</div>
          <div className="popup--download col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
								<div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30"></p>
										<p className="mb-30">Please, confirm your email address to activate your account.</p>
									</div>
								</div>
								<div className="col-md-12 right">
									<button className="popup--download-close btn btn--close"><p>Close</p></button>
								</div>
							</div>
						</div>
					</div>
          <div className="popup--error col-md-12 popup-licence-download">
						<div className="popup__content">
							<div className="col-md-8 center">
								<div className="popup__text-content">
									<div className="popup__title">
                    <p className="mb-30">Oops!</p>
										<p className="mb-30">Incorrect email or password.</p>
									</div>
								</div>
								<div className="col-md-12 right">
									<button className="popup--error-close btn btn--close"><p>Close</p></button>
								</div>
							</div>
						</div>
					</div>
        </Popups>

      </Fragment>
    );
  }
}
LoginIndex.protoTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errorMessage: state.errorMessage,
});

const Popups = styled("div")`
  .popup--welcom,
  .popup--login,
  .popup--download,
  .popup--error,
  .popup--price {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: RGBA(235, 235, 235, 0.98);
    font-family: "TWKLausanne-600";

    &.open {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }

    .popup__content {
      display: flex;
      justify-content: flex-start;
      height: auto;
      width: 100%;
      margin-top: 400px;
      padding-bottom: 100px;
    }
    .center {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 480px) {
        justify-content: center;
      }
    }
    .popup__title {
       margin-bottom: 70px;
      text-align: center;
    }
    .mb-30 {
       margin-bottom: 0px;
    }
    .mb-40 {
       margin-bottom: 40px;
    }
    .underline {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 480px) {
    .popup--welcom,
    .popup--login .popup--download,
    .popup--error,
    .popup--price {
      .popup__title {
         margin-bottom: 30px;
      }
      &.open {
        padding: 0 20px;
      }
    }
    h3 {
      color: #000000;
    }
  }
`;

const Login = {
  Content: styled.div`
    overflow: hidden;
    background: #ebebeb;
    display: flex;
  `,

  Form: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `,

  LeftSide: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    + .rightside {
      margin-left: 0px;
      @media screen and (max-width: 480px) {
        margin-left: 0;
      }
    }

    .form-text {
      display: flex;
    }
  `,
  RightSide: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
};
export default connect(mapStateToProps, { loginUser })(LoginIndex);
