import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cart from "./Cart";
import Likes from "./Likes";
import User from "./User";

class MenuTop extends Component {

  componentDidMount() {
    $(".menu").on('click', function () {
      if($("body").hasClass("body-lock")==true){
				$("body.body").removeClass("body-lock");
			}else{
        $("body.body").addClass("body-lock");
      }
	 
      // $("body").toggleClass("body-lock");

      // $("header.top-header").toggleClass("dark");
      // $("header .top-header-right").toggleClass('grise');
      // $("header .top-header .logo").toggleClass('grise');
      // $("header .top-header").toggleClass('dark');
      // $("menu-page-details").removeClass('Black');
      $("header").toggleClass("menu-show");
      $(this).toggleClass("active");

      $("header").removeClass("panier-show");
      $(".panier-content").removeClass("open");

      if($(".cart").hasClass("active")==true){
        $(".cart").removeClass("active");
        $("body.body").toggleClass("body-lock");
      }

      return false;
    });

    // $(".cart").on('click', function () {
    //   $("header.top-header").toggleClass("dark");
    //   return false;
    // });

    // $(".account, .account-offine").on('click', function () {
    //   $(".top-header").toggleClass("dark");
    // });
  }

  render() {
    return (
      <div className="top-header-right">
        <Cart />
        <Likes />
        <User />
        <div className="link-menu">
        <a className="menu">menu</a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart
})

export default connect(mapStateToProps)(MenuTop)
