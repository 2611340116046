import React, { Component } from 'react';

export default class Panier extends Component {
	render() {
		return (
			<div className="panier-content">
				<div className="header-panier col-md-12">
					<div className="articles">Articles</div>
					<div className="informations">Informations</div>
					<div className="qty">QTY/LCS</div>
					<div className="prix">Prix</div>
				</div>
				<div className="content-panier col-md-12">
					<div className="col-md-12 no-articles d-flex-column-c">
						<p>Aucun article dans le panier</p>
					</div>

					<div className="col-md-12 content">
						<a className="remove"></a>
						<div className="articles">
							<div className="images">
								<img src="/images/typo.png" alt="Welt" />
							</div>
							<div className="nom-prix-statut">
								<div>
									<h3>Neubau Forst Archive</h3>
								</div>
								<div className="prix-statut">
									<div className="reward">Reward</div>
									<div className="statut">
										<div className="col-md-12">
											<span>Prime</span>
											<span className="money"><span>45W x 8</span>+138W</span>
										</div>
										<div className="col-md-12 colorStatut">
											<span>Status</span>
											<span className="money">+69W</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="informations">
							<div className="col-md-12">
								<div className="icon"></div>
								<div className="date">Dec. 24 - Jan. 22</div>
							</div>
							<div className="col-md-12">
								<div className="icon"></div>
								<div className="date">Dec. 24 - Jan. 22</div>
							</div>
						</div>
						<div className="qty">QTY/LCS</div>
						<div className="prix">
							<span>CHF</span>
							<span>69,90</span>
							<span>-0W</span>
						</div>
						<div className="clearfix"></div>
					</div>

					<div className="col-md-12 content">
						<a className="remove"></a>
						<div className="articles">
							<div className="images">
								<img src="/images/typo.png" alt="Welt" />
							</div>
							<div className="nom-prix-statut">
								<div>
									<h3>Neubau Forst Archive</h3>
								</div>
								<div className="prix-statut">
									<div className="reward">Reward</div>
									<div className="statut">
										<div className="col-md-12">
											<span>Prime</span>
											<span className="money"><span>45W x 8</span>+138W</span>
										</div>
										<div className="col-md-12">
											<span>Status</span>
											<span className="money">+69W</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="informations">
							<div className="col-md-12">
								<div className="icon"></div>
								<div className="date">Dec. 24 - Jan. 22</div>
							</div>
							<div className="col-md-12">
								<div className="icon"></div>
								<div className="date">Dec. 24 - Jan. 22</div>
							</div>
						</div>
						<div className="qty">QTY/LCS</div>
						<div className="prix">
							<span>CHF</span>
							<span>69,90</span>
							<span>-0W</span>
						</div>
						<div className="clearfix"></div>
					</div>

					<div className="col-md-12 content">
						<a className="remove"></a>
						<div className="articles">
							<div className="images">
								<img src="/images/typo.png" alt="Welt" />
							</div>
							<div className="nom-prix-statut">
								<div>
									<h3>Neubau Forst Archive</h3>
								</div>
								<div className="prix-statut">
									<div className="reward">Reward</div>
									<div className="statut">
										<div className="col-md-12">
											<span>Prime</span>
											<span className="money"><span>45W x 8</span>+138W</span>
										</div>
										<div className="col-md-12">
											<span>Statut</span>
											<span className="money">+69W</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="informations">
							<div className="col-md-12">
								<div className="icon"></div>
								<div className="date">Dec. 24 - Jan. 22</div>
							</div>
							<div className="col-md-12">
								<div className="icon"></div>
								<div className="date">Dec. 24 - Jan. 22</div>
							</div>
						</div>
						<div className="qty">QTY/LCS</div>
						<div className="prix">
							<span>CHF</span>
							<span>69,90</span>
							<span>-0W</span>
						</div>
						<div className="clearfix"></div>
					</div>


					{/*!-- Paiement listes -->*/}
					<div className="paiment-lists sticky-pos">
						{/*!-- Listes -->*/}
						<div className="lists">
							<div className="col-md-4 float-left">
								Livraison offerte *
                            </div>
							<div className="col-md-4 float-left">

							</div>
							<div className="col-md-4 float-left align-right">
								<i className="fas fa-check"></i>
							</div>
						</div>
						{/*!-- Listes -->*/}
						{/*!-- Listes -->*/}
						<div className="lists">
							<div className="col-md-4 float-left">
								Bonus
                            </div>
							<div className="col-md-4 float-left tarif-livraison">
								+200W
                            </div>
							<div className="col-md-4 float-left align-right">
								✓
                            </div>
						</div>
						{/*!-- Listes -->*/}
						{/*!-- Listes -->*/}
						<div className="lists">
							<div className="col-md-4 float-left">
								Bonus
                            </div>
							<div className="col-md-4 float-left tarif-livraison">
								+200W
                            </div>
							<div className="col-md-4 float-left align-right">
								✓
                            </div>
						</div>
						{/*!-- Listes -->*/}

					</div>
					{/*!-- Paiement listes -->*/}

					{/*!-- Paiement listes -->*/}
					<div className="paiment-lists sticky-events">

                        <div className="paiement">
							<div className="col-md-6 float-left">
								<div className="col-md-10 float-right">

                                    <div className="bloc-paiement">
										<p>Vous cumulez</p>
										<div className="content">
											<div className="col-md-12">
												<div className="nom">Prime</div>
												<div className="nbr">12'429W</div>
											</div>
											<div className="col-md-12 statut">
												<div className="nom">Statut</div>
												<div className="nbr">9'214W</div>
											</div>
										</div>
									</div>
									{/*!-- End Points cumulés -->*/}
									{/*!-- Surprises -->*/}
									<div className="bloc-paiement bloc-surprise">
										<p>Surprise à <br />débloquer</p>
										<div className="content">
											<div className="col-md-12">
												<div className="surprise">Prime</div>
											</div>
										</div>
									</div>
									{/*!-- End Surprises -->*/}
									{/*!-- Surprises -->*/}
									<div className="bloc-paiement">
										<div className="total-points">
											<div className="col-md-12">
												<div className="first"><i className="fas fa-lock"></i></div>
												<div className="last">Livraison offerte</div>
											</div>
											<div className="col-md-12">
												<div className="first"><i className="fas fa-lock"></i></div>
												<div className="last">+500W</div>
											</div>
											<div className="col-md-12">
												<div className="first"><i className="fas fa-lock"></i></div>
												<div className="last">+500W</div>
											</div>
											<div className="col-md-12">
												<div className="first"><i className="fas fa-lock"></i></div>
												<div className="last">+500W</div>
											</div>
											<div className="col-md-12">
												<div className="first"><i className="fas fa-lock"></i></div>
												<div className="last">+500W</div>
											</div>
										</div>
									</div>
									{/*!-- End Surprises -->*/}
								</div>
							</div>
							<div className="col-md-6 float-left">
								<div className="col-md-10 float-right">
									<div className="total-paiement">
										<div className="sous-total-paiement">
											<div className="item-paiement">
												<div className="item-label">Sous-total</div>
												<div className="item-prix"><span>CHF</span><span>180,90</span></div>
											</div>
											<div className="item-paiement">
												<div className="item-label">Livraison</div>
												<div className="item-prix">
													<div className="pays">Switzerland</div>
													<span>CHF</span><span>0,00</span>
												</div>
											</div>
										</div>

										<div className="total">
											<div className="item-label">Total</div>
											<div className="item-prix"><span>CHF</span><span>180,90</span></div>
											<div className="points">+12'423W</div>
										</div>
										<div className="btn-paiement-panier">
											<a className="continue"  >Continuer mes achats</a>
											<a className="paye"  >Passer à la caisse</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*!-- Paiement -->*/}
					</div>
					{/*!-- Paiement listes -->*/}
				</div>
			</div>
		)
	}
}
